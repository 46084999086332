import axios from "axios";
const url = process.env.REACT_APP_SERVER_URL;
export const createLeaseTaxInDB = async(taxJson) => {
    try{
        const responseForLeaseTax= await axios.post(`${url}/leaseTax`,taxJson);
        console.log(responseForLeaseTax)
        if(responseForLeaseTax && responseForLeaseTax.data && responseForLeaseTax.data.leaseTaxId){
          return responseForLeaseTax.data.leaseTaxId;
        }
        else{
          return "No Data Found"
        }
    }
    catch(error)
    {
        console.log(error);
    }
};

export const getLeaseTaxByIdInDB = async(leaseTaxId) => {
    try{
        const responseForLeaseTax= await axios.get(`/api/leaseTax/${leaseTaxId}`);
        console.log(responseForLeaseTax)
        if(responseForLeaseTax && responseForLeaseTax.data){
          return responseForLeaseTax.data;
        }
        else{
          return "No Data Found"
        }
    }
    catch(error)
    {
        console.log(error);
    }
};
export const updateLeaseTaxInDB = async(taxJson) => {
    try{
        const responseForLeaseTax= await axios.patch(`${url}/leaseTax/${taxJson.id}`,taxJson);
        console.log(responseForLeaseTax)
        if(responseForLeaseTax && responseForLeaseTax.data){
          return responseForLeaseTax.data;
        }
        else{
          return "No Data Found"
        }
    }
    catch(error)
    {
        console.log(error);
    }
};