import React, { useState, useEffect } from 'react';
import axios from "axios";
import {
    Box ,
    FilledInput ,
    InputLabel ,
    FormControl,
    Button,
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
  } from '@mui/material';
  import { NumericFormat } from 'react-number-format';
  import { PatternFormat } from 'react-number-format';
import { useNavigate, useParams } from 'react-router-dom';
const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const AddTenant = ({rows,setRows,showAddTenantModal,setShowAddTenantModal,customer}) => {
    // const [showAddTenantModal,setShowAddTenantModal]=useState(false);
    const [customerJson,setCustomerJson]=useState(()=>{
      if(customer && Object.keys(customer).length > 0){
        return {
          id:customer.id || null,
          fname: customer.fname  || "",
          lname: customer.lname  || "",
          email: customer.email || "",
          phone: customer.phone || "",
          address: customer.address || ""

        };
      }
      else
      return {
        id:-1*(rows.length+1),
        fname:"",
        lname:"",
        phone:"",
        email:"",
        address:""
      }
        
    })
    const url=process.env.SERVER_URL;
    const navigate=useNavigate();
    const {propertyId} = useParams();
    useEffect(() => {
      
      }, []);
    const handleCloseAddTenantPopup=()=>{
        setShowAddTenantModal(false)
    }

    const saveTenant= ()=>{
        // setRows((oldRows) => [...oldRows, {id,  fname: '',lname:"",phone:'',email:'', address: '', isNew: true }]);
        if (customerJson.id===-1*(rows.length+1)){
          setRows((oldRows) => [...oldRows, customerJson]);
        }
        else{
          setRows((oldRows) => {
            // Check if the row with the same id exists in the array
            const index = oldRows.findIndex(row => row.id === customerJson.id);
            
            // If the row exists, update it; otherwise, add the new row
            
            if (index !== -1) {
              // If row exists, update it
              const updatedRows = [...oldRows];
              updatedRows[index] = customerJson;
              console.log(updatedRows)
              return updatedRows;
            } else {
              // If row doesn't exist, add it
              return [...oldRows, customerJson];
            }
          });

        }
        handleCloseAddTenantPopup();
        
    }

  return (
    <div>
        <Dialog
        open={showAddTenantModal}
        onClose={handleCloseAddTenantPopup}
      >
        <DialogTitle>Tenant Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          
        
        
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        
      <div>
      <FormControl sx={{ m: 1, width: '34ch' }} variant="filled">
         <InputLabel>First Name</InputLabel>
          <FilledInput value={customerJson.fname} onChange={(e)=>setCustomerJson({...customerJson,fname:e.target.value})}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: '34ch' }} variant="filled">
         <InputLabel>Last Name</InputLabel>
          <FilledInput value={customerJson.lname} onChange={(e)=>setCustomerJson({...customerJson,lname:e.target.value})}
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1}} variant="filled">
          <InputLabel>Phone Number</InputLabel>
          
          <PatternFormat  format="+1 (###) ###-####" customInput={FilledInput} value={customerJson.phone} onChange={(e)=>setCustomerJson({...customerJson,phone:e.target.value})}
          />
        </FormControl><br></br>
        <FormControl fullWidth sx={{ m: 1}} variant="filled">
          <InputLabel>Email</InputLabel>
          <FilledInput value={customerJson.email} onChange={(e)=>setCustomerJson({...customerJson,email:e.target.value})}
          />
        </FormControl><br></br>
      

      <FormControl fullWidth sx={{ m: 1}} variant="filled">
          <InputLabel>Address</InputLabel>
          <FilledInput value={customerJson.address} onChange={(e)=>setCustomerJson({...customerJson,address:e.target.value})}
          />
        </FormControl><br></br>
        
         {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
         <InputLabel>City</InputLabel>
          <FilledInput value={customerJson.city} onChange={(e)=>setCustomerJson({...customerJson,city:e.target.value})}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
        <InputLabel>State</InputLabel>
          <FilledInput value={customerJson.state} onChange={(e)=>setCustomerJson({...customerJson,state:e.target.value})}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
          <InputLabel>Zip Code</InputLabel>
          <FilledInput value={customerJson.zipCode} onChange={(e)=>setCustomerJson({...customerJson,zipCode:e.target.value})}
          />
        </FormControl> */}
        
      </div>

      
      
    </Box>
    </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddTenantPopup}>Cancel</Button>
          <Button type="submit" onClick={(e)=>{
            saveTenant()
          }}>Save</Button>
        </DialogActions>
      </Dialog>
   
    </div>
  );
};

export default AddTenant;