import  React,{
    useState,
    useEffect
} from 'react';
import axios from 'axios';
import { 
    Link, useParams 
} from "react-router-dom";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    FilledInput,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    Input,
    TextField,
    InputAdornment,
    Box,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import {calculateMonthsAndDays, calculateWeeksAndDays} from "../CommonFunctions"
import { getAnyOverLappingLeaseInDB } from '../TenancyFunctions';
import { getTaxDetailsByPropertyIdInDB } from '../CountyTaxFunctions';
export default function TaxInfo({tenancyJson,setTenancyJson,taxJson,setTaxJson}) {
    const [dateDiffJson,setDateDiffJson]=useState({})
    const {propertyId} = useParams();
    const {unitId} =useParams();
    const {tenancyId} = useParams();
    const [overLappingTenancy,setOverLappingTenancy]=useState([]);
    const url = process.env.REACT_APP_SERVER_URL;
    
    useEffect(() => {
        if(propertyId){
            getTaxDetailsByPropertyId(propertyId);
        }
    
      }, [propertyId]);

      const getTaxDetailsByPropertyId = async(propertyId) =>{
        try{
        let response= await getTaxDetailsByPropertyIdInDB(propertyId);
        console.log(response)
        if(response && Object.keys(response).length > 0){
          delete response["id"];
          setTaxJson(response)
        }
        }
        catch(error){
          console.log(error)
        }
      }





    
    

  return (
    <>
    <br></br>
    
    <Box sx={{marginLeft:"2%"}}>
        <i>The tax information provided here is respective to the property. 
            Please update it and provide notes accordingly if there are any exceptions/modifications.</i><br></br>
    {/* <TextField sx={{m:1,width:"50ch"}}  type="text" variant="filled"  label="County Name" value={taxJson.countyName} onChange={(e)=>{
            setTaxJson({...taxJson,countyName: e.target.value});
        }}/> */}
        {/* <FormControlLabel 
        control={<Checkbox checked={taxJson.exception} onChange={(e)=>{
          const targetValue=e.target.checked ? 1 : 0;
          setTaxJson({...taxJson,exception: targetValue});
        }}
        />} label="Tax Exception" />
        <br></br> */}
        
        
        <TextField sx={{m:1}} type="float" variant="filled" label="Sales Tax" value={taxJson.salesTax} onChange={(e)=>{
             setTaxJson({...taxJson,salesTax: e.target.value});
        }} 
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />

        <TextField sx={{m:1}} type="float" variant="filled" label="Tourism Tax" value={taxJson.tourismTax}  onChange={(e)=>{
             setTaxJson({...taxJson,tourismTax: e.target.value});
        }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />

        <TextField sx={{m:1}} type="float" variant="filled" label="Surface Tax" value={taxJson.surfaceTax}  onChange={(e)=>{
             setTaxJson({...taxJson,surfaceTax: e.target.value});
        }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField sx={{m:1}} type="float" variant="filled" label="Other Taxes" value={taxJson.otherTax}  onChange={(e)=>{
             setTaxJson({...taxJson,otherTax: e.target.value});
        }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />

        <br></br>
        <br></br>
        <TextField
          id="outlined-multiline-static"
          label="Tax Notes"
          multiline
          style={{ width: '50%' }}
          value={tenancyJson.description} 
          onChange={(e)=>setTenancyJson({...tenancyJson,description:e.target.value})}
          rows={2} // Specify the number of rows you want to display
          variant="filled"
        />
        <b style={{marginLeft:"20%"}}>Total Tax: 
            {parseInt(taxJson.salesTax || 0) + parseInt(taxJson.tourismTax || 0) 
            + parseInt(taxJson.surfaceTax || 0) + parseInt(taxJson.otherTax || 0)} %</b> 

        
    </Box>

    </>
  );
}