
// import { forwardRef } from 'react';

// material-ui
import React, {useState,useEffect} from 'react'
  import axios from 'axios'
  import { eachDayOfInterval, format, parseISO  } from 'date-fns';
  import {
    formatDate,
    formatDateText,
  } from '../CommonFunctions';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {Grid,Stack,Box} from '@mui/material'
import MainCard from './MainCard';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CardAnalysis from "./CardAnalysis"
import BarChartComponent from './BarChartComponent';
import AreaChartComponent from './AreaGraphComponent';
const options = { month: 'short', day: 'numeric', year:'numeric' };
// header style
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
function ChartGroup({paymentReportSummary,searchStartDate,expiredLeases}) 
{
    //Time being here ..will be moved to context
    const [startDate,setStartDate]=useState(formatDate(new Date(),-7));
    const [endDate,setEndDate]=useState(formatDate(new Date()));
    const url = process.env.REACT_APP_SERVER_URL;
    // const units=[1,2,3,4,5,6]
    
    useEffect(() => {
    }, []);
    

  return (
    
    <Box sx={{  marginTop: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={7.2}>
                    <Paper sx={{ padding: 2, textAlign: 'center' }}>
                        <BarChartComponent data={paymentReportSummary} searchStartDate={searchStartDate}/>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4.8}>
      <Paper sx={{ textAlign: 'center', height: '52ch', display: 'flex', flexDirection: 'column' }}>
        
        {/* Sticky Header */}
        <Box sx={{ 
          padding: 2, 
            position: 'sticky', 
            top: 0, 
            backgroundColor: "rgb(250, 250, 251)", 
            color:"black",
            zIndex: 1, 
            padding: 1, 
            borderBottom: '1px solid #ddd' 
          }}>
          <Typography variant="h6" align="left">Leases About to Expire</Typography>
        </Box>

        {/* Scrollable Content */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, overflowY: 'auto', flex: 1, paddingTop: 1}}>
          {expiredLeases.map((unit, index) => (
            <Paper key={index} sx={{ padding: 2, textAlign: 'left' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">{unit.name}</Typography>
                {unit.expiredLease && (
                  <Typography variant="body1" sx={{ color: 'red' }}>
                    <u>Expired</u>
                  </Typography>
                )}
              </Box>
              <Typography variant="body2">Lease Expected End Date: 
                <b>{parseISO(unit.checkout).toLocaleDateString('en-us',options)} </b></Typography>
            </Paper>
          ))}
        </Box>
        
      </Paper>
    </Grid>
    </Grid>
        </Box>
  );
}

export default ChartGroup;

