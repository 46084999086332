import axios from "axios";
const url = process.env.REACT_APP_SERVER_URL;
export const createLandlordDetailsInDB = async(landlordJson) =>{
    try{
        const response= await axios.post(`${url}/landlordDetails`,landlordJson);
        if(response && response.data){
            return response.data;
        }
        
        
    }
    catch(error)
    {
        console.log(error)
    }
}
export const updateLandlordDetailsInDB = async(id,landlordJson) =>{
    try{
        const response= await axios.patch(`${url}/landlordDetails/${id}`,landlordJson);
        if(response && response.data){
            return response.data;
        }
        
        
    }
    catch(error)
    {
        console.log(error)
    }
}

export const getLandlordDetailsByPropertyIdInDB = async(propertyId) =>{
    try{
        const response= await axios.get(`${url}/landlordDetailsByPropertyId/${propertyId}`);
        if(response && response.data){
            return response.data;
        }
        return null;
        
        
    }
    catch(error)
    {
        console.log(error)
    }
}
