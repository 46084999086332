import React, { useState } from 'react';
import {
    Dialog,DialogTitle,IconButton,DialogContent,DialogContentText,
    DialogActions,Button, TextField
} from '@mui/material';
import { useParams,useNavigate } from 'react-router-dom';
import UnitInfo from './UnitInfo';
// import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
const  AddUnit = ({unitRows,showAddUnitModal,setShowAddUnitModal,unitJson}) => {
    const [open, setOpen] = React.useState(false);
    
    const [name,setName]=useState(unitJson ? unitJson.name || '' : '');
    const [streetAddress,setStreetAddress]=useState(unitJson ? unitJson.streetAddress || '' : '');
    const {propertyId}=useParams();

    const url = process.env.REACT_APP_SERVER_URL;
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
        setShowAddUnitModal(false)
    };
    const createUnitInDB = async(propertyId,unitInfo) =>{
        try{
          const name=unitInfo.name;
              const streetAddress=unitInfo.streetAddress;
              await axios.post(`${url}/units`,{
                  propertyId,
                  name,
                  streetAddress
              }).then((responseForCreatingUnits) =>{
                  console.log(responseForCreatingUnits.data);
              })
        }catch(error){
          console.log(error);
        }
      }
      const updateUnitInDB = async(propertyId,unitInfo) =>{
        try{
          const name=unitInfo.name;
              const streetAddress=unitInfo.streetAddress;
              
              await axios.patch(`${url}/units/${unitInfo.id}`,{
                  propertyId,
                  name,
                  streetAddress
              }).then((responseForUpdatingUnits) =>{
                  console.log(responseForUpdatingUnits.data);
              })
        }catch(error){
          console.log(error);
        }
      }
  
    return (
      <React.Fragment>
        
        <Dialog
          open={showAddUnitModal}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              let tempUnitJson={"name":name,"streetAddress":streetAddress}
              if(unitJson && unitJson.id){
                tempUnitJson["id"]=unitJson.id;
                updateUnitInDB(propertyId,tempUnitJson)
              }
              else{
                
                unitRows.push(tempUnitJson);
                createUnitInDB(propertyId,tempUnitJson)
              }
              handleClose();
            },
          }}
        >
          <DialogTitle close>Unit information</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            X
          </IconButton>
          <DialogContent>
            <DialogContentText>
              To add an unit, please enter the name and apartment/suite address here. 
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              label="Name"
              type="text"
              fullWidth
              variant="standard"
              value={name}
              onChange={(e)=>setName(e.target.value)}
            />
            <TextField
              required
              margin="dense"
              label="Apartment/Suite No."
              type="text"
              fullWidth
              variant="standard"
              value={streetAddress}
              onChange={(e)=>setStreetAddress(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  export default AddUnit;