import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, useRef } from "react";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { formatDate } from "../CommonFunctions";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Grid,
  Checkbox,
  List,
  ListItem,
  Button,


} from '@mui/material';
import { parseISO } from "date-fns";

// Register fonts with pdfmake
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts={
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf'
},
  DejaVu:{
    normal:"DejaVuSans.ttf",
    bold:"DejaVuSans-Bold.ttf"
  }
}
fetch('/fonts/DejaVuSans.ttf').then(res => res.arrayBuffer()).then(buffer => {
  pdfMake.vfs['DejaVuSans.ttf'] = buffer;
});
fetch('/fonts/DejaVuSans-Bold.ttf').then(res => res.arrayBuffer()).then(buffer => {
  pdfMake.vfs['DejaVuSans-Bold.ttf'] = buffer;
});
const checkboxStyles={ transform: "scale(1.2)", 
  WebkitTransform: "scale(1.2)", width: "1rem", height: "1rem" };
export const _15DaysNotice=({tenancyJson,unitJson,landlordJson,customersDetails,propertyJson})=>{
    const [show,setShow]=useState(true);
    const today=formatDate(new Date());
    const url=process.env.REACT_APP_SERVER_URL;
    const [dataJson,setDataJson]= useState({
      today:new Date().toLocaleDateString('en-us',{month:"short",day:"numeric", year:"numeric"}),
      county:propertyJson.county ? propertyJson.county : "",
      customerNames:customersDetails.length>0 ? customersDetails.map(person => `${person.fname} ${person.lname}`).join(", ") 
       : " ",
      propertyAddress: unitJson.name + ', ' + unitJson.streetAddress ,
      landlord_name:landlordJson.name ? landlordJson.name : "",
      landlord_address: landlordJson.address ? landlordJson.address : "",
      landlord_phone: landlordJson.phoneNumber ? landlordJson.phoneNumber : "",
      amount:"",
      lastDate:"",
      checkbox_value:"hand",
      description:"",
      d1:"",
      d2:"",
      d3:"",
      m1:"",
      m2:"",
      m3:"",
      y1:"",
      y2:"",
      y3:"",
      time3:"",




    })
    useEffect(() => {
        
       },[]);
    const handleClose=()=>{
        setShow(false);
        // props.setIsThreeDayNoticeFormVisible(false);
        // props.setIsCheckinFormVisible(false)
    }
    function printPage(){
        
        // const rulesContent=[];
        // for (let i = 0; i < rules.length; i++) {
        //     rulesContent.push({ text: rules[i], style: 'listItem' });
        //   }
        
        const documentDefinition = {
            content: [
              {
                text: 'NOTICE OF TERMINATION OF TENANCY',
                style: 'header'
              },
              
              {
                text: '(7 OR 15 DAY NOTICE: SEE INSTRUCTIONS) \n',
                style: 'subheader'
              },
              {text:`To: \n`},
              {
                text:[
                dataJson.customerNames.length>0 ? {text: `${dataJson.customerNames}`, style:"underlinedText"}:
            `________________________________________`,
               {text:`\nTenant(s) Name\n\n`},

               dataJson.propertyAddress.length>0 ? {text: `${dataJson.propertyAddress}`, style:"underlinedText"}:
            `________________________________________`,
               {text:`\nAddress\n\n`},

               {text: `________________________________________`},
               {text:`\nCity, State, Zip Code\n\n`},
               {text:`Date Of Delivery : `},
               
                dataJson.today ? {text: `${dataJson.today}`, style:"underlinedText"}:
                    `___________________________`,
            `\n`

                ]
              },
              {
                text:[
                `You are hereby notified to quit and vacate the premises described as:\n`,
                dataJson.description.length>0 ? 
                {text:`${dataJson.description}`,style:"underlinedText"}:
            `_________________________________________________________________________________________________`,
             ,
                ]
              },
              {
                text:[
                    `\nOn or before the `,
                    dataJson.d1.length>0 ? 
                {text:`     ${dataJson.d1}  `,style:"underlinedText"}:
                    `______`,
                ` day of `,
                dataJson.m1.length>0 ? 
                {text:`     ${dataJson.m1}  `,style:"underlinedText"}:
                `_______________`, 
                `20`,
                dataJson.y1.length>0 ? 
                {text:`${dataJson.y1} .`,style:"underlinedText"}:`____.\n`

                ]
              },
              {text:`This notice is given under and by authority of section §83.57 Florida Statutes. The tenancy under
which you have been occupying and using the said described premises as of the date shown above. `},
            {
            text:[
                `\nDated on this `,
                dataJson.d2.length>0 ? 
            {text:`     ${dataJson.d2}  `,style:"underlinedText"}:
                `______`,
            ` day of `,
            dataJson.m2.length>0 ? 
            {text:`     ${dataJson.m2}  `,style:"underlinedText"}:
            `_______________`, 
            `20`,
            dataJson.y2.length>0 ? 
            {text:`${dataJson.y2} .`,style:"underlinedText"}:`____.\n`

            ]
            },
            {
                text:[
                    `\n________________________________________`,
                    {text:`\nLandlord or Agent Signature\n\n`},
                dataJson.customerNames.length>0 ? {text: `${dataJson.customerNames}`, style:"underlinedText"}:
            `________________________________________`,
               {text:`\nName of Landlord/Property Manager (Circle One)\n\n`},

               dataJson.propertyAddress.length>0 ? {text: `${dataJson.propertyAddress}`, style:"underlinedText"}:
            `________________________________________`,
               {text:`\nAddress\n\n`},

               {text: `________________________________________`},
               {text:`\nCity, State, Zip Code\n\n`},
               dataJson.landlord_phone ? {text:`${dataJson.landlord_phone}`, style:"underlinedText"}
               : {text: `________________________________________`},
               {text:`\nPhone Number and Email Address\n\n`, },
               
                ],margin:[260,0,0,0],
                alignment:"left"
              },
              {
                text:[
                    `This NOTICE served on `,
                    ` the tenant, on the `,

                    dataJson.d3.length>0 ? 
                {text:`     ${dataJson.d3}  `,style:"underlinedText"}:
                    `______`,
                ` day of `,
                dataJson.m3.length>0 ? 
                {text:`     ${dataJson.m3}  `,style:"underlinedText"}:
                `_______________`, 
                `20`,
                dataJson.y3.length>0 ? 
                {text:`${dataJson.y3} .`,style:"underlinedText"}:`____`,
                ' at ',
                dataJson.time3.length>0 ? 
                {text:`${dataJson.time3} .`,style:"underlinedText"}:`_______`,
                `AM/PM (circle one). \n`
    
                ]
                },
                {
                    text:[
                        `By : `,
                        {text:dataJson.checkbox_value=="hand" ? `☑` :`☐`,style:'boxStyle'},
                        ` Personal Delivery \n`,
                        {text:dataJson.checkbox_value=="post" ? `☑` :`☐`,style:'boxStyle'},
                        `Posting a copy at resident`
                    ]
                }

            
            ],
            styles: {
              header: {
                fontSize: 12,
                bold: true,
                alignment: 'center',
                margin: [0, 0, 0, 5],
                fontFamily: '"Times New Roman",serif',
              },
              subheader: {
                fontSize: 11,
                bold: false,
                alignment: 'center',
                margin: [0, 0, 0, 10],
                fontFamily: '"Times New Roman",serif',
              },
              listItem: {
                fontSize: 11,
                margin: [10,0,10,7],
                alignment: 'left',
                lineHeight: 1.5,
                // marginLeft: 10,
                // marginRight: 10,
                // marginBottom: 5,
                bold:false,
                fontFamily: '"Times New Roman",serif',
              },
              statement: {
                fontSize: 11,
                margin: [10,0,10,10],
                lineHeight: 1.5,
                bold: false,
                // font: 'Times New Roman',
              },
              inputs:{
                fontSize: 11,
                margin: [10,0,10,0],
                lineHeight: 1.5,
                decoration:"underline"

              },
              underlinedText:{
                    width: 200,decoration:'underline',bold:true,
                    fontSize:11
              },
              boxStyle:{
                font:'DejaVu',bold:true,fontSize:13,lineHeight:1.4,
                decoration:'underline'
              }
            },
            defaultStyle: {
                fontSize: 10,
                alignment: 'justify',
                lineHeight: 1.5,
              }
            
            
          };
      
          // Create the PDF
          const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
          pdfDocGenerator.download('Three Day Notice - ' + new Date() + '.pdf');
    }

return (

    <div>
        <div>
        <Button color="primary" onClick={printPage}>Print</Button>
        <Paper id="mainComponent" 
    style={{marginLeft: "15%",marginRight:"15%",
    paddingLeft:"6%",paddingRight:"6%",lineHeight:"22px",fontSize: '0.8rem'}}>
      
     <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography variant="h6" component="h4" align="center"  sx={{fontWeight:"bold"}}>
      NOTICE OF TERMINATION OF TENANCY 
      </Typography>
      <Typography variant="subtitle1" align="center"  sx={{fontWeight:"bold"}}>
      (7 OR 15 DAY NOTICE: SEE INSTRUCTIONS)  <br></br>

      </Typography>
    </Box>
    <Box> 
    <Typography variant="body1">
        To:<br></br>
    <TextField variant="standard"
    size="small"
    value={dataJson.customerNames}
    onChange={(e)=> setDataJson({...dataJson,customerNames:e.target.value})}
    InputProps={{
      style: { fontSize: '1rem',fontWeight:'bold',width: "28rem" } // Adjust font size here
    }}/>
        <br />
        <Typography variant="body1">

          Tenant(s) Name
        </Typography>
      </Typography>
      </Box> <br></br>
      <Box> 
    <Typography variant="body1">
    <TextField variant="standard"
    size="small"
    value={dataJson.propertyAddress}
    onChange={(e)=> setDataJson({...dataJson,propertyAddress:e.target.value})}
    InputProps={{
      style: { fontSize: '1rem',fontWeight:'bold',width: "28rem" } // Adjust font size here
    }}/>
        <br />
        <Typography variant="body1">
          Address
        </Typography>
      </Typography>
      </Box> <br></br>
      <Box> 
    <Typography variant="body1">
    <TextField variant="standard"
    size="small"
    // value={dataJson.landlord_phone}
    // onChange={(e)=> setDataJson({...dataJson,landlord_phone:e.target.value})}
    InputProps={{
      style: { fontSize: '1rem',fontWeight:'bold',width: "18rem" } // Adjust font size here
    }}/>
        <br />
        <Typography variant="body1">
        City, State, Zip Code
                </Typography>
      </Typography>
      </Box> <br></br>
    <Box   flexDirection="column">
      <Typography variant="body1" >
          <p> Date of Delivery:  <TextField variant="standard" size="small" 
            value={dataJson.today}
            InputProps={{
                style: { fontSize: '1rem',fontWeight:'bold',width: "20rem" } // Adjust font size here
        }}/></p>
      </Typography>
    </Box><br></br><br></br>
    <Box   flexDirection="column">
      <Typography variant="body1" >
            You are hereby notified to quit and vacate the premises described as: <br></br>
         <TextField variant="standard" size="small" 
            value={dataJson.description}
            onChange={(e)=> setDataJson({...dataJson,description:e.target.value})}
            InputProps={{
                style: { fontSize: '1rem',fontWeight:'bold',width: "48rem" } // Adjust font size here
        }}/>

      </Typography>
    </Box><br></br><br></br>
    <Box   flexDirection="column">
      <Typography variant="body1">
      On or before the <TextField variant="standard" size="small" 
            value={dataJson.d1}
            onChange={(e)=> setDataJson({...dataJson,d1:e.target.value})}
            type="number"
            InputProps={{
                style: { fontSize: '1rem',fontWeight:'bold',width: "6rem" } // Adjust font size here
        }}/> day of <TextField variant="standard" size="small" 
        value={dataJson.m1}
        onChange={(e)=> setDataJson({...dataJson,m1:e.target.value})}
        InputProps={{
            style: { fontSize: '1rem',fontWeight:'bold',width: "4rem" } // Adjust font size here
    }}/>, 20<TextField variant="standard" size="small" 
    value={dataJson.y1}
    onChange={(e)=> setDataJson({...dataJson,y1:e.target.value})}
    type="number"
    InputProps={{
        style: { fontSize: '1rem',fontWeight:'bold',width: "4rem" } // Adjust font size here
    }}/>. 

      </Typography>
    </Box><br></br><br></br>
    <Box   flexDirection="column">
      <Typography variant="body1">
      This notice is given under and by authority of section §83.57 Florida Statutes. 
      The tenancy under which you have been occupying and using the said 
      described premises as of the date shown above. 
      </Typography>
    </Box><br></br><br></br>
    <Box   flexDirection="column">
      <Typography variant="body1">
      Dated on this <TextField variant="standard" size="small" 
            value={dataJson.d2}
            type="number"
            onChange={(e)=> setDataJson({...dataJson,d2:e.target.value})}
            InputProps={{
                style: { fontSize: '1rem',fontWeight:'bold',width: "6rem" } // Adjust font size here
        }}/> day of <TextField variant="standard" size="small" 
        value={dataJson.m2}
        onChange={(e)=> setDataJson({...dataJson,m2:e.target.value})}
        InputProps={{
            style: { fontSize: '1rem',fontWeight:'bold',width: "4rem" } // Adjust font size here
    }}/>, 20<TextField variant="standard" size="small" 
    value={dataJson.y2}
    type="number"
    onChange={(e)=> setDataJson({...dataJson,y2:e.target.value})}
    InputProps={{
        style: { fontSize: '1.0rem',fontWeight:'bold',width: "4rem" } // Adjust font size here
    }}/>. 

      </Typography>
    </Box><br></br><br></br>
    <Box> 
    <Typography variant="body1">
    <TextField variant="standard"
    size="small"
    // value={dataJson.landlord_phone}
    // onChange={(e)=> setDataJson({...dataJson,landlord_phone:e.target.value})}
    InputProps={{
      style: { fontSize: '1rem',fontWeight:'bold',width: "18rem" } // Adjust font size here
    }}/>
        <br />
        <Typography variant="body1">
        Landlord or Agent Signature
        </Typography>
      </Typography>
      </Box> 
      <Box> <br></br>
    <Typography variant="body1">
    <TextField variant="standard"
    size="small"
    value={dataJson.landlord_name}
    onChange={(e)=> setDataJson({...dataJson,landlord_name:e.target.value})}
    InputProps={{
      style: { fontSize: '1rem',fontWeight:'bold',width: "28rem" } // Adjust font size here
    }}/>
        <br />
        <Typography variant="body1">
        Name of Landlord/Property Manager (Circle One) 
        </Typography>
      </Typography>
      </Box> <br></br>
      <Box> 
    <Typography variant="body1">
    <TextField variant="standard"
    size="small"
    value={dataJson.landlord_address}
    onChange={(e)=> setDataJson({...dataJson,landlord_address:e.target.value})}
    InputProps={{
      style: { fontSize: '1rem',fontWeight:'bold',width: "28rem" } // Adjust font size here
    }}/>
        <br />
        <Typography variant="body1">
          Address
        </Typography>
      </Typography>
      </Box> <br></br>
      <Box> 
    <Typography variant="body1">
    <TextField variant="standard"
    size="small"
    // value={dataJson.landlord_phone}
    // onChange={(e)=> setDataJson({...dataJson,landlord_phone:e.target.value})}
    InputProps={{
      style: { fontSize: '1rem',fontWeight:'bold',width: "18rem" } // Adjust font size here
    }}/>
        <br />
        <Typography variant="body1">
        City, State, Zip Code 
        </Typography>
      </Typography>
      </Box> <br></br>
      <Box> 
    <Typography variant="body1">
    <TextField variant="standard"
    size="small"
    value={dataJson.landlord_phone}
    onChange={(e)=> setDataJson({...dataJson,landlord_phone:e.target.value})}
    InputProps={{
      style: { fontSize: '1rem',fontWeight:'bold',width: "18rem" } // Adjust font size here
    }}/>
        <br />
        <Typography variant="body1">
        Phone Number and Email Address
        </Typography>
      </Typography>
      </Box> <br></br>
      <Box   flexDirection="column">
      <Typography variant="body1">
      This NOTICE served on <TextField variant="standard" size="small" 
            value={dataJson.customerNames}
            InputProps={{
                style: { fontSize: '1rem',fontWeight:'bold',width: "32rem" } // Adjust font size here
        }}/>
      the tenant, on the <TextField variant="standard" size="small" 
            value={dataJson.d3}
            type="number"
            onChange={(e)=> setDataJson({...dataJson,d3:e.target.value})}
            InputProps={{
                style: { fontSize: '1rem',fontWeight:'bold',width: "6rem" } // Adjust font size here
        }}/> day of <TextField variant="standard" size="small" 
        value={dataJson.m3}
        onChange={(e)=> setDataJson({...dataJson,m3:e.target.value})}
        InputProps={{
            style: { fontSize: '1rem',fontWeight:'bold',width: "4rem" } // Adjust font size here
    }}/>, 20<TextField variant="standard" size="small" 
    value={dataJson.y3}
    type="number"
    onChange={(e)=> setDataJson({...dataJson,y3:e.target.value})}
    InputProps={{
        style: { fontSize: '1rem',fontWeight:'bold',width: "4rem" } // Adjust font size here
    }}/> at <TextField variant="standard" size="small" 
    value={dataJson.time3}
    onChange={(e)=> setDataJson({...dataJson,time3:e.target.value})}
    InputProps={{
        style: { fontSize: '1rem',fontWeight:'bold',width: "4rem" } // Adjust font size here
    }}/> AM/PM (circle one). 

      </Typography>
    </Box><br></br><br></br>
     
    <Box   flexDirection="column">
    <Typography variant="body1">
     By :  &nbsp;
      <input type="checkbox"  style={checkboxStyles}
      checked={dataJson.checkbox_value=="hand"}
      onChange={(e)=>setDataJson({...dataJson,checkbox_value:"hand"})}/> Personal Delivery 
      <br></br>
      <b style={{marginLeft:"5%"}}></b>
      <input type="checkbox"  style={checkboxStyles}
      checked={dataJson.checkbox_value=="post"}
      onChange={(e)=>setDataJson({...dataJson,checkbox_value:"post"})}/> Posting a copy at resident 
    </Typography> </Box>


    

    
    

    </Paper>
  {/*  
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-25pt"
    }}
  >
    To:&nbsp;
    <u>
    {customersDetails.map((c, index) => (
      <>
                   <strong>{c.fname + " " + c.lname + ","} </strong>
                   </>
                  // { index==0 ? <strong>{c.fname + " " + c.lname} </strong> : <strong>{" , " + c.fname + " " + c.lname} </strong>}
            ))}
            &nbsp;and all others
      
    </u>
  </p>
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-25pt"
    }}
  >
    &nbsp;
    <>
    {unitJson.name + ', ' + unitJson.streetAddress + ", " + propertyJson.city + ", " + propertyJson.county 
                 + ' COUNTY, ' + propertyJson.state + ' - ' + propertyJson.zipCode}
      
    </>
  </p>
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-25pt"
    }}
  >
    &nbsp;
    <u>
    
    </u>
  </p>
  <p>
    <br />
  </p>

  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginTop: "-2cm",
      marginRight: "104.8pt",
      marginBottom: ".0001pt",
      marginLeft: "105.95pt",
      textIndent: "35.95pt"
    }}
  >
    You are hereby notified that you are indebted to me in the sum of <b>$</b>
    
    <u>
    <mark style={{backgroundColor:"yellow"}}>
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp;&nbsp;
      </mark>
    </u>
    
    ,&nbsp;for the rent and use of the premises located at{" "}
   
    <>
    {unitJson.name + ', ' + unitJson.streetAddress + ", " + propertyJson.city + ", " + propertyJson.county 
                 + ' COUNTY, ' + propertyJson.state + ' - ' + propertyJson.zipCode}
      <br></br>
    </>

    <em>
      <span style={{ fontSize: 16 }}>
        (address&nbsp;of&nbsp;leased&nbsp;premises,&nbsp;including&nbsp;county
        and state)
      </span>
    </em>
    <span style={{ fontSize: 16 }}>, now occupied by you.</span>

  </p>
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      marginTop: "-2cm",
      fontFamily: '"Times New Roman",serif',
      marginRight: "104.8pt",
      marginBottom: ".0001pt",
      marginLeft: "105.95pt",
      textIndent: "35.95pt"
    }}
  >
    I&nbsp;demand&nbsp;payment&nbsp;of&nbsp;the&nbsp;rent&nbsp;in&nbsp;full,&nbsp;or
    possession&nbsp;of&nbsp;the&nbsp;premises,&nbsp;within three
      (3) days <em>(excluding Saturday, Sunday, or legal holidays)&nbsp;</em>
      from the date of delivery of this notice, specifically on or before the
      following date:&nbsp;
      <u>
      <mark style={{backgroundColor:"yellow"}}> 
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </mark>
      </u>{" "}
      <em>(month, date, and year)</em>.

  </p>
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginTop: "-2cm",
      marginRight: "108.6pt",
      marginBottom: ".0001pt",
      marginLeft: "106.0pt",
      textIndent: "36.0pt"
    }}
  >
    I&nbsp;certify&nbsp;that&nbsp;a&nbsp;copy&nbsp;of&nbsp;this&nbsp;Notice&nbsp;was&nbsp;served&nbsp;on&nbsp;the&nbsp;tenant&nbsp;named&nbsp;above,&nbsp;at&nbsp;the
    address noted above, on{" "}
    <b>
      {today}
    </b>{" "}
    <em>(date)</em>, by one of the following methods <em>(check one)</em>:
  </p>
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginTop: "13.75pt",
      marginRight: "108.6pt",
      marginBottom: ".0001pt",
      marginLeft: "106.0pt"
    }}
  >
    <u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</u> Mail&nbsp; &nbsp; &nbsp;
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    <u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</u> Hand delivery to
    tenant&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
    <u><mark style={{backgroundColor:"yellow"}}>
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; </mark></u> Copy left at premises
  </p>


  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-0.1cm",
    }}
  >
   <mark style={{backgroundColor:"yellow"}}> __________________________________</mark><br></br>
    Landlord’s&nbsp;Signature
  </p>
 

  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-0.5cm"
    }}
  >
   <mark style={{backgroundColor:"yellow",textDecoration:"underline"}}>{landlordJson.name}</mark> <br></br>
    Landlord’s&nbsp;Printed&nbsp;Name
  </p>



  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-0.5cm"
    }}
  >
   <u>{landlordJson.address}</u><br></br>
    Landlord’s&nbsp;Address
  </p> */}

  {/* <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-0.5cm"
    }}
  >
    <u>LAKELAND, FL, 33815</u><br></br>
    Landlord’s City, State &amp; Zip 
  </p> */}
  {/* <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif'
    }}
  >
    <span style={{ fontSize: 13 }}>&nbsp;</span>
  </p> */}
  {/* <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginTop: "3.15pt"
    }}
  >
    &nbsp;
  </p> */}

  {/* <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-0.5cm"
    }}
  >
    Landlord’s Phone # <u>{landlordJson.phoneNumber}</u>
  </p> */}
</div>
</div>


);
}