import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import { parseResponseJSON} from "./CommonFunctions";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,DialogActions,
    Button
} from "@mui/material";
import { getUserRole, isAuthenticated } from "./Auth";

const DeleteConfirmation = ({setShowDeleteModal,showDeleteModal,tableName,id}) => {
    
    const [isAdmin,setIsAdmin]=useState(false)
    const handleCloseDeletePopup=()=>{
        setShowDeleteModal(false)
    }
    useEffect(() => {
      if(getUserRole() ==="admin"){
        setIsAdmin(true);
      }


        
    }, []);


    return (
        <>
      <Dialog
        open={showDeleteModal}
        onClose={handleCloseDeletePopup}
      >
        {isAdmin===false ? 
        <>
        <DialogTitle>Alert </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDeletePopup}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        
        <DialogContent>
          <DialogContentText> <b>You are not authorised to perform delete operation. 
            Please contact your administration for further details.</b>
          </DialogContentText>
          
          
        </DialogContent>
        </>
         :
        <>
        <DialogTitle>Confirmation</DialogTitle>
        
        <DialogContent>
          <DialogContentText>Are you sure to delete this {tableName} ?
          </DialogContentText>
          
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeletePopup}>No</Button>
          <Button type="submit" onClick={(e)=>{
           
          }}>Yes</Button>
        </DialogActions>
        </> }
      </Dialog>
                
            
        </>
    );

}
export default DeleteConfirmation;