import  React,{
    useState,
    useEffect
} from 'react';
import axios from 'axios';
import { 
    Link, useParams 
} from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button,Box } from '@mui/material';

import Header from "../Header"
import MakePayment from '../MakePayment';
import PaymentHistory from '../PaymentHistory';
import AddUnit from './AddUnit';


export default function ListOfUnits() {
    const [units,setUnits]= useState([]);
    const {propertyId} = useParams();
    const [showMakePaymentModal,setShowMakePaymentModal]=useState(false);
    const [showPaymentHistoryModal,setShowPaymentHistoryModal]=useState(false);
    const [showAddUnitModal,setShowAddUnitModal]=useState(false)
    const [unitJson,setUnitJson]=useState({})
    const url = process.env.REACT_APP_SERVER_URL;
    const [tenancyId,setTenancyId]=useState(null)
    useEffect(() => {
      if(propertyId){
        getAllUnitsByPropertyId(propertyId);
      }
        
    
      }, [propertyId]);


    const getAllUnitsByPropertyId = async(propertyId) =>{
        try{
            await axios.get(`${url}/property/${propertyId}/units`).then((responseForAllUnits)=>{
                console.log(responseForAllUnits.data);
                setUnits(responseForAllUnits.data);
            })

        }
        catch(error)
        {
            console.log(error)
        }
    }

  return (
    <>
    <Header/>
    <br></br>
    <Button sx={{marginLeft:"80%"}} onClick={(e)=>setShowAddUnitModal(true)}>Add Unit</Button>
    <Link to={`/property/${propertyId}/monthlyPaymentReport`}><Button>Monthly Report</Button></Link>
    
    <Paper sx={{ m:1, width: '80%', overflow: 'hidden',marginLeft:"10%" }}>
      <TableContainer sx={{ maxHeight: window.innerHeight }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            <TableCell>Id</TableCell>
            <TableCell >Name</TableCell>
            <TableCell >Address</TableCell>
            <TableCell >Lease Info</TableCell>
            <TableCell >Next Payment Due Date</TableCell>
            <TableCell align="right">...</TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {units.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Link to={`/property/${row.propertyId}/units/${row.id}`}>
                  {row.id}
                  </Link>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell >{row.streetAddress}</TableCell>
                {row.currentTenancyId ? 
                <TableCell >
                  <Link to={`/property/${row.propertyId}/units/${row.id}/lease/${row.currentTenancyId}`}>
                    Rented 
                  </Link><br></br>
                  ({"$"+row.rate + " " + row.rentalType + " from " + row.checkin + " to " + row.checkout} ) 
                  </TableCell>
                 : <TableCell ></TableCell>}
                 <TableCell> (pending balance)</TableCell>
                <TableCell align="right">
                  <Link to={`/property/${row.propertyId}/units/${row.id}/lease`}>Create Lease </Link><br></br>
                  <Link onClick={(e)=>{
                    setTenancyId(row.currentTenancyId)
                    setShowMakePaymentModal(true)}}>Take Payment </Link><br></br>
                    <Link onClick={(e)=>{
                    setTenancyId(row.currentTenancyId)
                    setUnitJson(row)
                    setShowPaymentHistoryModal(true)}}> Payment History </Link>
                </TableCell>
              
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    {showMakePaymentModal && <MakePayment showMakePaymentModal={showMakePaymentModal} 
    tenancyJson={{id:tenancyId}}
    setShowMakePaymentModal={setShowMakePaymentModal}/>}
    {showPaymentHistoryModal && <PaymentHistory showPaymentHistoryModal={showPaymentHistoryModal} 
    tenancyJson={{id:tenancyId}} unitJson={unitJson}
    setShowPaymentHistoryModal={setShowPaymentHistoryModal}/>}
    {showAddUnitModal && <AddUnit showAddUnitModal={showAddUnitModal} setShowAddUnitModal={setShowAddUnitModal}
    unitRows={units}/>}
    </>
  );
}