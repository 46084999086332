import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, useRef } from "react";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { formatDate } from "../CommonFunctions";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Grid,
  Checkbox,
  List,
  ListItem,
  Button,


} from '@mui/material';
import { parseISO } from "date-fns";

// Register fonts with pdfmake
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts={
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf'
},
  DejaVu:{
    normal:"DejaVuSans.ttf",
    bold:"DejaVuSans-Bold.ttf"
  }
}
fetch('/fonts/DejaVuSans.ttf').then(res => res.arrayBuffer()).then(buffer => {
  pdfMake.vfs['DejaVuSans.ttf'] = buffer;
});
fetch('/fonts/DejaVuSans-Bold.ttf').then(res => res.arrayBuffer()).then(buffer => {
  pdfMake.vfs['DejaVuSans-Bold.ttf'] = buffer;
});
const checkboxStyles={ transform: "scale(1.2)", 
  WebkitTransform: "scale(1.2)", width: "1rem", height: "1rem" };
export const ThreeDayNotice=({tenancyJson,unitJson,landlordJson,customersDetails,propertyJson})=>{
    const [show,setShow]=useState(true);
    const today=formatDate(new Date());
    const url=process.env.REACT_APP_SERVER_URL;
    const [dataJson,setDataJson]= useState({
      today:new Date().toLocaleDateString('en-us',{month:"short",day:"numeric", year:"numeric"}),
      county:propertyJson.county ? propertyJson.county : "",
      customerNames:customersDetails.length>0 ? customersDetails.map(person => `${person.fname} ${person.lname}`).join(", ") 
      + " and all others" : " ",
      propertyAddress: unitJson.name + ', ' + unitJson.streetAddress ,
      landlord_name:landlordJson.name ? landlordJson.name : "",
      landlord_address: landlordJson.address ? landlordJson.address : "",
      landlord_phone: landlordJson.phoneNumber ? landlordJson.phoneNumber : "",
      amount:"",
      lastDate:"",
      checkbox_value:"copy"



    })
    useEffect(() => {
        
       },[]);
    const handleClose=()=>{
        setShow(false);
        // props.setIsThreeDayNoticeFormVisible(false);
        // props.setIsCheckinFormVisible(false)
    }
    function printPage(){
        
        // const rulesContent=[];
        // for (let i = 0; i < rules.length; i++) {
        //     rulesContent.push({ text: rules[i], style: 'listItem' });
        //   }
        
        const documentDefinition = {
            content: [
              {
                text: 'THREE DAY NOTICE TO TENANT',
                style: 'header'
              },
              
              {
                text: 'Demand for Payment or Possession\nFS 83.56\n\n',
                style: 'subheader'
              },
            //   {
            //     margin: [20,0,0,10],
            //     fontSize: 11,
            //     text:[
            //         { text: 'Name(s) : ' }, // Text before the bold word
            //         { text: nameString + '\n', bold: true }, // Bold word
            //     ] 
            //   },
              {
                alignment: 'justify',
                margin: [20,0,0,10],
                fontSize: 11,
                columns: [
                    {
                        text: [
                            { text: ' Date Sent : ' }, // Text before the bold word
                            { text: dataJson.today + '\n\n', bold: true }, // Bold word
                        ]
                    },
                    {
                        text:[
                            { text: ' County : ' }, // Text before the bold word
                            { text: `${dataJson.county}` + '\n\n', bold: true }, // Bold word
                        ]
                    }

                ]

              },
        
              {
                text: 'To ' + `${dataJson.customerNames}` + "\n  " + 
                `${dataJson.propertyAddress ? dataJson.propertyAddress : '________________________________________'}`,
                bold:true,
                style: 'statement'
              },
            
              {
                text:[`\t           You are hereby notified that you are indebted to me in the sum of $ ${dataJson.amount ? dataJson.amount : '____________________'}
                , for the rent and use of the premises located at ${dataJson.propertyAddress}  (address of leased premises, including county and state), now occupied by you.\n\n`,
                ],style: 'statement'
              },
              {
                text:['\t           I demand payment of the rent in full, or possession of the premises, within three (3) days (excluding Saturday, Sunday, or legal holidays) from the date of delivery of this notice, specifically on or before the following date: ',
                dataJson.lastDate ? dataJson.lastDate : '_______________________' ,
                 ' (month, date, and year).\n\n',
                ],style: 'statement'
              },

              {
                text:'\t           I certify that a copy of this Notice was served on the tenant named above, at the address noted above, on '+ today +' (date), by one of the following methods (check one):\n\n',
                style: 'statement'
              },

              {
                text:[
                  {text:dataJson.checkbox_value=="mail" ? `☑` :`☐`,style:'boxStyle'},
                  ' Mail           ',
                  {text:dataJson.checkbox_value=="hand" ? `☑` :`☐`,style:'boxStyle'},
                  ' Hand delivery to tenant          ',
                  {text:dataJson.checkbox_value=="copy" ? `☑` :`☐`,style:'boxStyle'},
                  ' Copy left at premises\n\n',
                  ],style: 'statement'
              },
              {
                text:'__________________________________\nLandlord’s Signature\n',
                style: 'statement'
              },
              {
                text:dataJson.landlord_name.length>0 ? `${dataJson.landlord_name}` : '__________________________________',
                style:'statement',
                decoration:'underline',
                bold:true
              },
              {
                text:'Landlord’s Printed Name\n',
                style: 'statement'
              },
              
              {
                text:`${dataJson.landlord_address}\n`,
                bold: true,
                style:"inputs"
              },
              {
                text:'Landlord’s  Address\n',
                style: 'statement'
              },
            //   {
            //     text:'LAKELAND, FL, 33815\n',
            //     bold: true,
            //     // style: 'statement'
            //     style:"inputs"
            //   },
            //   {
            //     text:'Landlord’s City, State & Zip\n',
            //     style: 'statement'
            //   },
              {
                text:`${dataJson.landlord_phone}\n`,
                bold: true,
                // style: 'statement'
                style:"inputs"
              },
              {
                text:'Landlord’s Phone # ',
                style: 'statement'
              },
            ],
            styles: {
              header: {
                fontSize: 12,
                bold: true,
                alignment: 'center',
                margin: [0, 0, 0, 5],
                fontFamily: '"Times New Roman",serif',
              },
              subheader: {
                fontSize: 11,
                bold: false,
                alignment: 'center',
                margin: [0, 0, 0, 10],
                fontFamily: '"Times New Roman",serif',
              },
              listItem: {
                fontSize: 11,
                margin: [10,0,10,7],
                alignment: 'left',
                lineHeight: 1.5,
                // marginLeft: 10,
                // marginRight: 10,
                // marginBottom: 5,
                bold:false,
                fontFamily: '"Times New Roman",serif',
              },
              statement: {
                fontSize: 11,
                margin: [10,0,10,10],
                lineHeight: 1.5,
                bold: false,
                // font: 'Times New Roman',
              },
              inputs:{
                fontSize: 11,
                margin: [10,0,10,0],
                lineHeight: 1.5,
                decoration:"underline"

              },
              boxStyle:{
                font:'DejaVu',bold:true,fontSize:13,lineHeight:1.4,
                decoration:'underline'
              }
            },
            
            
          };
      
          // Create the PDF
          const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
          pdfDocGenerator.download('Three Day Notice - ' + new Date() + '.pdf');
    }

return (

    <div>
        <div>
        <Button color="primary" onClick={printPage}>Print</Button>
        <Paper id="mainComponent" 
    style={{marginLeft: "15%",marginRight:"15%",
    paddingLeft:"6%",paddingRight:"6%",lineHeight:"22px",fontSize: '0.8rem'}}>
      
     <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography variant="h6" component="h4" align="center"  sx={{fontWeight:"bold"}}>
      THREE DAY NOTICE TO TENANT 
      </Typography>
      <Typography variant="subtitle1" align="center"  sx={{fontWeight:"bold"}}>
      Demand for Payment or Possession <br></br> FS 83.56

      </Typography>
    </Box>
    <Box   flexDirection="column">
      <Typography variant="body1"   sx={{
        display:"flex",justifyContent:"space-between"}}>
          <p> Date Sent : <TextField variant="standard"
    size="small"
    value={dataJson.today}
    InputProps={{
      style: { fontSize: '0.9rem',fontWeight:'bold',width: "18rem" } // Adjust font size here
    }}/></p>
          <p> County : <TextField variant="standard"  size="small" 
          value={dataJson.county}
          onChange={(e)=> setDataJson({...dataJson,county:e.target.value})}
          InputProps={{
            style: { fontSize: '0.9rem',fontWeight:'bold',width: "12rem" } 
          }}/></p>
          <> <u></u></>
      </Typography>
    </Box><br></br><br></br>
    <Box   flexDirection="column">
      <Typography variant="body1"   >
          To <TextField variant="standard" size="small"
          value={dataJson.customerNames}
          onChange={(e)=> setDataJson({...dataJson,customerNames:e.target.value})}
          InputProps={{
            style: { fontSize: '0.9rem',fontWeight:'bold',width: "32rem" } 
          }}/>
          <br></br><TextField variant="standard" size="small"
          value={dataJson.propertyAddress}
          onChange={(e)=> setDataJson({...dataJson,propertyAddress:e.target.value})}
          InputProps={{
            style: { fontSize: '0.9rem',fontWeight:'bold',width: "32rem" } 
          }}/><br></br>
      </Typography>
    </Box>
    <br></br><br></br>
    <Box   flexDirection="column">
      <Typography variant="body1"   >
      You are hereby notifed that you are indebted to me in the 
      sum of $ <TextField variant="standard" size="small"
        value={dataJson.amount}
          onChange={(e)=> setDataJson({...dataJson,amount:e.target.value})}
        InputProps={{
          style: { fontSize: '0.8rem',fontWeight:'bold',width: "12rem" } 
        }}/> , for the rent and use of the 
      premises located at <TextField variant="standard" size="small"
        value={dataJson.propertyAddress}
          onChange={(e)=> setDataJson({...dataJson,propertyAddress:e.target.value})}
        InputProps={{
          style: { fontSize: '0.9rem',fontWeight:'bold',width: "32rem" } 
        }}/>
       (address of leased premises, including county and state), now occupied by you.
      </Typography>
    </Box>
    <br></br><br></br>
    <Box   flexDirection="column">
      <Typography variant="body1"   >
      I demand payment of the rent in full, or possession of the premises, 
      within three (3) days (excluding Saturday, Sunday, or legal holidays) 
      from the date of delivery of this notice, specifcally on or before 
      the following date: <TextField variant="standard" size="small"
      value={dataJson.lastDate}
      onChange={(e)=> setDataJson({...dataJson,lastDate:e.target.value})}
    InputProps={{
      style: { fontSize: '0.8rem',fontWeight:'bold',width: "18rem" } // Adjust font size here
    }}/>(month, date, and year).
      </Typography>
    </Box>
    <br></br><br></br>
    <Box   flexDirection="column">
      <Typography variant="body1"   >
      I certify that a copy of this Notice was served on the tenant named above, 
      at the address noted above, on <TextField variant="standard"
    size="small"
    value={dataJson.today}
    InputProps={{
      style: { fontSize: '0.9rem',fontWeight:'bold',width: "12rem" } // Adjust font size here
    }}/> (date), 
      by one of the following methods (check one):<br></br> <br></br>
      <input type="checkbox"  style={checkboxStyles}
      checked={dataJson.checkbox_value=="mail"}
      onChange={(e)=>setDataJson({...dataJson,checkbox_value:"mail"})}/> Mail 
      <b style={{marginLeft:"5%"}}></b>
      <input type="checkbox"  style={checkboxStyles}
      checked={dataJson.checkbox_value=="hand"}
      onChange={(e)=>setDataJson({...dataJson,checkbox_value:"hand"})}/> Hand delivery to tenant 
      <b style={{marginLeft:"5%"}}></b>
      <input type="checkbox"  style={checkboxStyles}
      checked={dataJson.checkbox_value=="copy"}
      onChange={(e)=>setDataJson({...dataJson,checkbox_value:"copy"})}/> Copy left at premises
      <b style={{marginLeft:"5%"}}></b>
      </Typography>
    </Box>
    <br></br><br></br>
    <Box> 
    <Typography variant="body1">
    <TextField variant="standard"
    size="small"
    InputProps={{
      style: { fontSize: '0.9rem',fontWeight:'bold',width: "18rem" } // Adjust font size here
    }}/>
        <br />
        <Typography variant="body">
          Landlord's Signature
        </Typography>
      </Typography>
      </Box> 
      <br></br><br></br>
    <Box> 
    <Typography variant="body1">
    <TextField variant="standard"
    size="small"
    value={dataJson.landlord_name}
    onChange={(e)=> setDataJson({...dataJson,landlord_name:e.target.value})}
    InputProps={{
      style: { fontSize: '0.9rem',fontWeight:'bold',width: "18rem" } // Adjust font size here
    }}/>
        <br />
        <Typography variant="body">
        Landlord’s Printed Name
        </Typography>
      </Typography>
      </Box> 
      <br></br><br></br>
    <Box> 
    <Typography variant="body1">
    <TextField variant="standard"
    size="small"
    value={dataJson.landlord_address}
    onChange={(e)=> setDataJson({...dataJson,landlord_address:e.target.value})}
    InputProps={{
      style: { fontSize: '0.9rem',fontWeight:'bold',width: "32rem" } // Adjust font size here
    }}/>
        <br />
        <Typography variant="body">
          Landlord's Address
        </Typography>
      </Typography>
      </Box> 
      <br></br><br></br>
    <Box> 
    <Typography variant="body1">
    <TextField variant="standard"
    size="small"
    value={dataJson.landlord_phone}
    onChange={(e)=> setDataJson({...dataJson,landlord_phone:e.target.value})}
    InputProps={{
      style: { fontSize: '0.9rem',fontWeight:'bold',width: "18rem" } // Adjust font size here
    }}/>
        <br />
        <Typography variant="body">
          Landlord's Phone Number
        </Typography>
      </Typography>
      </Box> 
    

    
    

    </Paper>
  {/*  
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-25pt"
    }}
  >
    To:&nbsp;
    <u>
    {customersDetails.map((c, index) => (
      <>
                   <strong>{c.fname + " " + c.lname + ","} </strong>
                   </>
                  // { index==0 ? <strong>{c.fname + " " + c.lname} </strong> : <strong>{" , " + c.fname + " " + c.lname} </strong>}
            ))}
            &nbsp;and all others
      
    </u>
  </p>
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-25pt"
    }}
  >
    &nbsp;
    <>
    {unitJson.name + ', ' + unitJson.streetAddress + ", " + propertyJson.city + ", " + propertyJson.county 
                 + ' COUNTY, ' + propertyJson.state + ' - ' + propertyJson.zipCode}
      
    </>
  </p>
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-25pt"
    }}
  >
    &nbsp;
    <u>
    
    </u>
  </p>
  <p>
    <br />
  </p>

  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginTop: "-2cm",
      marginRight: "104.8pt",
      marginBottom: ".0001pt",
      marginLeft: "105.95pt",
      textIndent: "35.95pt"
    }}
  >
    You are hereby notified that you are indebted to me in the sum of <b>$</b>
    
    <u>
    <mark style={{backgroundColor:"yellow"}}>
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp;&nbsp;
      </mark>
    </u>
    
    ,&nbsp;for the rent and use of the premises located at{" "}
   
    <>
    {unitJson.name + ', ' + unitJson.streetAddress + ", " + propertyJson.city + ", " + propertyJson.county 
                 + ' COUNTY, ' + propertyJson.state + ' - ' + propertyJson.zipCode}
      <br></br>
    </>

    <em>
      <span style={{ fontSize: 16 }}>
        (address&nbsp;of&nbsp;leased&nbsp;premises,&nbsp;including&nbsp;county
        and state)
      </span>
    </em>
    <span style={{ fontSize: 16 }}>, now occupied by you.</span>

  </p>
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      marginTop: "-2cm",
      fontFamily: '"Times New Roman",serif',
      marginRight: "104.8pt",
      marginBottom: ".0001pt",
      marginLeft: "105.95pt",
      textIndent: "35.95pt"
    }}
  >
    I&nbsp;demand&nbsp;payment&nbsp;of&nbsp;the&nbsp;rent&nbsp;in&nbsp;full,&nbsp;or
    possession&nbsp;of&nbsp;the&nbsp;premises,&nbsp;within three
      (3) days <em>(excluding Saturday, Sunday, or legal holidays)&nbsp;</em>
      from the date of delivery of this notice, specifically on or before the
      following date:&nbsp;
      <u>
      <mark style={{backgroundColor:"yellow"}}> 
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </mark>
      </u>{" "}
      <em>(month, date, and year)</em>.

  </p>
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginTop: "-2cm",
      marginRight: "108.6pt",
      marginBottom: ".0001pt",
      marginLeft: "106.0pt",
      textIndent: "36.0pt"
    }}
  >
    I&nbsp;certify&nbsp;that&nbsp;a&nbsp;copy&nbsp;of&nbsp;this&nbsp;Notice&nbsp;was&nbsp;served&nbsp;on&nbsp;the&nbsp;tenant&nbsp;named&nbsp;above,&nbsp;at&nbsp;the
    address noted above, on{" "}
    <b>
      {today}
    </b>{" "}
    <em>(date)</em>, by one of the following methods <em>(check one)</em>:
  </p>
  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginTop: "13.75pt",
      marginRight: "108.6pt",
      marginBottom: ".0001pt",
      marginLeft: "106.0pt"
    }}
  >
    <u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</u> Mail&nbsp; &nbsp; &nbsp;
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    <u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</u> Hand delivery to
    tenant&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
    <u><mark style={{backgroundColor:"yellow"}}>
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; </mark></u> Copy left at premises
  </p>


  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-0.1cm",
    }}
  >
   <mark style={{backgroundColor:"yellow"}}> __________________________________</mark><br></br>
    Landlord’s&nbsp;Signature
  </p>
 

  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-0.5cm"
    }}
  >
   <mark style={{backgroundColor:"yellow",textDecoration:"underline"}}>{landlordJson.name}</mark> <br></br>
    Landlord’s&nbsp;Printed&nbsp;Name
  </p>



  <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-0.5cm"
    }}
  >
   <u>{landlordJson.address}</u><br></br>
    Landlord’s&nbsp;Address
  </p> */}

  {/* <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-0.5cm"
    }}
  >
    <u>LAKELAND, FL, 33815</u><br></br>
    Landlord’s City, State &amp; Zip 
  </p> */}
  {/* <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif'
    }}
  >
    <span style={{ fontSize: 13 }}>&nbsp;</span>
  </p> */}
  {/* <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginTop: "3.15pt"
    }}
  >
    &nbsp;
  </p> */}

  {/* <p
    style={{
      margin: "0cm",
      fontSize: 16,
      fontFamily: '"Times New Roman",serif',
      marginLeft: "106.0pt",
      marginTop: "-0.5cm"
    }}
  >
    Landlord’s Phone # <u>{landlordJson.phoneNumber}</u>
  </p> */}
</div>
</div>


);
}