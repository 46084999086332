import { BrowserRouter, Routes, Route } from 'react-router-dom'
import logo from './logo.svg';
import './App.css';
import 'rsuite/dist/rsuite.min.css';
import AddProperty from './components/Property/AddProperty';
import PropertyWizard from './components/Property/PropertyWizard';
import PropertyList from './components/Property/PropertyList';
import ListOfUnits from './components/Property/ListOfUnits';
import UnitsAvailability from './components/Property/UnitsAvailability';
import LeaseWizard from './components/Lease/LeaseWizard';
import AllTenants from './components/AllTenants';
import Payments from './components/Payments';
import RepairsReport from './components/Repairs/RepairsReport';
import AmenitiesReport from './components/Amenities/AmenitiesReport';
import Editlease from './components/Lease/EditLease';
import MonthlyPaymentsReport from './components/MonthlyPaymentsReport';
import Lease from './components/Lease/Lease';
import LoginPage from './components/LoginPage';
import ProtectedRoute from './components/ProtectedRoute';
import LeaseDocument from './components/Lease/LeaseDocument';
import Dashboard from './components/AdminDashboard/Dashboard';

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route element={<ProtectedRoute requiredRole={["admin","manager"]} />}>
        <Route path="/property" element={<PropertyWizard />} />
        <Route path="/leaseDocument" element={<LeaseDocument />} />
        <Route path="/property/:propertyId/units" element={<ListOfUnits />} />
        <Route path="/property/:propertyId" element={<PropertyWizard />} />
        <Route path="/properties" element={<PropertyList />} />
        {/* <Route path="/units/availability" element={<UnitsAvailability />} /> */}
        <Route path="/property/:propertyId/lease" element={<Lease />} />
        <Route path="/property/:propertyId/units/:unitId/lease" element={<Lease />} />
        <Route path="/property/:propertyId/units/:unitId/lease/:tenancyId" element={<Editlease />} />
        <Route path="/property/:propertyId/tenants" element={<AllTenants/>} />
        <Route path="/property/:propertyId/payments" element={<Payments/>} />
        <Route path="/property/:propertyId/repairs" element={<RepairsReport/>} />
        <Route path="/property/:propertyId/amenities" element={<AmenitiesReport/>} />
        <Route path="/property/:propertyId/monthlyPaymentReport" element={<MonthlyPaymentsReport />} />
                </Route>
        <Route path="/property/:propertyId/dashboard" element={<Dashboard />} />
        
        {/* <Route path="/property" element={<PropertyWizard />} /> */}
        
        
      </Routes>
      <script src="https://accounts.google.com/gsi/client" async defer></script>
    </BrowserRouter>
  );
}

export default App;
