import  React,{
    useState,
    useEffect
} from 'react';
import axios from 'axios';
import { 
    Link, useParams 
} from "react-router-dom";

import {
    FilledInput,
    InputLabel,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormGroup,
    TextField
} from '@mui/material';
export default function AdditionalLeaseInfo({tenancyJson,setTenancyJson}) {
    console.log(tenancyJson)
    useEffect(() => {
    
      }, []);




  return (
    <>
    <br></br>
    Utilities Included:
    
    <FormGroup sx={{width:"70%",display:"flex",flexDirection: 'row',justifyContent: 'space-around'}}>
      <FormControlLabel 
        control={<Checkbox checked={tenancyJson.electricity==1} onChange={(e)=>{
          const targetValue=e.target.checked ? 1 : 0;
          setTenancyJson({...tenancyJson,electricity:targetValue})
        }}
        />} label="Electricity" />
      <FormControlLabel 
        control={<Checkbox checked={tenancyJson.water==1} onChange={(e)=>{
          const targetValue=e.target.checked ? 1 : 0;
          setTenancyJson({...tenancyJson,water:targetValue})
        }}
        />} label="Water" />
      <FormControlLabel 
        control={<Checkbox checked={tenancyJson.garbage==1} onChange={(e)=>{
          const targetValue=e.target.checked ? 1 : 0;
          setTenancyJson({...tenancyJson,garbage:targetValue})
        }}
        />} label="Garbage" />
      <FormControlLabel 
        control={<Checkbox checked={tenancyJson.sewage==1} onChange={(e)=>{
          const targetValue=e.target.checked ? 1 : 0;
          setTenancyJson({...tenancyJson,sewage:targetValue})
        }}
        />} label="Sewage" />
      <FormControlLabel control={<Checkbox 
      checked={tenancyJson.sewage==0  && tenancyJson.garbage==0 && tenancyJson.water==0 && tenancyJson.electricity==0} 
      onChange={(e)=>{
          const targetValue=e.target.checked ? 0 : 1;
          setTenancyJson({...tenancyJson,
            sewage:targetValue,
            garbage:targetValue,
            water:targetValue,
            electricity:targetValue
          })
        }}
      />} label="None" />
      
      <FormControlLabel control={<Checkbox 
      checked={tenancyJson.sewage==1  && tenancyJson.garbage==1 && tenancyJson.water==1 && tenancyJson.electricity==1} 
      onChange={(e)=>{
          const targetValue=e.target.checked ? 1 : 0;
          setTenancyJson({...tenancyJson,
            sewage:targetValue,
            garbage:targetValue,
            water:targetValue,
            electricity:targetValue
          })
        }}/>} label="All" />
    </FormGroup>

  
        <br></br>    

        
        <FormControl sx={{ m: 1}} variant="filled">
        <TextField
          id="outlined-multiline-static"
          label="Additional Conditions"
          multiline
          style={{ width: '200%' }}
          value={tenancyJson.description} 
          onChange={(e)=>setTenancyJson({...tenancyJson,description:e.target.value})}
          rows={3} // Specify the number of rows you want to display
          variant="outlined"
        />
        </FormControl> 
        <br></br>

    </>
  );
}