
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Snackbar,
    Alert
    } from '@mui/material';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
  } from '@mui/x-data-grid';
  import {
    randomCreatedDate,
    randomTraderName,
    randomId,
    randomArrayItem,
  } from '@mui/x-data-grid-generator';
import AddTenant from './AddTenant';

const TenantInfo = ({rows,setRows}) => {
    const {propertyId}=useParams();
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const {tenancyId} = useParams();
  const [showAddTenantModal,setShowAddTenantModal]=useState(false)
    const url = process.env.REACT_APP_SERVER_URL;
    const [customer,setCustomer] = useState({})
  const columns = [
    { field: 'fname', headerName: 'First Name', width: 160 },
    { field: 'lname', headerName: 'Last Name', width: 160 },
    { field: 'phone', headerName: 'Phone Number', width: 170 },
    { field: 'email', headerName: 'Email', width: 220 },
    { field: 'address', headerName: 'Address', width: 300 },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: (rowData) => {
            return [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={(e)=>{
                    setCustomer(rowData.row)
                    setShowAddTenantModal(true)}}
                  color="inherit"
                />,
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  color="inherit"
                />,
            ];
  
          
        },
    }
  ];
  
  // const [rows, setRows] = useState([]);
  let [rowModesModel, setRowModesModel] = useState({});
  useEffect(() => {
    if(tenancyId){
      getCustomerDetailsByTenancyId(tenancyId)
    }
    
  }, [tenancyId]);






    const getCustomerDetailsByTenancyId=async(tenancyId)=>{
      try{
        if(tenancyId){
          await axios.get(`${url}/tenants/${tenancyId}/customers`).then((responseForGettingCustomers)=>{
            console.log(responseForGettingCustomers.data)
            setRows(responseForGettingCustomers.data);
          });
        }
          

      }catch(error){
          console.log(error);
      }
  }
    
  return (
    <>
    <br></br>
        <Button color="primary" startIcon={<AddIcon />} onClick={(e)=>{
          setCustomer({})
          setShowAddTenantModal(true);
          }}>
          Add tenant
        </Button>
     <Box
      sx={{
        height: 300,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      
      <DataGrid
        rows={rows}
        columns={columns}
        
      />
    </Box>
    {showAddTenantModal && <AddTenant setShowAddTenantModal={setShowAddTenantModal} customer={customer}
      showAddTenantModal={showAddTenantModal} rows={rows} setRows={setRows}/>}
        
    
    
    </>
  );
};


function EditToolbar(props) {
    const { rows, setRows, setRowModesModel } = props;
  
    const handleClick = () => {
      const id=rows.length +1;
      setRows((oldRows) => [...oldRows, {id,  fname: '',lname:"",phone:'',email:'', address: '', isNew: true }]);
      
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      }));
    };
  
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add tenant
        </Button>
      </GridToolbarContainer>
    );
  }

export default TenantInfo;
