import React, { useState, useEffect } from 'react';
import {
    Box ,FilledInput ,InputLabel ,FormControl,Stepper,Step,StepLabel,
    Typography,Button, Container,Paper,Select,MenuItem  
} from '@mui/material';
import axios from 'axios';
import UnitInfo from './UnitInfo';
import LeaseInfo from './LeaseInfo';
import AdditionalLeaseInfo from './AdditionalLeaseInfo';
import TenantInfo from './TenantInfo';
import LeaseReview from './LeaseReview';
import { useParams,useNavigate } from 'react-router-dom';
import Header from '../Header';
import PaymentSchedule from "./PaymentSchedule";
import { updateUnitInfoByIdInDB } from '../UnitsFunctions';
import { addPaymentScheduleInDB } from '../PaymentScheduleFunctions';
import { formatDate } from '../CommonFunctions';
import PrintLease from './PrintLease';
import TaxInfo from './TaxInfo';
import { createLeaseTaxInDB } from '../LeaseTaxesFunctions';
import { createTenancyInfoInDB, updateTenancyInfoInDB } from '../TenancyFunctions';
import LeaseDocumentHtml from './LeaseDocumentHtml';
import LeaseDocument from './LeaseDocument';
// const steps = ['Select campaign settings', 'Create an ad group'];
const components=[UnitInfo,LeaseInfo,
  TaxInfo,PaymentSchedule,AdditionalLeaseInfo,TenantInfo, 
  LeaseReview,LeaseDocument] 

const LeaseWizard = () => {
    const [activeStep, setActiveStep] = useState(0);
    const navigate=useNavigate();
    const {propertyId}=useParams();
    const {tenancyId} = useParams();
    const url = process.env.REACT_APP_SERVER_URL;
    const [rows,setRows]=useState([]);
    let [scheduledPayments,setScheduledPayments] = useState([])
    const [unitJson,setUnitJson] = useState({
      unitId:null,
      name:"",
      streetAddress:"",
      city:"",
      state:"",
      zipCode:"",
      currentTenancyId:null
    });
    const [landlordJson,setLandlordJson] = React.useState({
      landlordId:null,
      propertyId:propertyId ? propertyId : null,
      name:"",
      address:"",
      phoneNumber:null,
      email:null,
      managerName:""
    })
    const [tenancyJson,setTenancyJson]= useState({
        firstArrivalDate:formatDate(new Date()),
        checkin:formatDate(new Date()),
        checkout:formatDate(new Date(),6),
        actualCheckoutDate:null,
        rentalType:"monthly",
        rate:0,
        paymentDueDate:1,
        deposit:0,
        unitId:null,
        tenancyId:null,
        description:"",
        electricity:0,
        water:0,
        garbage:0,
        sewage:0
    });
    const [taxJson,setTaxJson] = React.useState({
      countyName:"",
      salesTax:null,
      tourismTax:null,
      surfaceTax:null,
      otherTax:null,
      propertyId:null,
      exception:0
    })
    

    const SaveTenancyInfoInDb = async() =>{
        if(tenancyId)
        {
          updateTenancy(tenancyId)
        }
        else{
          createNewTenancy();
        }
        
          
    }
    const createTenancyCustomerRelation = async(customerId,tenancyId) =>{
      try{
         await axios.post(`${url}/tenancy_customer`,{
          customerId,tenancyId
          
         }).then((responseForRelation) =>{
           console.log(responseForRelation.data);
           
         })

      }catch(error){
           console.log(error);
       }
   } 
    const createCustomerInDB = async(tenancyId, customer) =>{
       try{
          const fname=customer.fname;
          const lname=customer.lname;
          const phone=customer.phone;
          const address=customer.address;
          const email=customer.email;
          await axios.post(`${url}/customers`,{
            fname,
            lname,
            phone,
            address,
            email

          }).then(async(responseForCustomers) =>{
            console.log(responseForCustomers.data);
            const customerId=responseForCustomers.data.customerId;
            await createTenancyCustomerRelation(customerId,tenancyId);
          })

       }catch(error){
            console.log(error);
        }
    };
    const updateCustomerInDB= async(tenancyId,customer) =>{
      try{
        if(customer && customer.id){
         await axios.patch(`${url}/customers/${customer.id}`,customer).then((responseForCustomers) =>{
           console.log(responseForCustomers.data);
         })

      }}catch(error){
           console.log(error);
       }
   };
    const CreateMultipleCustomers = async(tenancyId,rows) =>{
      try{
        let i=0;
        for(i=0;i<rows.length;i++)
        {
          await createCustomerInDB(tenancyId,rows[i]) .then(()=>{
            if(i==rows.length-1){
              if(propertyId){
                navigate(`/property/${propertyId}/monthlyPaymentReport`);
              }
              else{
                navigate(`/tenants`)
              }
            }
          });
            
        }
        
        
  
      }catch(error){
          console.log(error);
      }
    };
    const UpdateMultipleCustomers = async(tenancyId,rows) =>{
      let i=0;
      let booleanNavigation=false;
      for(i=0;i<rows.length;i++)
        {
          if(rows[i].id<0){
            //Means a newly added unit
            await createCustomerInDB(tenancyId,rows[i]).then(()=>{
              if(i==rows.length-1)
              booleanNavigation=true;
            });
          }
          else{
            //Already existing record in DB
            await updateCustomerInDB(tenancyId,rows[i]).then(()=>{
              if(i==rows.length-1)
              booleanNavigation=true;
            });;
          }
          
            
        }
        if(booleanNavigation===true){
          if(propertyId){
            navigate(`/property/${propertyId}/monthlyPaymentReport`);
          }
          else{
            navigate(`/tenants`)
          }
        }
        
  
    }
    const createNewTenancy = async() =>{
        try{
          if(tenancyJson.unitId){
            // FIXME: Create lease Tax first for the leaseTaxId
            const response1=await createLeaseTaxInDB(taxJson);
            console.log(response1)
            if(response1){
              let tempTenancyJson=tenancyJson;
              tempTenancyJson["leaseTaxId"]=response1;
            const response=await createTenancyInfoInDB(tempTenancyJson);
            if(response){
              console.log(response);
                const id=response.tenancyId;
                setTenancyJson({...tenancyJson,tenancyId:id});
                setUnitJson({...unitJson,currentTenancyId:id});
                CreateMultipleCustomers(id,rows);
                updateUnitInfo(id);
                addPaymentSchedule(id);

            }
                
          }}

        }catch(error){
            console.log(error);
        }
    }
    const updateUnitInfo = async(currentTenancyId) =>{
      try{
        let tempUnitJson=unitJson;
        tempUnitJson["currentTenancyId"]=currentTenancyId;
        const response= await updateUnitInfoByIdInDB(unitJson.unitId,tempUnitJson)
        if(response){
            console.log(response)
        }
      }catch(error){
        console.log(error);
    }

    }
    const updateTenancy = async(tenancyId) =>{
        try{
          if(tenancyId){
            const response= await updateTenancyInfoInDB(tenancyId,tenancyJson)
            if(response){
              console.log(response);
              UpdateMultipleCustomers(tenancyId,rows);
            }
            
          }

        }catch(error){
            console.log(error);
        }
    }
    const addPaymentSchedule = async(tenancyId)=>{
      try{
        for(let i=0;i<scheduledPayments.length;i++){
          let tempScheduleJson=scheduledPayments[i];
          tempScheduleJson["tenancyId"]=tenancyId;
          delete tempScheduleJson.id;
          const response=await addPaymentScheduleInDB(tempScheduleJson);
          if(response){
            console.log(response)
          }
        }
        

      }catch(error){
            console.log(error);
        }
    }

    
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

 const CurrentComponent=components[activeStep];
  return (
    <div>
      {/* <Container sx={{ mt: 5}}> */}
      <Box sx={{ mt: 5,display:"flex",justifyContent:"space-around"}}>
  <Paper elevation={3} sx={{ ps: 5, pe: 5,ms:5,me:5, height: "80ch", display: "flex", flexDirection: "column",width:"180ch" }}>
    <Box sx={{ width: '100%', pt: 5 }}>
      <Stepper activeStep={activeStep} color="red">
        <Step>
          <StepLabel><b>{unitJson.name ? "Unit: " + unitJson.name : "Select unit to be leased"}</b></StepLabel>
        </Step>
        <Step>
          <StepLabel><b>Lease Term</b></StepLabel>
        </Step>
        <Step>
          <StepLabel><b>Tax Information</b></StepLabel>
        </Step>
        <Step>
          <StepLabel><b>Payment Schedule</b></StepLabel>
        </Step>
        <Step>
          <StepLabel><b>Additional Information</b></StepLabel>
        </Step>
        <Step>
          <StepLabel><b>Tenant Information</b></StepLabel>
        </Step>
        <Step>
          <StepLabel><b>Review</b></StepLabel>
        </Step>
        <Step>
          <StepLabel><b>Print Lease</b></StepLabel>
        </Step>
      </Stepper>
    </Box>

    <Box sx={{ flexGrow: 1, overflowY: "auto", mt: 2, mb: 2 }}>
      <Typography sx={{ mt: 2, mb: 1 }}>
        <CurrentComponent 
          unitJson={unitJson}
          setUnitJson={setUnitJson}
          tenancyJson={tenancyJson} 
          setTenancyJson={setTenancyJson}
          rows={rows}
          setRows={setRows}
          taxJson={taxJson}
          setTaxJson={setTaxJson}
          scheduledPayments={scheduledPayments}
          setScheduledPayments={setScheduledPayments}
          landlordJson={landlordJson}
          setLandlordJson={setLandlordJson}
        />
      </Typography>
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
      <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
      >
        Back
      </Button>
      <Box sx={{ flex: '1 1 auto' }} />
      {activeStep < components.length - 1 ? 
        <Button disabled={activeStep === components.length - 1} onClick={handleNext}> 
          Next 
        </Button>
      : 
        <Button onClick={SaveTenancyInfoInDb}> 
          Finish 
        </Button>
      }
    </Box>
  </Paper>
  </Box>
{/* </Container> */}

    </div>
  );
};

export default LeaseWizard;