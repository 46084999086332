import React, { useState } from 'react';
import axios from "axios";
import UnitInfo from './UnitInfo';
// import CloseIcon from '@mui/icons-material/Close';
import {
    Button, 
    TextField,
    Box,
    InputLabel ,
    FormControl,
    Select,
    MenuItem  
} from '@mui/material';
import { useParams } from 'react-router-dom';
const  AdditionalPropertyInfo = ({numberOfUnits,setNumberOfUnits,propertyJson,setPropertyJson}) => {
    const {propertyId}=useParams();

    return (
        <>
        
            <FormControl sx={{ m: 1, width: '25ch' }} >
            <InputLabel id="demo-simple-select-autowidth-label">Type</InputLabel>
            <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            autoWidth
            label="Type"
            value={propertyJson.type}
            onChange={(e)=>setPropertyJson({...propertyJson,type:e.target.value})}
            >
            <MenuItem value="single unit">Single Dwelling Unit</MenuItem>
            <MenuItem value="apartment">Apartments</MenuItem>
            <MenuItem value="duplex">Duplex</MenuItem>
            <MenuItem value="shopping center">Shopping center</MenuItem>
            <MenuItem value="other">Other</MenuItem>
            </Select>
        </FormControl>
        {propertyId ? null :
            <TextField sx={{m:1}} type="number" label="number of units" value={numberOfUnits} onChange={(e)=>{setNumberOfUnits(e.target.value);
            }}/>
        }
        <br></br>
        
 
        </>
        
    );
  }
  export default AdditionalPropertyInfo;