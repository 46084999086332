import  React,{
    useState,
    useEffect
} from 'react';
import axios from 'axios';
import { 
    Link, useNavigate 
} from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {TablePagination,Box,Button} from '@mui/material'
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';



export default function PropertyList() {
    const [properties,setProperties]= useState([]);
    const url = process.env.REACT_APP_SERVER_URL;
    const navigate=useNavigate();
    useEffect(() => {
        getAllProperties();
    
      }, []);

      
    const getAllProperties = async() =>{
        try{
            await axios.get(`${url}/property`).then((responseForAllProperties)=>{
                console.log(responseForAllProperties.data);
                setProperties(responseForAllProperties.data);
            })

        }
        catch(error)
        {
            console.log(error)
        }
    }

  return (
    <><Header/>
    <Box sx={{m:1,marginLeft:"60%"}}>
    <Button color="secondary"  variant="outlined" startIcon={<AddIcon />} onClick={(e)=>{
      navigate(`/property`);
    }} >
          Add Property
        </Button>
    </Box>
    <Paper sx={{ width: '80%', overflow: 'hidden',marginLeft:"10%",marginTop:"2%" }}>
      <TableContainer sx={{ maxHeight: window.innerHeight }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            <TableCell >Property ID</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Address</TableCell>
            <TableCell align="left">Units</TableCell>
            <TableCell align="left">...</TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            
                  {properties.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Link to={`/property/${row.id}`} onClick={(e)=>sessionStorage.setItem('propertyName', row.name)}>
                        {row.id}
                        </Link>
                      </TableCell>
                      <TableCell align="left" >{row.name}</TableCell>
                      <TableCell align="left">{row.streetAddress}</TableCell>
                      <TableCell align="left"><Link to={`/property/${row.id}/units`}  onClick={(e)=>{
                         sessionStorage.setItem('propertyName', row.name);
                      }} >{row.noOfUnits}</Link></TableCell>
                      <TableCell align="left"><Link to={`/property/${row.id}`} onClick={(e)=>{
                         sessionStorage.setItem('propertyName', row.name);
                      }} >
                        Edit Property Info
                        </Link><br></br>
                        <Link to={`/property/${row.id}/monthlyPaymentReport`} onClick={(e)=>{
                         sessionStorage.setItem('propertyName', row.name);
                      }} >
                        Show Units
                        </Link></TableCell>
                    </TableRow>
                  ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    </>
  );
}