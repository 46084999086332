
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Snackbar,
    Alert,
    FormControlLabel,
    Switch
    } from '@mui/material';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
  } from '@mui/x-data-grid';
  import {
    randomCreatedDate,
    randomTraderName,
    randomId,
    randomArrayItem,
  } from '@mui/x-data-grid-generator';
import { 
  formatDateText,
  formatDate,
   calculateMonthsAndDays, 
   calculateWeeksAndDays,
   calculateTotalTaxPer 
  } from '../CommonFunctions';
import AddScheduledPayment from './AddScheduledPayment';
import { NumericFormat } from 'react-number-format';
const PaymentSchedule = ({tenancyJson,scheduledPayments,setScheduledPayments,taxJson}) => {
    if(tenancyJson){
        console.log(tenancyJson)
    }
    const [scheduledPaymentJson,setScheduledPaymentJson]=useState({})
    // let [scheduledPayments,setScheduledPayments] = useState([])
    const {propertyId}=useParams();
  const {tenancyId} = useParams();
  const [showAddScheduledPaymentModal,setShowAddScheduledPaymentModal]=useState(false);
  const [scheduledPayment,setScheduledPayment]=useState({})

  const [proRate,setProRate]=useState(false);
    const url = process.env.REACT_APP_SERVER_URL;
    const [rentSum,setRentSum]=useState(0);
  const columns = [
    {
        field: 'dueDate',
        headerName: 'Due Date',
        width: 170,
        valueFormatter: (params) => {
          const formattedDate = formatDateText(params.value)
          return formattedDate
        },
    },
    { field: 'rent', headerName: 'Rent', width: 120,
    renderCell:(params)=>{
      
      return <NumericFormat 
      value={params.value} thousandSeparator prefix={'$'} displayType="text"/>
    
    
     } },
    { field: 'deposit', headerName: 'Deposit', width: 120,
    renderCell:(params)=>{
      return <NumericFormat 
      value={params.value} thousandSeparator prefix={'$'} displayType="text"/>
    
    
    } },
    { field: 'totalTax', headerName: 'Tax', width: 100, align: 'left' ,
    renderCell:(params)=>{
      
        const totalTaxPer= calculateTotalTaxPer(taxJson)
        const taxAmount=(totalTaxPer * params.row.rent)/100;
        return <><NumericFormat 
        value={taxAmount} thousandSeparator prefix={'$'} displayType="text"/>({totalTaxPer}%)</>
      
      
    } },
    { field: 'totalAmount', headerName: 'Total Amount', width: 160,
    valueGetter: (params) => {
        let totalAmount= parseInt(params.row.rent);
        if(params.row.deposit)
        totalAmount +=  parseInt(params.row.deposit);
        if(params.row.tax)
          totalAmount+= parseInt(params.row.tax)
        //   const totalTaxPer= calculateTotalTaxPer(taxJson)
        // const taxAmount=(totalTaxPer * params.row.rent)/100;
        //   totalAmount+= taxAmount;
        
        return "$" + totalAmount;
    }   
    },
    
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: (rowData) => {
            return [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={(e)=>{
                    setScheduledPayment(rowData.row);
                    setShowAddScheduledPaymentModal(true);
                   }}
                  color="inherit"
                />,
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  color="inherit"
                />,
            ];
  
          
        },
    },
    {
        field:"description",
        headerName: 'Description',
        width:330

    }
  ];
  
  useEffect(() => {
    createPaymentSchedule()
    
    
  }, [tenancyId,proRate]);
  useEffect(() => {

     setRentSum( scheduledPayments.reduce((total, current) => total + parseInt(current.rent), 0));
    //  console.log(rentSum)
    
    
  }, [scheduledPayments]);
  
  
  const getNumberOfPayments = async() =>{
    try{
        // console.log("Checkin Date:" + tenancyJson.checkin + "\nCheckout Date: " +tenancyJson.checkout);
        // console.log("Rent : " + tenancyJson.rate + "\nRental Type: " + tenancyJson.rentalType);
        // const dueDay=7;
        
        let index=1;
        const dueDates=[];
        const tempArrayCheckin=tenancyJson.checkin.split('-');
        if(tempArrayCheckin.length==3){
            let yearCheckin=parseInt(tempArrayCheckin[0]);
            let monthCheckin=parseInt(tempArrayCheckin[1]);
            let dateCheckin=parseInt(tempArrayCheckin[2]);
            const tempDateCheckin=proRate==false ? dateCheckin : 1;
            const totalTaxPer= calculateTotalTaxPer(taxJson)
          if(tenancyJson.rentalType=="monthly"){

            let diffJson;
            if(proRate){
                diffJson=calculateMonthsAndDays(""+yearCheckin+"-"+monthCheckin+"-1",tenancyJson.checkout);
            }
             
            else{
                diffJson=calculateMonthsAndDays(tenancyJson.checkin,tenancyJson.checkout)
            }
            
            for(let i=1;i<=diffJson.months;i++)
            {
                if(i==1){
                    dueDates.push({id:index,
                        rent:tenancyJson.rate,
                        deposit:tenancyJson.deposit,
                        tax:(totalTaxPer * tenancyJson.rate)/100,
                        dueDate:new Date(yearCheckin,monthCheckin-1,dateCheckin),
                        description:"Pre-payment including deposit"
                    })
                }
                else{
                    let rent=tenancyJson.rate;
                    if(i==2 && proRate){
                        
                        const  daysInMonth=new Date(yearCheckin, monthCheckin, 0).getDate();
                        
                        rent=Math.round(((daysInMonth-dateCheckin+1) * rent)/daysInMonth,0);
                        
                    }
                    dueDates.push({id:index,
                        rent:rent,
                        tax:(totalTaxPer * rent)/100,
                        dueDate:new Date(yearCheckin,monthCheckin-1,tempDateCheckin)
                    })
                }
                
                console.log(new Date(yearCheckin,monthCheckin-1,dateCheckin))
                    if(monthCheckin==12){
                        monthCheckin=1;
                        yearCheckin+=1;
                    }
                    else{
                        monthCheckin +=1;
                    }
                    index++;
            }
            if(diffJson.days>0){
                const  daysInMonth=new Date(yearCheckin, monthCheckin, 0).getDate();
                let rent=Math.round(((diffJson.days) * tenancyJson.rate)/daysInMonth,0);
                dueDates.push({id:index,
                    rent:rent,
                    tax:(totalTaxPer * rent)/100,
                    dueDate:new Date(yearCheckin,monthCheckin-1,tempDateCheckin),
                    description:"For Remaining " + diffJson.days + " days"
                })
            }
            return dueDates;
           
            }
            else{
                const diffJson=calculateWeeksAndDays(tenancyJson.checkin,tenancyJson.checkout)
                for(let i=1;i<=diffJson.weeks;i++)
                {
                    const  daysInMonth=new Date(yearCheckin, monthCheckin, 0).getDate();
                    // console.log(new Date(yearCheckin,monthCheckin-1,dateCheckin))
                    if(i==1){
                        dueDates.push({id:index,
                            rent:tenancyJson.rate,
                            deposit:tenancyJson.deposit,
                            tax:(totalTaxPer * tenancyJson.rate)/100,
                            dueDate:new Date(yearCheckin,monthCheckin-1,dateCheckin),
                            description: "Pre-payment including deposit"
                        });

                    }
                    else{
                        dueDates.push({id:index,
                            rent:tenancyJson.rate,
                            tax:(totalTaxPer * tenancyJson.rate)/100,
                            dueDate:new Date(yearCheckin,monthCheckin-1,dateCheckin)});

                    }
                    if(dateCheckin + 7>daysInMonth ){
                        const diff=daysInMonth-dateCheckin;
                        if(monthCheckin==12){
                            monthCheckin=1;
                            yearCheckin+=1;
                        }
                        else{
                            monthCheckin +=1;
                        }
                        dateCheckin=diff;
            
                    }
                    else{
                        dateCheckin =dateCheckin + 7;
                    }
                    index++;
                }
                if(diffJson.days>0){
                    dueDates.push({id:index,
                        rent:tenancyJson.rate,
                        tax:(totalTaxPer * tenancyJson.rate)/100,
                        dueDate:new Date(yearCheckin,monthCheckin-1,dateCheckin),
                        description:"For Remaining " + diffJson.days + " days"
                    })
                }



                // let dayInWeek=new Date(yearCheckin, monthCheckin-1, dateCheckin).getDay();
                // while(dayInWeek!=tenancyJson.paymentDueDate){
                //     dateCheckin =(dateCheckin+1)%7;
                //     dayInWeek=new Date(yearCheckin, monthCheckin-1, dateCheckin).getDay();
                // }
                // while(yearCheckin<=yearCheckout && monthCheckin<=monthCheckout && dateCheckin<dateCheckout){
                //     const  daysInMonth=new Date(yearCheckin, monthCheckin, 0).getDate();
                //     console.log(new Date(yearCheckin,monthCheckin-1,dateCheckin))
                //     dueDates.push({id:index,rent:tenancyJson.rate,dueDate:new Date(yearCheckin,monthCheckin-1,dateCheckin)});
                //     index++;
                //     if(dateCheckin +7 <=dateCheckout){
                //         if(dateCheckin + 7>daysInMonth ){
                //             const diff=daysInMonth-dateCheckin;
                //             if(monthCheckin==12){
                //                 monthCheckin=1;
                //                 yearCheckin+=1;
                //             }
                //             else{
                //                 monthCheckin +=1;
                //             }
                //             dateCheckin=diff;
    
                //         }
                //         else{
                //             dateCheckin =dateCheckin + 7;
                //         }
                //     }
                //     else{
                //         break;
                //     }
                    //dueDates.push({id:index,rent:tenancyJson.rate,dueDate:
                    // new Date(yearCheckin,monthCheckin-1,dateCheckin)})
                        
                // }
                return dueDates;

            }
        }

                
            
       
        return [];


    }
    catch(error){
        console.log(error)
    }
  }

 const createPaymentSchedule = async() =>{
    try{
        const dueDates=await getNumberOfPayments();
        console.log(dueDates)
        setScheduledPayments(dueDates.map((item) => {
          return {
            ...item,
            dueDate: new Date(item.dueDate).toISOString().split('T')[0]
          };
        }));
        

       //Editing Code
        /*if (scheduledPaymentJson.id===-1*(scheduledPaymentJson.length+1)){
            setScheduledPayments((oldRows) => [...oldRows, scheduledPaymentJson]);
          }
          else{
            setScheduledPayments((oldRows) => {
              // Check if the row with the same id exists in the array
              const index = oldRows.findIndex(row => row.id === scheduledPaymentJson.id);
              
              // If the row exists, update it; otherwise, add the new row
              
              if (index !== -1) {
                // If row exists, update it
                const updatedRows = [...oldRows];
                updatedRows[index] = scheduledPaymentJson;
                console.log(updatedRows)
                return updatedRows;
              } else {
                // If row doesn't exist, add it
                return [...oldRows, scheduledPaymentJson];
              }
            });
  
          }*/
         

    }
    catch(error){
        console.log(error)
    }

 }





    
    
  return (
    <>
        <Box sx={{display:"flex",justifyContent:"space-between"}}>
          <Box>
        <Button color="primary" startIcon={<AddIcon />} onClick={(e)=>{
          setScheduledPayment({})
          setShowAddScheduledPaymentModal(true)
          }}>
          Schedule a Payment
        </Button></Box>
        <Box sx={{margin:"1% 10%",display:"flex",flexDirection:"row",justifyContent:"space-evenly"}}>
          <div>Total Rent: ${rentSum}</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div> Total Deposit: ${tenancyJson.deposit}</div>
        </Box>
        </Box>
{/*FIXME: Only For Monthly  */}
      {tenancyJson.rentalType == "monthly" ? 
        <FormControlLabel required control={<Switch value={proRate} onChange={(e)=>setProRate(e.target.checked)}/>} label="Prorate payment date to 1st of every month" />
      : null}
     <Box
      sx={{
        height: 320,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      
      <DataGrid
        rows={scheduledPayments}
        columns={columns}
        
      />
    </Box>
    {showAddScheduledPaymentModal && <AddScheduledPayment 
    setShowAddScheduledPaymentModal={setShowAddScheduledPaymentModal} 
    scheduledPayment={scheduledPayment}
      showAddScheduledPaymentModal={showAddScheduledPaymentModal} 
      rows={scheduledPayments} setRows={setScheduledPayments}/>} 
    
        
    
    
    </>
  );
};



export default PaymentSchedule;
