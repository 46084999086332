import { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Paper,
    TextField,
    Grid,
    Checkbox,
    List,
    ListItem,
    Button,


} from '@mui/material';

function StandardTextField({width="400px",value,props,dataJson,keyProp,setDataJson,disable=false}){
  // const [dataValue,setDataValue] = useState(value);
  const handleDataChange = (keyProp, newValue) => {
    // setDataValue(newValue);
    setDataJson( {...dataJson,[keyProp]: newValue});
    
  };
    return <TextField
    variant="standard"
    size="small"
    value={value}
    // value={data[key]}
    onChange={(e)=>handleDataChange(keyProp,e.target.value)}
    sx={{mt:-0.5}}
    {...props}
    disabled={disable}
    InputProps={{
      style: { fontSize: '0.8rem',fontWeight:'bold',width: width } // Adjust font size here
    }}/>
    
}
function CustomCheckBox({value,keyProp,dataJson,setDataJson}){
  const handleDataChange = () => {
    setDataJson( {...dataJson,[keyProp]: !value});
    
  };
  return <input type="checkbox" 
  checked={value} onChange={handleDataChange} /> 

}
function MyThreeGrid({children,dataJson,setDataJson,value1,keyProp1,value2,keyProp2}) {
  return (
    <Grid container >
      <Grid item xs={12} sm={7}>
        {children}
      </Grid>
      <Grid item xs={12} sm={2.5}>
        $ <StandardTextField width="5rem" dataJson={dataJson} setDataJson={setDataJson}
         keyProp={keyProp1} value={value1}
         />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        due $ <StandardTextField width="5rem" dataJson={dataJson} setDataJson={setDataJson}
         keyProp={keyProp2} value={value2}
         />
      </Grid>
    </Grid>
  );
}
function Signatures({col1,col2}){
    return <Box display="flex" justifyContent="space-evenly" mt={4}>
    <Box flex={1} sx={{marginRight:"10%"}}> 
    <Typography variant="body1">
    <StandardTextField width="18rem"/>
        <br />
        <Typography variant="caption" color="textSecondary">
          {col1}
        </Typography>
      </Typography>
      </Box> 
      <Box flex={1}> 
    <Typography variant="body1">
    <StandardTextField width="18rem"/>
        <br />
        <Typography variant="caption" color="textSecondary">
          {col2}
        </Typography>
      </Typography>
      </Box> 

      </Box>
}
function FourSignatures({col1,col2,col3,col4}){
  return <Box display="flex" justifyContent="space-evenly" mt={4}>
  <Box flex={1} > 
  <Typography variant="body1">
  <StandardTextField width="9rem"/>
      <br />
      <Typography variant="caption" color="textSecondary">
        {col1}
      </Typography>
    </Typography>
    </Box> 
    <Box flex={1}> 
  <Typography variant="body1">
  <StandardTextField width="9rem"/>
      <br />
      <Typography variant="caption" color="textSecondary">
        {col2}
      </Typography>
    </Typography>
    </Box> 
    <Box flex={1}> 
  <Typography variant="body1">
  <StandardTextField width="9rem"/>
      <br />
      <Typography variant="caption" color="textSecondary">
        {col1}
      </Typography>
    </Typography>
    </Box> 
    <Box flex={1}> 
  <Typography variant="body1">
  <StandardTextField width="9rem"/>
      <br />
      <Typography variant="caption" color="textSecondary">
        {col2}
      </Typography>
    </Typography>
    </Box> 

    </Box>
}
function LeaseDocumentHtml({dataJson,setDataJson}) {
    const [isPrinting,setIsPrinting] = useState(false);
    
    
    useEffect(()=>{
        setIsPrinting(false);
    })

  return (
    <>
    {/* <Button onClick={(e)=>{
        setIsPrinting(true)
            document.getElementById("mainComponent").style.marginLeft="0%";
            document.getElementById("mainComponent").style.marginRight="0%";
            
        window.print();
    }}>Printyyy</Button> */}
    <Paper id="mainComponent" 
    style={{marginLeft:isPrinting ? "0%": "15%",marginRight:isPrinting ? "0%": "15%",
    paddingLeft:"6%",paddingRight:"6%",lineHeight:"22px",fontSize: '0.8rem'}}>
     <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography variant="h6" component="h4" align="center"  sx={{fontWeight:"bold"}}>
      Residential Lease for Single Family Home or Duplex 
      </Typography>
      <Typography variant="subtitle1" align="center"  sx={{fontWeight:"bold"}}>
      (FOR A TERM NOT TO EXCEED ONE YEAR)
      </Typography>
    </Box>
    <Box>
    <Typography variant="p">
    A BOX ( <b style={{fontSize:"18px"}}>☐</b> ) OR A BLANK SPACE ( ____ ) INDICATES A PROVISION WHERE A CHOICE OR
    DECISION MUST BE MADE BY THE PARTIES.
      </Typography>
      <br></br><br></br>
      <Typography variant="p"><b>
      THE LEASE IMPOSES IMPORTANT LEGAL OBLIGATIONS. MANY RIGHTS AND 
      RESPONSIBILITIES OF THE PARTIES ARE GOVERNED BY CHAPTER 83, PART II,
      RESIDENTIAL LANDLORD AND TENANT ACT, FLORIDA STATUTES. A COPY OF THE 
      RESIDENTIAL LANDLORD AND TENANT ACT IS ATTACHED TO THIS LEASE. </b>
      </Typography>

    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
        <b>1. PARTIES.</b> This is a lease ("the Lease") between {' '}
        <StandardTextField keyProp="landlord_name" 
        dataJson={dataJson} setDataJson={setDataJson} value={dataJson.landlord_name}/>
         (name & address of owner of the property) <StandardTextField keyProp="landlord_address" 
        dataJson={dataJson} setDataJson={setDataJson} value={dataJson.landlord_address}/>       
         (“Landlord”) and 
         <StandardTextField dataJson={dataJson} setDataJson={setDataJson}
         value={dataJson.tenants_names_list}  keyProp={"tenants_names_list"}/>  
         (name(s) of person(s) to whom the property is leased) <StandardTextField/>
         (“Tenant.”) 
      </Typography><br></br>
      
      <Typography variant="p">
      Landlord’s E-mail address: <StandardTextField width="280px" keyProp="landlord_email" 
        dataJson={dataJson} setDataJson={setDataJson} value={dataJson.landlord_email}/>
        </Typography>
        <Typography variant="p">
        Landlord’s Telephone Number:<StandardTextField  width="250px" keyProp="landlord_phoneNumber" 
        dataJson={dataJson} setDataJson={setDataJson} value={dataJson.landlord_phoneNumber}/>
        </Typography>
        <Typography variant="p">
        Tenant’s E-mail address: <StandardTextField width="280px" keyProp="tenant_email" 
        dataJson={dataJson} setDataJson={setDataJson} value={dataJson.tenant_email}/>
        </Typography>
        <Typography variant="p">
        Tenant’s Telephone Number:<StandardTextField width="250px" keyProp="tenant_phone" 
        dataJson={dataJson} setDataJson={setDataJson} value={dataJson.tenant_phone}/>
        </Typography>
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
        <b>2. PROPERTY RENTED. </b> 
        Landlord leases to Tenant the land and buildings located at 
        <StandardTextField width="36rem" keyProp="property_streetAddress" 
        dataJson={dataJson} setDataJson={setDataJson} value={dataJson.property_streetAddress}/> {' '}
         (street address) , Florida <StandardTextField width="5rem" dataJson={dataJson} setDataJson={setDataJson}
         keyProp="property_zipCode" value={dataJson.property_zipCode}
         />   (zipcode). <br></br><br></br>
         
         together with the following furniture and appliances [List all furniture and appliances. 
         If none, write "none."] 
         (In the Lease, the property leased, including furniture and appliances, if any, is called "the 
         Premises"):<br></br>
         <StandardTextField width="36rem" keyProp="appliances_list" 
        dataJson={dataJson} setDataJson={setDataJson} value={dataJson.appliances_list}/>
         <StandardTextField width="36rem"/><br></br><br></br>
         The Premises shall be occupied only by the Tenant and the following persons: 
         <StandardTextField width="36rem" dataJson={dataJson} setDataJson={setDataJson}
         value={dataJson.tenants_names_list}  keyProp={"tenants_names_list"}/>
      </Typography>
      
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>3. TERM. </b> This is a lease for a term, not to exceed twelve months, beginning on &nbsp;&nbsp;
      <StandardTextField width="160px" dataJson={dataJson} setDataJson={setDataJson}
         value={dataJson.leaseStartDate}  keyProp={"leaseStartDate"}/> (month, day, year) and ending &nbsp;&nbsp;
      <StandardTextField width="160px" dataJson={dataJson} setDataJson={setDataJson}
         value={dataJson.leaseEndDate}  keyProp={"leaseEndDate"}/> (month, day,year) (the "Lease Term"). 

      </Typography>
      
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap">
    <Typography variant="body1" gutterBottom sx={{fontSize:"13px"}}>
      <b>4. RENT PAYMENTS, TAXES AND CHARGES. </b><br></br>
      Tenant shall pay total rent in the amount of $ <StandardTextField width="200px"
      dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.total_rent}  keyProp={"total_rent"}
      /> (excluding taxes) for the Lease Term. The
rent shall be payable by Tenant in advance in installments or in full as provided in the options below: 
    
        <List >
            <ListItem>
              <CustomCheckBox keyProp="checkbox_4_1" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_4_1} />  in installments. 
              If in installments, rent shall be payable </ListItem>
            <ListItem>
                <List>
                    <ListItem>
                    <Box display="flex" flexWrap="wrap" >
                    <Typography variant="body1" gutterBottom sx={{fontSize:"13px"}}>
                    <CustomCheckBox keyProp="checkbox_4_2" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_4_2} />  monthly, on the 
                        { dataJson.checkbox_4_2 &&
                        <StandardTextField width="200px" dataJson={dataJson} setDataJson={setDataJson}
                        value={dataJson.m_which_day}  
                        keyProp={"m_which_day"} /> }
                        {!dataJson.checkbox_4_2 &&
                        <StandardTextField width="200px"/>}
                        day of each month (if left blank, on the first day of each month) 
                        in the amount of $   { dataJson.checkbox_4_2 && <StandardTextField width="200px"
                        dataJson={dataJson} setDataJson={setDataJson}
                        value={dataJson.m_install_amount}  
                        keyProp={"m_install_amount"}
                        />} {!dataJson.checkbox_4_2 &&
                          <StandardTextField width="200px"/>}per installment.
                    </Typography>
                    </Box>
                    </ListItem>
                </List>
            </ListItem>
            <ListItem>
                <List>
                    <ListItem>
                    <Box display="flex" flexWrap="wrap" >
                    <Typography variant="body1" gutterBottom sx={{fontSize:"13px"}}>
                    <CustomCheckBox keyProp="checkbox_4_3" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_4_3} /> weekly, on the { dataJson.checkbox_4_3 &&
                <StandardTextField width="200px" dataJson={dataJson} setDataJson={setDataJson}
                value={dataJson.w_which_day}  
                keyProp={"w_which_day"} /> }
                {!dataJson.checkbox_4_3 &&
                <StandardTextField width="200px"/>}
                        day of each week. (If left blank, on Monday of each week.) in the amount of $ 
                        { dataJson.checkbox_4_3 && <StandardTextField width="200px"
                        dataJson={dataJson} setDataJson={setDataJson}
                        value={dataJson.w_install_amount}  
                        keyProp={"w_install_amount"}
                        />} {!dataJson.checkbox_4_3 &&
                          <StandardTextField width="200px"/>} per installment. 
                    </Typography>
                    </Box>

                    </ListItem>
                </List>
            </ListItem>
            <ListItem>
            <Box display="flex" flexWrap="wrap" >
            <Typography variant="body1" gutterBottom sx={{fontSize:"13px"}}>
            <CustomCheckBox keyProp="checkbox_4_4" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_4_4} />  in full on { dataJson.checkbox_4_4 && 
              <StandardTextField width="200px" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.f_which_day}  
              keyProp={"f_which_day"} /> }
              {!dataJson.checkbox_4_4 &&
              <StandardTextField width="200px"/>} (date) in the 
            amount of $ { dataJson.checkbox_4_4 && <StandardTextField width="200px"
                        dataJson={dataJson} setDataJson={setDataJson}
                        value={dataJson.f_install_amount}  
                        keyProp={"f_install_amount"}
                        />} {!dataJson.checkbox_4_4 &&
                          <StandardTextField width="200px"/>}. 
            </Typography>
                    </Box></ListItem>
            

        </List>
        </Typography>
      
    </Box>
    <br></br>
    
    <Box display="flex" flexWrap="wrap">
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      Tenant shall also be obligated to pay taxes on the rent when applicable in the amount of $ 
      <StandardTextField width="230px" sx={{mt:-2}} 
      dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.total_taxes }  
      keyProp={"total_taxes"}
      /> 
      <CustomCheckBox keyProp="checkbox_4_5" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_4_5} /> with each rent installment  {'\t\t'} &nbsp;
      <CustomCheckBox keyProp="checkbox_4_6" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_4_6} />  with the rent for the full term of the Lease. Landlord will notify Tenant if
the amount of the tax changes. 

      </Typography>
      
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>Payment Summary. </b><br></br>
      <b>
      <CustomCheckBox keyProp="checkbox_ps_1" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_ps_1} /> If rent is paid in installments, the total payment per installment including taxes shall
    be in the amount of $ <StandardTextField width="60px" dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_ps_1 }  
      keyProp={"input_ps_1"}/>.<br></br>
    <CustomCheckBox keyProp="checkbox_ps_2" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_ps_2} /> If rent is paid in full, the total payment including taxes shall be in the amount of
    $ <StandardTextField width="130px" dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_ps_2 }  
      keyProp={"input_ps_2"}/>. </b><br></br><br></br>
    All rent payments shall be payable to 
    <StandardTextField width="200px"  dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_ps_3 }  
      keyProp={"input_ps_3"}/>(name) at 
    <StandardTextField width="350px"  dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_ps_4 }  
      keyProp={"input_ps_4"}/>(address). <br></br><br></br>
    <CustomCheckBox keyProp="checkbox_ps_3" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_ps_3} /> (If left blank, to Landlord at Landlord’s address).
     If the tenancy starts on a day other than the first day of the month or week as designated above, the
    rent shall be prorated from <StandardTextField width="160px"  dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_ps_5 }  
      keyProp={"input_ps_5"}/> (date) through 
    <StandardTextField width="160px"  dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_ps_6 }  
      keyProp={"input_ps_6"}/> (date)in the amount of
    $<StandardTextField width="160px"  dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_ps_7 }  
      keyProp={"input_ps_7"}/> and shall be due on <StandardTextField width="200px"  dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_ps_8 }  
      keyProp={"input_ps_8"}/> (date) 
    (If rent paid monthly, prorate on a 30 day month.) <br></br><br></br>
    Tenant shall make rent payments required under the Lease by (choose all applicable) &nbsp;
    <CustomCheckBox keyProp="checkbox_ps_4" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_ps_4} /> cash, <CustomCheckBox keyProp="checkbox_ps_5" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_ps_5} /> personal check, 
    <CustomCheckBox keyProp="checkbox_ps_6" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_ps_6} /> money order, 
    <CustomCheckBox keyProp="checkbox_ps_7" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_ps_7} /> cashier’s check, or <CustomCheckBox keyProp="checkbox_ps_8" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_ps_8} /> other 
    <StandardTextField width="200px"  dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_ps_9 }  
      keyProp={"input_ps_9"}/> (specify). If
    payment is accepted by any means other than cash, payment is not considered made until the other
    instrument is collected.<br></br><br></br>
    If Tenant makes a rent payment with a worthless check, Landlord can require Tenant &nbsp;
    <CustomCheckBox keyProp="checkbox_ps_9" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_ps_9} />  to pay all future
    payments by &nbsp;<CustomCheckBox keyProp="checkbox_ps_10" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_ps_10} /> money order, cashier’s check or official bank check or &nbsp;
    <CustomCheckBox keyProp="checkbox_ps_11" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_ps_11} /> cash or other (specify), and &nbsp;
    <CustomCheckBox keyProp="checkbox_ps_12" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_ps_12} /> to pay bad check fees in the amount of $ 
    <StandardTextField width="200px"  dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_ps_10 }  
      keyProp={"input_ps_10"}/>  (not to exceed the amount prescribed by Section
    68.065, Florida Statutes.)

      </Typography>
      
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>5. MONEY DUE PRIOR TO OCCUPANCY. </b> 
      Tenant shall pay the sum of $ <StandardTextField width="110px" dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_5_1 }  
      keyProp={"input_5_1"}
    /> in accordance with this paragraph
      prior to occupying the Premises. Tenant shall not be entitled to move in or to keys to the Premises until all money
      due prior to occupancy has been paid. If no date is specifed below, then funds shall be due prior to Tenant
      occupancy. Any funds designated in this paragraph due after occupancy, shall be paid accordingly. Any funds due
      under this paragraph shall be payable to Landlord at Landlord’s address or to <br></br>
      <StandardTextField width="15rem" dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_5_2 }  
      keyProp={"input_5_2"}/> (name) at <StandardTextField width="25rem" dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_5_3 }  
      keyProp={"input_5_3"}/> (address).<br></br><br></br>
     <MyThreeGrid dataJson={dataJson} setDataJson={setDataJson}
      value1={dataJson.input_5_p1 }  value2={dataJson.input_5_d1}
      keyProp1={"input_5_p1"} keyProp2={"input_5_d1"}>
      First <CustomCheckBox keyProp="checkbox_5_1" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_5_1} /> month’s <CustomCheckBox keyProp="checkbox_5_2" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_5_2} />  week’s rent plus applicable taxes 

     </MyThreeGrid>
     <MyThreeGrid dataJson={dataJson} setDataJson={setDataJson}
      value1={dataJson.input_5_p2 }  value2={dataJson.input_5_d2}
      keyProp1={"input_5_p2"} keyProp2={"input_5_d2"}>
      Prorated rent plus applicable taxes
     </MyThreeGrid>
     <MyThreeGrid dataJson={dataJson} setDataJson={setDataJson}
      value1={dataJson.input_5_p3 }  value2={dataJson.input_5_d3}
      keyProp1={"input_5_p3"} keyProp2={"input_5_d3"}>
      Advanced rent for  <CustomCheckBox keyProp="checkbox_5_3" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_5_3} /> month <CustomCheckBox keyProp="checkbox_5_4" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_5_4} />  week of <StandardTextField width="110px" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_5_4 }  
              keyProp={"input_5_4"}
            /> plus applicable taxes 
     </MyThreeGrid>
     <MyThreeGrid dataJson={dataJson} setDataJson={setDataJson}
      value1={dataJson.input_5_p4 }  value2={dataJson.input_5_d4}
      keyProp1={"input_5_p4"} keyProp2={"input_5_d4"}>
      Last <CustomCheckBox keyProp="checkbox_5_5" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_5_5} /> month’s <CustomCheckBox keyProp="checkbox_5_6" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_5_6} />  week’s rent plus applicable taxes 
     </MyThreeGrid>
     <MyThreeGrid dataJson={dataJson} setDataJson={setDataJson}
      value1={dataJson.input_5_p5 }  value2={dataJson.input_5_d5}
      keyProp1={"input_5_p5"} keyProp2={"input_5_d5"}>
      Security deposit
     </MyThreeGrid>
     <MyThreeGrid dataJson={dataJson} setDataJson={setDataJson}
      value1={dataJson.input_5_p6 }  value2={dataJson.input_5_d6}
      keyProp1={"input_5_p6"} keyProp2={"input_5_d6"}>
      Additional Security deposit
     </MyThreeGrid>
     <MyThreeGrid dataJson={dataJson} setDataJson={setDataJson}
      value1={dataJson.input_5_p7 }  value2={dataJson.input_5_d7}
      keyProp1={"input_5_p7"} keyProp2={"input_5_d7"}>
      Security deposit for homeowner’s association
     </MyThreeGrid>
     <MyThreeGrid dataJson={dataJson} setDataJson={setDataJson}
      value1={dataJson.input_5_p8 }  value2={dataJson.input_5_d8}
      keyProp1={"input_5_p8"} keyProp2={"input_5_d8"}>
      Pet Deposit 
     </MyThreeGrid>
     <MyThreeGrid dataJson={dataJson} setDataJson={setDataJson}
      value1={dataJson.input_5_p9 }  value2={dataJson.input_5_d9}
      keyProp1={"input_5_p9"} keyProp2={"input_5_d9"}>
      Other <StandardTextField width="20rem" dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_5_5 }  
      keyProp={"input_5_5"}
    />
     </MyThreeGrid>
     <MyThreeGrid dataJson={dataJson} setDataJson={setDataJson}
      value1={dataJson.input_5_p10 }  value2={dataJson.input_5_d10}
      keyProp1={"input_5_p10"} keyProp2={"input_5_d10"}>
      Other <StandardTextField width="20rem" dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_5_6 }  
      keyProp={"input_5_6"}
    />
     </MyThreeGrid>
    
      </Typography>
      <Typography>
        
      </Typography>
      
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>6. LATE FEES. </b> 
      (Complete if applicable) In addition to rent, Tenant shall pay a late charge in the amount
    of $ <StandardTextField width="110px" dataJson={dataJson} setDataJson={setDataJson}
      value={dataJson.input_6_1 }  
      keyProp={"input_6_1"}
    /> (If left blank, 4% of the rent payment) 
    for each rent payment made <StandardTextField width="200px"
    dataJson={dataJson} setDataJson={setDataJson}
    value={dataJson.input_6_2 }  
    keyProp={"input_6_2"}
    />
    days after the day it is due (if left blank, 5 days if rent is paid monthly, 1 day if rent is paid weekly). 

      </Typography>
      
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>7. PETS AND SMOKING. </b> 
      <CustomCheckBox keyProp="checkbox_7_1" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_7_1} /> 
      Unless this box is checked or a pet deposit is paid, Tenant may not keep
    pets or animals on the Premises. If Tenant may keep pets, the pets described in this paragraph are permitted
    on the Premises.<br></br>
    <StandardTextField width="36rem" dataJson={dataJson} setDataJson={setDataJson}
    value={dataJson.input_7_1 }  
    keyProp={"input_7_1"}/> 
    (Specify number of pets, type(s), breed, maximum adult weight of pets.)<br></br><br></br>
    <CustomCheckBox keyProp="checkbox_7_2" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_7_2} />  Unless this box is checked, no smoking is permitted in the Premises. 

      </Typography>
      
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>8. NOTICES.  </b> 
      <StandardTextField width="20rem" dataJson={dataJson} setDataJson={setDataJson}
    value={dataJson.input_8_1 }  
    keyProp={"input_8_1"}/> is Landlord’s Agent. All notices must be
    sent to<br></br>
    <CustomCheckBox keyProp="checkbox_8_1" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_8_1} /> Landlord <StandardTextField width="10rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_8_2 }  
              keyProp={"input_8_2"}/> at <StandardTextField width="15rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_8_3 }  
              keyProp={"input_8_3"}/><br></br>
    <CustomCheckBox keyProp="checkbox_8_2" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_8_2} /> Landlord’s Agent <StandardTextField width="10rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_8_4 }  
              keyProp={"input_8_4"}/> at <StandardTextField width="15rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_8_5 }  
              keyProp={"input_8_5"}/>
<br></br><br></br>
    unless Landlord gives Tenant written notice of a change. All notices of such names and addresses or
    changes thereto shall be delivered to the Tenant's residence or, if specified in writing by the Tenant, to any
    other address. All notices to the Landlord or the Landlord's Agent (whichever is specified above) shall be
    given by U.S. mail or by hand delivery.<br></br><br></br>
    Any notice to Tenant shall be given by U.S. mail or delivered to Tenant at the Premises. If Tenant is absent
    from the Premises, a notice to Tenant may be given by leaving a copy of the notice at Premises. 

      </Typography>
      
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>9. UTILITIES.  </b> 
       Tenant shall pay for all utilities services during the Lease Term and connection charges
    and deposits for activating existing utility connections to the Premises except for
    <StandardTextField width="15rem"  dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_9_1 }  
              keyProp={"input_9_1"}
    />, that Landlord agrees to provide at Landlord’s expense (If blank, then
    “NONE”). 

      </Typography>
      
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>10. MAINTENANCE. </b> 
      Landlord shall be responsible for compliance with Section 83.51, Florida Statutes, and shall be
responsible for maintenance and repair of the Premises, unless otherwise stated below: (Fill in each blank space
with “Landlord" for Landlord or “Tenant" for Tenant, if left blank, Landlord will be responsible for the item): 
<br></br>

________ roofs ________ windows ________ screens ________ steps 
<br></br>
________ doors ________ floors  ________porches  ________exterior walls <br></br>
________ foundations ________ plumbing  ________ structural components <br></br>
________ heating ________ hot water ________ running water ________ locks and keys <br></br>
________ electrical ________ system cooling ________ smoke detection device
________ garbage removal/ outside receptacles <br></br>
________  extermination of rats, mice, roaches, ants and bedbugs <br></br>
________ extermination of wood-destroying organisms <br></br>
________ lawn/shrubbery  &nbsp;&nbsp; __________pool/spa/hot tub <br></br>
________ water treatment &nbsp;&nbsp; __________ filters (specify) _______________________ <br></br>
________ ceilings        {'\t\t'}_________ interior walls <br></br>
________  Other (specify) ______________________________________________________________________ <br></br>

Tenant shall notify <StandardTextField width="10rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_10_1 }  
              keyProp={"input_10_1"}/> (name) at <StandardTextField width="15rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_10_2 }  
              keyProp={"input_10_2"}/> (address) (if left blank,
Landlord at Landlord’s address) and <StandardTextField width="15rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_10_3 }  
              keyProp={"input_10_3"}/> (telephone number) of maintenance and 
repair requests. 

      </Typography>
      
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>11. ASSIGNMENT. </b> 
      Unless this box <CustomCheckBox keyProp="checkbox_11_1" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_11_1} /> is checked, Tenant may not assign the Lease or sublease all or
any part of the Premises without first obtaining the Landlord’s written approval and consent to the
assignment or sublease. 

      </Typography>
      
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>12. KEYS AND LOCKS. </b> 
      Landlord shall furnish Tenant.<br></br>
      <StandardTextField width="15rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_12_1 }  
              keyProp={"input_12_1"}/>  # of sets of keys to the dwelling <br></br>
      <StandardTextField width="15rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_12_2 }  
              keyProp={"input_12_2"}/>  # of mail box keys <br></br>
      <StandardTextField width="15rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_12_3 }  
              keyProp={"input_12_3"}/>   # of garage door openers <br></br>

        If there is a homeowner’s association, Tenant will be provided with the following to access the
        association’s common areas/facilities:<br></br>
        <StandardTextField width="10rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_12_4 }  
              keyProp={"input_12_4"}/> # of keys to <StandardTextField width="15rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_12_5 }  
              keyProp={"input_12_5"}/><br></br>
        <StandardTextField width="10rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_12_6 }  
              keyProp={"input_12_6"}/> # of remote controls to <StandardTextField width="15rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_12_7 }  
              keyProp={"input_12_7"}/><br></br>
        <StandardTextField width="10rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_12_8 }  
              keyProp={"input_12_8"}/> # of electronic cards to <StandardTextField width="15rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_12_9 }  
              keyProp={"input_12_9"}/><br></br>
        <StandardTextField width="10rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_12_10 }  
              keyProp={"input_12_10"}/> other (specify) to <StandardTextField width="15rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_12_11 }  
              keyProp={"input_12_11"}/><br></br>
        At end of Lease Term, all items specified in this paragraph shall be returned to
        <StandardTextField width="10rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_12_12 }  
              keyProp={"input_12_12"}/> (name) at <StandardTextField width="18rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_12_13 }  
              keyProp={"input_12_13"}/>
        (address) (If left blank, Landlord at Landlord’s address). 

      </Typography>
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>13. LEAD- BASED PAINT. </b> 
      <CustomCheckBox keyProp="checkbox_13_1" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkbox_13_1} /> Check and complete if the dwelling was built before January 1, 1978.Lead Warning
              Statement (when used in this article, the term Lessor refers to Landlord and the term Lessee refers to Tenant).
              <br></br><br></br>Housing built before 1978 may contain lead-based paint. Lead from paint, paint chips, and dust can pose health
              hazards if not managed properly. Lead exposure is especially harmful to young children and pregnant women.
              Before renting pre-1978 housing, Lessors must disclose the presence of known leadbased paint and/or lead-based
              paint hazards in the dwelling. Lessees must also receive a federally approved pamphlet on lead poisoning
              prevention
      <br></br><br></br><b>Lessor's Disclosure (initial) </b> <br></br>
      <StandardTextField width="5rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_13_ldi_1 }  
              keyProp={"input_13_ldi_1"}/> Presence of lead-based paint or lead-based paint hazards (check (i) or (ii) below): <br></br> 
              <Box sx={{fontSize:"13px",paddingLeft:"15%"}}>
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <StandardTextField width="5rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_13_ldi_2 }  
              keyProp={"input_13_ldi_2"}/> Known lead- based paint and/ or lead- based paint hazards are present in the housing
      (explain). <br></br>
      <StandardTextField width="32rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_13_ldi_3 }  
              keyProp={"input_13_ldi_3"}/><br></br>
      <StandardTextField width="5rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_13_ldi_4 }  
              keyProp={"input_13_ldi_4"}/> Lessor has no knowledge of lead- based paint and/ or lead- based paint hazards in the
              housing.
              
      </Typography></Box>
      <br></br><StandardTextField width="5rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_13_ldi_5 }  
              keyProp={"input_13_ldi_5"}/> Records and reports available to the Lessor (check (i) or (ii) below): <br></br>
      <Box sx={{fontSize:"13px",paddingLeft:"15%"}}>
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <StandardTextField width="5rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_13_ldi_6 }  
              keyProp={"input_13_ldi_6"}/> Lessor has provided the lessee with all available records and reports pertaining to leadbased paint and/or lead-based paint hazards in the housing (list documents below). 
      <StandardTextField width="32rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_13_ldi_7 }  
              keyProp={"input_13_ldi_7"}/><br></br>
      <StandardTextField width="5rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_13_ldi_8 }  
              keyProp={"input_13_ldi_8"}/>  Lessor has no reports or records pertaining to lead-based paint and/or lead-based paint
      hazards in the housing. 

      </Typography>

      </Box>
      <br></br><br></br><b>Lessee's Acknowledgment (initial)</b> <br></br>
      <StandardTextField width="5rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_13_lai_1 }  
              keyProp={"input_13_lai_1"}/> Lessee has received copies of all information listed above.<br></br>
      <StandardTextField width="5rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_13_lai_2 }  
              keyProp={"input_13_lai_2"}/> Lessee has received the pamphlet Protect Your Family From Lead in Your Home
      <br></br><br></br><b>Agent's Acknowledgment (initial) </b> <br></br> 
      <StandardTextField width="5rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_13_aai_1 }  
              keyProp={"input_13_aai_1"}/> Agent has informed the Lessor of the Lessor's obligations under 42 U.S.C. 4852d and is aware of
      <StandardTextField width="15rem" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.input_13_aai_2 }  
              keyProp={"input_13_aai_2"}/> his/her responsibility to ensure compliance
      <br></br><br></br><b>Certification of Accuracy </b>The following parties have reviewed the information above and certify, to the best of their
      knowledge,that the information provided by the signatory is true and accurate. 
      <FourSignatures col1="Lessor’s signature" col2="Date"/>
      <FourSignatures col1="Lessor’s signature" col2="Date"/>
      <FourSignatures col1="Agent’s signature" col2="Date"/>

      </Typography>
      
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>14. SERVICEMEMBER. </b> 
      If Tenant is a member of the United States Armed Forces on active duty or
state active duty or a member of the Florida National Guard or United States Reserve Forces, the Tenant
has rights to terminate the Lease as provided in Section 83.682, Florida Statutes, the provisions of which
can be found in the attachment to this Lease.
      
      </Typography>
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b> 15. LANDLORD’S ACCESS TO THE PREMISES. </b> 
      Landlord’s Agent may enter the Premises in the following circumstances: <br></br>
      <List>
        <ListItem>
        At any time for the protection or preservation of the Premises. 
        </ListItem>
        <ListItem>
        After reasonable notice to Tenant at reasonable times for the purpose of repairing the Premises.
        </ListItem>
        <ListItem>
        To inspect the Premises; make necessary or agreed-upon repairs, decorations, alterations,
            or improvements; supply agreed services; or exhibit the Premises to prospective or actual purchasers,
            mortgagees, tenants, workers, or contractors under any of the following circumstances: <br></br>
            
            
       </ListItem>
       <ListItem>
       <List>
            <ListItem>with Tenant’s consent;            
            </ListItem>
            <ListItem>in case of emergency;                  
            </ListItem>
            <ListItem>when Tenant unreasonably withholds consent; or                
            </ListItem>
            <ListItem>
            if Tenant is absent from the Premises for a period of at least one-half a rental
            installment period. (If the rent is current and Tenant notifies Landlord of an intended absence, then
            Landlord may enter only with Tenant’s consent or for the protection or preservation of the Premises.) 
            </ListItem>
            </List>

       </ListItem>
       
      </List>
      
      </Typography>
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>16. HOMEOWNER’S ASSOCIATION. IF TENANT MUST BE APPROVED BY A
        HOMEOWNER’S ASSOCIATION (“ASSOCIATION”), LANDLORD AND TENANT AGREE
        THAT THE LEASE IS CONTINGENT UPON RECEIVING APPROVAL FROM THE
        ASSOCIATION. ANY APPLICATION FEE REQUIRED BY AN ASSOCIATION SHALL BE
        PAID BY <CustomCheckBox keyProp="checkBox_16_1" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkBox_16_1} /> LANDLORD <CustomCheckBox keyProp="checkBox_16_2" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkBox_16_2} /> TENANT. IF SUCH APPROVAL IS NOT OBTAINED PRIOR TO
        COMMENCEMENT OF LEASE TERM, EITHER PARTY MAY TERMINATE THE LEASE BY
        WRITTEN NOTICE TO THE OTHER GIVEN AT ANY TIME PRIOR TO APPROVAL BY THE
        ASSOCIATION, AND IF THE LEASE IS TERMINATED, TENANT SHALL RECEIVE RETURN 
        </b> 
      
      </Typography>
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>OF DEPOSITS SPECIFIED IN ARTICLE 5, IF MADE</b> 
      If the Lease is not terminated, rent shall abate
        until the approval is obtained from the association. Tenant agrees to use due diligence in applying for
        association approval and to comply with the requirements for obtaining approval {'\t'}
        <CustomCheckBox keyProp="checkBox_16_3" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkBox_16_3} /> Landlord <CustomCheckBox keyProp="checkBox_16_4" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkBox_16_4} /> Tenant
        shall pay the security deposit required by the association, if applicable. 
      
      </Typography>
    </Box>
    <br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>17. USE OF THE PREMISES. </b> 
      Tenant shall use the Premises for residential purposes. Tenant shall have
        exclusive use and right of possession to the dwelling. The Premises shall be used so as to comply with all
        state, county, municipal laws and ordinances, and all covenants and restrictions affecting the Premises and
        all rules and regulations of homeowners’ associations affecting the Premises. Tenant may not paint or make
        any alterations or improvements to the Premises without first obtaining the Landlord’s written consent to
        the alteration or improvement. However, unless this box  <CustomCheckBox keyProp="checkBox_17_1" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkBox_17_1} /> is checked, 
        Tenant may hang pictures and
        install window treatments in the Premises without Landlord’s consent, provided Tenant removes all such
        items before the end of the Lease Term and repairs all damage resulting from the removal. Any
        improvements or alterations to the Premises made by the Tenant shall become Landlord’s property. Tenant
        agrees not to use, keep, or store on the Premises any dangerous, explosive, toxic material which would
        increase the probability of fire or which would increase the cost of insuring the Premises. 
      
      </Typography>
    </Box><br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>18. RISK OF LOSS/INSURANCE. </b> 
      <List>
        <ListItem>
        Landlord and Tenant shall each be responsible for loss, damage, or injury caused by its
        own negligence or willful conduct. 
        </ListItem>
        <ListItem>Tenant should carry insurance covering Tenant’s personal property and Tenant’s liability
        insurance.
        </ListItem>
      </List>
      
      </Typography>
    </Box><br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>19. PROHIBITED ACTS BY LANDLORD. </b> 
      Landlord is prohibited from taking certain actions as described in Section 83.67, Florida Statutes, 
      the provisions of which can be found in the attachment to this Lease. 
      
      </Typography>
    </Box><br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>20. CASUALTY DAMAGE.  </b> 
      If the Premises are damaged or destroyed other than by wrongful or
        negligent acts of Tenant or persons on the Premises with Tenant’s consent, so that the use of the Premises
        is substantially impaired, Tenant may terminate the Lease within 30 days after the damage or destruction
        and Tenant will immediately vacate the Premises. If Tenant vacates, Tenant is not liable for rent that would
        have been due after the date of termination. Tenant may vacate the part of the Premises rendered unusable
        by the damage or destruction, in which case Tenant’s liability for rent shall be reduced by the fair rental
        value of the part of the Premises that was damaged or destroyed. 
      
      </Typography>
    </Box><br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>21. DEFAULTS/REMEDIES. </b> 
      Should a party to the Lease fail to fulfill their responsibilities under the
    Lease or need to determine whether there has been a default of the Lease, refer to Part II, Chapter 83,
    entitled Florida Residential Landlord and Tenant Act which contains information on defaults and remedies.
    A copy of the current version of this Act is attached to the Lease. 
      
      </Typography>
    </Box><br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>22. SUBORDINATION. </b> 
      The Lease is automatically subordinate to the lien of any mortgage encumbering 
      the fee title to the Premises from time to time. 
      
      </Typography>
    </Box><br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>23. LIENS. THE INTEREST OF THE LANDLORD SHALL NOT BE SUBJECT TO LIENS FOR
        IMPROVEMENTS MADE BY THE TENANT AS PROVIDED IN SECTION 713.10, FLORIDA
        STATUTES.  </b> 
      Tenant shall notify all parties performing work on the Premises at Tenant’s request that the
        Lease does not allow any liens to attach to Landlord’s interest. 
      
      </Typography>
    </Box><br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>24. RENEWAL/EXTENSION. </b> 
      The Lease can be renewed or extended only by a written agreement
signed by both Landlord and Tenant, but the term of a renewal or extension together with the original Lease
Term may not exceed one year. A new lease is required for each year.
      
      </Typography>
    </Box><br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>25. TENANT’S TELEPHONE NUMBER. </b> 
      Tenant shall, within 5 business days of obtaining telephone
       services at the Premises, send written notice to Landlord of Tenant’s telephone numbers at the Premises. 
      
      </Typography>
    </Box><br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>26. ATTORNEYS' FEES. </b> 
      In any lawsuit brought to enforce the Lease or under applicable law, the party 
      in whose favor a judgment or decree has been rendered may recover reasonable court costs, including 
      attorneys’ fees, from the non-prevailing party. 
      
      </Typography>
    </Box><br></br>

    
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>27. MISCELLANEOUS.  </b> 
      <List>
        <ListItem>
        Time is of the essence of the performance of each party’s obligations under the Lease. 
        </ListItem>
        <ListItem>
        The Lease shall be binding upon and for the benefit of the heirs, personal representatives,
        successors, and permitted assigns of Landlord and Tenant, subject to the requirements specifically
        mentioned in the Lease. Whenever used, the singular number shall include the plural or singular and the use
        of any gender shall include all appropriate genders. 
        </ListItem>
        <ListItem>
        The agreements contained in the Lease set forth the complete understanding of the parties
        and may not be changed or terminated orally.
        </ListItem>
        <ListItem>
        No agreement to accept surrender of the Premises from Tenant will be valid unless in
        writing and signed by Landlord.             
        </ListItem>
        <ListItem>
        All questions concerning the meaning, execution, construction, effect, validity, and
        enforcement of the Lease shall be determined pursuant to the laws of Florida.             
        </ListItem>
        <ListItem>
        A facsimile copy of the Lease and any signatures hereon shall be considered for all
        purposes originals. 
            
        </ListItem>
        <ListItem>
        As required by law, Landlord makes the following disclosure: "RADON GAS." Radon is
        a naturally occurring radioactive gas that, when it has accumulated in a building in sufficient quantities,
        may present health risks to persons who are exposed to it over time. Levels of radon that exceed federal and
        state guidelines have been found in buildings in Florida. Additional information regarding radon and radon
        testing may be obtained from your county health department.             
        </ListItem>
      </List>
      
      </Typography>
    </Box><br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>28. BROKERS’ COMMISSION. </b> 
      <CustomCheckBox keyProp="checkBox_28_1" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkBox_28_1} /> Check and complete if applicable. The brokerage companies named
        below will be paid the commission set forth in this paragraph by <CustomCheckBox keyProp="checkBox_28_2" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkBox_28_2} /> Landlord 
        <CustomCheckBox keyProp="checkBox_28_3" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkBox_28_3} /> Tenant for procuring a
        tenant for this transaction.<br></br> 
        <Signatures col1="Real Estate Licensee " col2="Real Estate Licensee "/>
        <Signatures col1="Real Estate Brokerage Company" col2="Real Estate Brokerage Company"/>
        <Signatures col1="Commission" col2="Commission"/>
      
      </Typography>
    </Box><br></br>
    <Box display="flex" flexWrap="wrap" >
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <b>29. TENANT’S PERSONAL PROPERTY. </b> 
      TENANT MUST INITIAL IN THIS BOX <CustomCheckBox keyProp="checkBox_29_1" dataJson={dataJson} setDataJson={setDataJson}
              value={dataJson.checkBox_29_1} /> FOR THE 
      FOLLOWING PROVISION TO APPLY. BY SIGNING THIS RENTAL AGREEMENT, THE TENANT 
      AGREES THAT UPON SURRENDER, ABANDONMENT, OR RECOVERY OF POSSESSION OF 
      THE DWELLING UNIT DUE TO THE DEATH OF THE LAST REMAINING TENANT, AS 
      PROVIDED BY CHAPTER 83, FLORIDA STATUTES, THE LANDLORD SHALL NOT BE LIABLE 
      OR RESPONSIBLE FOR STORAGE OR DISPOSITION OF THE TENANT’S PERSONAL PROPERTY. 
      
      </Typography>
    </Box><br></br>
    </Paper>
    <Paper id="mainComponent" 
    style={{marginLeft:isPrinting ? "0%": "15%",marginRight:isPrinting ? "0%": "15%",
    paddingLeft:"6%",paddingRight:"6%",lineHeight:"22px",fontSize: '0.8rem',marginTop:"1rem",paddingTop:"1rem"}}>
    <Box display="flex" flexWrap="wrap" sx={{pageBreakBefore:"always"}}>
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      The Lease has been executed by the parties on the dates indicated below.
      <Signatures col1="Landlord's Signature" col2="Date"/>
      <Signatures col1="Landlord's Signature" col2="Date"/>
      <Signatures col1="Landlord's Signature" col2="Date"/>
      <Signatures col1="Tenant's Signature" col2="Date"/>
      <Signatures col1="Tenant's Signature" col2="Date"/>
      </Typography>
     
     
    </Box><br></br>
    </Paper>
    <Paper id="mainComponent" 
    style={{marginLeft:isPrinting ? "0%": "15%",marginRight:isPrinting ? "0%": "15%",
    paddingLeft:"6%",paddingRight:"6%",lineHeight:"22px",fontSize: '0.8rem',marginTop:"1rem",paddingTop:"1rem"}}>
    <Box display="flex" flexWrap="wrap" sx={{pageBreakBefore:"always"}}>
    <Typography variant="h6" align="center"  sx={{fontWeight:"bold",textDecoration:"underline",fontSize: '1rem'}}>
    <br></br><br></br>EARLY TERMINATION FEE/LIQUIDATED DAMAGES ADDENDUM
      </Typography>
      <Typography variant="body1" component="span" gutterBottom sx={{fontSize:"13px"}}>
      <br></br><br></br>[ ] I agree, as provided in the rental agreement, to pay $_______ (an amount that does not exceed 2 months' rent) as
liquidated damages or an early termination fee if I elect to terminate the rental agreement and the landlord waives
the right to seek additional rent beyond the month in which the landlord retakes possession. <br></br><br></br>
[ ] I do not agree to liquidated damages or an early termination fee, and I acknowledge that the landlord may seek
damages as provided by law. <br></br><br></br>
      <Signatures col1="Landlord's Signature" col2="Date"/>
      <Signatures col1="Landlord's Signature" col2="Date"/>
      <Signatures col1="Landlord's Signature" col2="Date"/>
      <Signatures col1="Tenant's Signature" col2="Date"/>
      <Signatures col1="Tenant's Signature" col2="Date"/>
      </Typography>
     
     
    </Box><br></br>
    </Paper>
    
    </>
  );
}

export default LeaseDocumentHtml;