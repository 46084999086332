import React, { useState } from 'react';
import axios from "axios";
import UnitInfo from './UnitInfo';
// import CloseIcon from '@mui/icons-material/Close';
import {
    Button, 
    TextField,
    Box,
    InputLabel ,
    FormControl,
    Select,
    MenuItem ,
    InputAdornment,
    Paper
} from '@mui/material';
import { useParams } from 'react-router-dom';
const  AddTaxes = ({taxJson,setTaxJson,setPropertyJson,propertyJson}) => {
    const {propertyId}=useParams();

    return (
        <>
        
        <TextField sx={{m:1,width:"50ch"}}  type="text" variant="filled"  label="County Name" 
        value={taxJson.countyName} onChange={(e)=>{
            setTaxJson({...taxJson,countyName: e.target.value});
            setPropertyJson({...propertyJson, county:e.target.value})
        }}/>
        <br></br>
        
        <TextField sx={{m:1}} type="float" variant="filled" label="Sales Tax" value={taxJson.salesTax} onChange={(e)=>{
             setTaxJson({...taxJson,salesTax: e.target.value});
        }} 
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />

        <TextField sx={{m:1}} type="float" variant="filled" label="Tourism Tax" value={taxJson.tourismTax}  onChange={(e)=>{
             setTaxJson({...taxJson,tourismTax: e.target.value});
        }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />

        <TextField sx={{m:1}} type="float" variant="filled" label="Surface Tax" value={taxJson.surfaceTax}  onChange={(e)=>{
             setTaxJson({...taxJson,surfaceTax: e.target.value});
        }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField sx={{m:1}} type="float" variant="filled" label="Other Taxes" value={taxJson.otherTax}  onChange={(e)=>{
             setTaxJson({...taxJson,otherTax: e.target.value});
        }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />

        <br></br>
        <br></br>
        <b style={{marginLeft:"60%"}}>Total Tax: 
            {parseInt(taxJson.salesTax || 0) + parseInt(taxJson.tourismTax || 0) 
            + parseInt(taxJson.surfaceTax || 0) + parseInt(taxJson.otherTax || 0)} %</b>

 
        </>
        
    );
  }
  export default AddTaxes;