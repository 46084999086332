
import axios from "axios";
const url = process.env.REACT_APP_SERVER_URL;
export const getPaymentHistoryByTenancyIdInDB = async(tenancyId) =>{
    try{
        const responseForTenancy = await axios.get(`${url}/paymentHistory/${tenancyId}`)
            if(responseForTenancy && responseForTenancy.data){
                return responseForTenancy.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const createPaymentDetailsInDB = async(paymentJson)=>{
    try{
        const responseForPayment = await axios.post(`${url}/payments`,paymentJson);
        if(responseForPayment && responseForPayment.data){
            return responseForPayment.data;
        }
        else{
            return "No Data Found"
        }

    }
    catch(error)
    {
        console.log(error)
    }
}
export const getPaymentDetailsByIdInDB = async(paymentId) =>{
    try{
        const responseForTenancy = await axios.get(`${url}/payments/${paymentId}`)
            if(responseForTenancy && responseForTenancy.data){
                return responseForTenancy.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}

export const getPaymentSummaryByMonthInDB = async(searchStartDate,searchEndDate) =>{
    try{
        const responseForTenancy = await axios.post(`${url}/paymentSummaryByMonth`,{
            searchStartDate,
            searchEndDate
        })
            if(responseForTenancy && responseForTenancy.data){
                return responseForTenancy.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}

export const getAllPaymentsInfoByTenancyIdAndDateRangeInDB = async(searchStartDate,searchEndDate,tenancyId) =>{
    try{
        const responseForTenancy = await axios.post(`${url}/payments/${tenancyId}/byDateRange`,{
            searchStartDate,
            searchEndDate
        })
            if(responseForTenancy && responseForTenancy.data){
                return responseForTenancy.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}