import axios from "axios";
const url = process.env.REACT_APP_SERVER_URL;
export const getCustomerInfoByTenancyIdInDB = async(tenancyId) =>{
    try{
        const responseForCustomer = await axios.get(`${url}/tenants/${tenancyId}/customers`)
            if(responseForCustomer && responseForCustomer.data){
                return responseForCustomer.data;
              }
              else{
                return "No Data Found";
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const updateCustomerInfoInDB = async(customerId,customerJson) =>{
    try{
        const responseForCustomer = await axios.patch(`${url}/customers/${customerId}`,customerJson)
            if(responseForCustomer && responseForCustomer.data){
                return responseForCustomer.data;
              }
              else{
                return "No Data Found";
            }
    }
    catch(error)
    {
        console.log(error)
    }
}