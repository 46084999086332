import axios from "axios";
const url = process.env.REACT_APP_SERVER_URL;
export const createLeaseActivityLogInDB = async(leaseActivityLog) =>{
    try{
        const response= await axios.post(`${url}/leaseActivityLog`,leaseActivityLog);
        if(response && response.data){
            return;
        }
        
        
    }
    catch(error)
    {
        console.log(error)
    }
}
