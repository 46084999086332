import React, { useState, useEffect } from 'react';
import axios from "axios";
import {
    Box ,
    FilledInput ,
    InputLabel ,
    FormControl,
    Button,
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
  } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '../CommonFunctions';
const AddScheduledPayment = ({rows,setRows,showAddScheduledPaymentModal,setShowAddScheduledPaymentModal,scheduledPayment}) => {
    // const [showAddScheduledPaymentModal,setShowAddScheduledPaymentModal]=useState(false);
    
    const [scheduledPaymentJson,setscheduledPaymentJson]=useState(()=>{
      if(scheduledPayment && Object.keys(scheduledPayment).length > 0){
        console.log(scheduledPayment)
        return {
          id:scheduledPayment.id || null,
          rent:scheduledPayment.rent || null,
          deposit:scheduledPayment.deposit || null,
          dueDate:scheduledPayment.dueDate || null,
          description: scheduledPayment.description || null

        };
      }
      else
      return {
        id:-1*rows.length +1,
        rent:null,
        deposit:null,
        dueDate:null,
        description: null
       }
        
    })
    const url=process.env.SERVER_URL;
    const navigate=useNavigate();
    const {propertyId} = useParams();
    useEffect(() => {
      
      }, []);
    const handleCloseAddScheduledPaymentPopup=()=>{
        setShowAddScheduledPaymentModal(false)
    }

    const saveScheduledPayment= ()=>{
        // setRows((oldRows) => [...oldRows, {id,  fname: '',lname:"",phone:'',email:'', address: '', isNew: true }]);
        if (scheduledPaymentJson.id===-1*(rows.length+1)){
          setRows((oldRows) => [...oldRows, scheduledPaymentJson]);
        }
        else{
          setRows((oldRows) => {
            // Check if the row with the same id exists in the array
            const index = oldRows.findIndex(row => row.id === scheduledPaymentJson.id);
            
            // If the row exists, update it; otherwise, add the new row
            
            if (index !== -1) {
              // If row exists, update it
              const updatedRows = [...oldRows];
              updatedRows[index] = scheduledPaymentJson;
              console.log(updatedRows)
              return updatedRows;
            } else {
              // If row doesn't exist, add it
              return [...oldRows, scheduledPaymentJson];
            }
          });

        }
        handleCloseAddScheduledPaymentPopup();
        
    }

  return (
    <div>
        <Dialog
        open={showAddScheduledPaymentModal}
        onClose={handleCloseAddScheduledPaymentPopup}
      >
        <DialogTitle>Tenant Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          
        
        
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        
      <div>
      
      

      <FormControl fullWidth sx={{ m: 1}} variant="filled">
          <InputLabel>Description</InputLabel>
          <FilledInput value={scheduledPaymentJson.description} onChange={(e)=>setscheduledPaymentJson({...scheduledPaymentJson,description:e.target.value})}
          />
        </FormControl><br></br>
        
        
        <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
          <InputLabel>Rent</InputLabel>
          <FilledInput value={scheduledPaymentJson.rent} onChange={(e)=>setscheduledPaymentJson({...scheduledPaymentJson,rent:e.target.value})}
          />
        </FormControl> 
        <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
          <InputLabel>Deposit</InputLabel>
          <FilledInput value={scheduledPaymentJson.deposit} onChange={(e)=>setscheduledPaymentJson({...scheduledPaymentJson,deposit:e.target.value})}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
          <InputLabel>Tax</InputLabel>
          <FilledInput value={scheduledPaymentJson.tax} onChange={(e)=>setscheduledPaymentJson({...scheduledPaymentJson,tax:e.target.value})}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
          <InputLabel></InputLabel>
          <FilledInput type="date" value={scheduledPaymentJson.dueDate} onChange={(e)=>
          setscheduledPaymentJson({...scheduledPaymentJson,dueDate:e.target.value})}
          />
        </FormControl>
        
      </div>

      
      
    </Box>
    </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddScheduledPaymentPopup}>Cancel</Button>
          <Button type="submit" onClick={(e)=>{
          saveScheduledPayment()
          }}>Save</Button>
        </DialogActions>
      </Dialog>
   
    </div>
  );
};

export default AddScheduledPayment;