import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, useRef } from "react";
import Button from 'react-bootstrap/Button';
import { printPage } from "./PdfElements";
import LeaseDocumentHtml from "./LeaseDocumentHtml";
import { parseISO } from 'date-fns';

// Register fonts with pdfmake
//rows means customer names
let options={month:"short", day: 'numeric', year:'numeric'}
const LeaseDocument=({unitJson,tenancyJson,rows,taxJson,scheduledPayments,landlordJson})=>{
  let data={
    landlord_name:landlordJson.name,
    landlord_address:landlordJson.address,
    landlord_email:landlordJson.email,
    landlord_phoneNumber:landlordJson.phoneNumber,
    tenants_names_list:rows.length>0 ? rows.map(person => `${person.fname} ${person.lname}`).join(", ") : " ",
      tenant_email:rows.length>0 ? rows[0].email :"",
      tenant_phone:rows.length>0 ? rows[0].phone :"",
      property_streetAddress:unitJson.name + ', ' + unitJson.streetAddress + ' ' + unitJson.city + ' ' +unitJson.state,
      property_zipCode:unitJson.zipCode,
      appliances_list:"",
      leaseStartDate:parseISO(tenancyJson.checkin).toLocaleString('en-US', options),
      // leaseStartDate:tenancyJson.checkin,
      leaseEndDate:parseISO(tenancyJson.checkout).toLocaleString('en-US', options),
      total_rent: scheduledPayments.length>0 ? scheduledPayments.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.rent;
      }, 0): 0,
      rentalType:tenancyJson.rentalType,
      m_which_day:tenancyJson.paymentDueDate,
      m_install_amount:tenancyJson.rate,
      w_which_day:tenancyJson.paymentDueDate,
      w_install_amount:tenancyJson.rate,
      f_which_day:tenancyJson.paymentDueDate,
      f_install_amount:scheduledPayments.length>0 ? scheduledPayments.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.rent;
      }, 0): 0,
      full_amount_due_date:"",
      total_taxes:scheduledPayments.length>0 ? scheduledPayments[0].tax: 0,
      checkbox_4_1:tenancyJson.rentalType==="weekly" || tenancyJson.rentalType==="monthly",
      checkbox_4_2:tenancyJson.rentalType==="monthly",
      checkbox_4_3:tenancyJson.rentalType==="weekly",
      checkbox_4_4:!(tenancyJson.rentalType==="weekly" || tenancyJson.rentalType==="monthly"),
      checkbox_4_5:true,
      checkbox_4_6:false,
      checkbox_ps_1: false,
      checkbox_ps_2: false,
      checkbox_ps_3: false,
      checkbox_ps_4: false,
      checkbox_ps_5: false,
      checkbox_ps_6: true,
      checkbox_ps_7: true,
      checkbox_ps_8: false,
      checkbox_ps_9: false,
      checkbox_ps_10: false,
      checkbox_ps_11: false,
      checkbox_ps_12: false,
      input_ps_1: "",
      input_ps_2: "",
      input_ps_3: landlordJson.name,
      input_ps_4: landlordJson.address,
      input_ps_5: "",
      input_ps_6: "",
      input_ps_7: "",
      input_ps_8: "",
      input_ps_9: "",
      input_ps_10: "",
      input_5_1:"",
      input_5_2:"",
      input_5_3:"",
      input_5_4:"",
      input_5_5:"",
      input_5_6:"",
      input_5_p1: scheduledPayments.length>0 ? scheduledPayments[0].rent + scheduledPayments[0].tax : "",
      input_5_p2: "",
      input_5_p3: "",
      input_5_p4: "",
      input_5_p5: tenancyJson.deposit,
      input_5_p6: "",
      input_5_p7: "",
      input_5_p8: "",
      input_5_p9: "",
      input_5_p10: "",
      input_5_d1: "",
      input_5_d2: "",
      input_5_d3: "",
      input_5_d4: "",
      input_5_d5: "",
      input_5_d6: "",
      input_5_d7: "",
      input_5_d8: "",
      input_5_d9: "",
      input_5_d10: "",
      checkbox_5_1: tenancyJson.rentalType==="monthly",
      checkbox_5_2: tenancyJson.rentalType==="weekly",
      checkbox_5_3: false,
      checkbox_5_4: false,
      checkbox_5_5: false,
      checkbox_5_6: false,
      input_6_1:"100",
      input_6_2:"3",
      checkbox_7_1:false,
      checkbox_7_2:false,
      input_7_1:"No smoking allowed",
      checkbox_8_1:true,
      checkbox_8_2:false,
      input_8_1:"",
      input_8_2: landlordJson.name,
      input_8_3: landlordJson.address,
      input_8_4: "",
      input_8_5: "",
      input_9_1:"",
      input_10_1:"",
      input_10_2:"",
      input_10_3:"",
      input_10_array: Array(30).fill(""),
      checkbox_11_1:false,
      input_12_1: "1",
      input_12_2: "",
      input_12_3: "1",
      input_12_4: "",
      input_12_5: "",
      input_12_6: "",
      input_12_7: "",
      input_12_8: "",
      input_12_9: "",
      input_12_10: "",
      input_12_11: "",
      input_12_12: "",
      input_12_13: "",
      checkbox_13:"",
      input_13_ldi_1:"",
      input_13_ldi_2:"",
      input_13_ldi_3:"",
      input_13_ldi_4:"",
      input_13_ldi_5:"",
      input_13_ldi_6:"",
      input_13_ldi_7:"",
      input_13_ldi_8:"",
      input_13_lai_1:"",
      input_13_lai_2:"",
      input_13_aai_1:"",
      input_13_aai_2:"",
      checkBox_16_1:false,
      checkBox_16_2:false,
      checkBox_16_3:false,
      checkBox_16_4:false,
      checkBox_17_1:false,
      checkBox_28_1:false,
      checkBox_28_2:false,
      checkBox_28_3:false,
      checkBox_29_1:false,
  }
  const [dataJson,setDataJson] = useState(data);
    useEffect(() => {
      console.log("asdfg")
      console.log(dataJson["tenants_names_list"])
       },[]);
    
    

return (
<>
  <Button variant="primary" onClick={()=>printPage(dataJson)}>
  Print
</Button> 
<LeaseDocumentHtml dataJson={dataJson} setDataJson={setDataJson}/>
</>




);
}
export default LeaseDocument;