import axios from "axios";
const url = process.env.REACT_APP_SERVER_URL;
export const addPaymentScheduleInDB = async(scheduleJson) => {
    try{
      console.log(scheduleJson)
        const responseForSchedule= await axios.post(`${url}/paymentSchedule`,scheduleJson);
        if(responseForSchedule && responseForSchedule.data){
          return responseForSchedule.data.PaymentScheduleId;
        }
        else{
          return "No Data Found"
        }
    }
    catch(error)
    {
        console.log(error);
    }
};

export const updatePaymentScheduleInDB = async(scheduleId,scheduleJson) =>{
  try{
    const responseForSchedule= await axios.patch(`${url}/paymentSchedule/${scheduleId}`,scheduleJson);
        if(responseForSchedule && responseForSchedule.data){
          return responseForSchedule.data;
        }
        else{
          return "No Data Found"
        }


  }
  catch(error)
  {
    console.log(error);
  }

}
export const getPaymentScheduleByTenancyIdInDB = async(tenancyId) =>{
  try{
    const responseForSchedule= await axios.get(`${url}/paymentScheduleByTenancyId/${tenancyId}`);
        if(responseForSchedule && responseForSchedule.data){
          return responseForSchedule.data;
        }
        else{
          return "No Data Found"
        }


  }
  catch(error)
  {
    console.log(error);
  }

}
export const getPaymentScheduleByIdInDB = async(scheduleId) =>{
  try{
    const responseForSchedule= await axios.get(`${url}/paymentSchedule/${scheduleId}`);
        if(responseForSchedule && responseForSchedule.data){
          return responseForSchedule.data;
        }
        else{
          return "No Data Found"
        }


  }
  catch(error)
  {
    console.log(error);
  }

}

