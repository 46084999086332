import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import { 
  formatDateText,
  formatDate 
} from '../CommonFunctions';
import LeaseWizard from "./LeaseWizard";
import {
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Box,
    IconButton,
    Input,
    Alert
    
} from "@mui/material";
import {
    GridPagination,
    DataGrid,
    GRID_CHECKBOX_SELECTION_COL_DEF,
  } from '@mui/x-data-grid'
  import CancelIcon from '@mui/icons-material/Close';
import { updateTenancyInfoInDB } from "../TenancyFunctions";


const ExtendLease = ({tenancyJson,setTenancyJson,showExtendLeaseModal,setShowExtendLeaseModal}) => {
 
    

    const url=process.env.REACT_APP_SERVER_URL;
    const {tenancyId}=useParams();
    
    
      const handleCloseExtendLeasePopup=()=>{
        setShowExtendLeaseModal(false)
      }
     
      
      
      useEffect(() => {
        
      }, [tenancyJson]);


      const checkoutTenancy= async(tenancyJson)=>{
        try{
            const response=await updateTenancyInfoInDB(tenancyJson.id,tenancyJson);
            if(response){
              console.log("Tenant Checkedout Successfully")
            }

        }catch(error){
            console.log(error)
        }
      }
      
      
    
    return (
        <>
      <Dialog
        open={showExtendLeaseModal}
        onClose={handleCloseExtendLeasePopup}
        maxWidth="lg"
      >
        <DialogTitle 
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            Extend Lease  
        <IconButton aria-label="close" onClick={handleCloseExtendLeasePopup}>
            <CancelIcon />
        </IconButton></DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
          <Alert severity="info">
            This lease is expired on <b>{formatDateText(tenancyJson.checkout)}</b>.
            </Alert>
            
        
          </DialogContentText> */}
          <LeaseWizard/>
      
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseExtendLeasePopup}>Cancel</Button>
          <Button
            type="submit"
            onClick={(e) => {
                checkoutTenancy(tenancyJson)
                handleCloseExtendLeasePopup();
                
             
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      
            
        </>
    );

}
export default ExtendLease;