import axios from "axios";
const url = process.env.REACT_APP_SERVER_URL;
export const createRepairInDB = async(repairJson) => {
    try{
        const responseForRepairs= await axios.post(`${url}/repairs`,repairJson);
        console.log(responseForRepairs)
        if(responseForRepairs && responseForRepairs.data && responseForRepairs.data.RepairId){
          return responseForRepairs.data.RepairId;
        }
        else{
          return "No Data Found"
        }
    }
    catch(error)
    {
        console.log(error);
    }
};
export const updateRepairInDB = async(repairJson) => {
    try{
        const responseForRepairs= await axios.patch(`${url}/repairs/${repairJson.id}`,repairJson);
        console.log(responseForRepairs)
        if(responseForRepairs && responseForRepairs.data){
          return responseForRepairs.data;
        }
        else{
          return "No Data Found"
        }
    }
    catch(error)
    {
        console.log(error);
    }
};
export const getAllRepairsInDB = async () => {
    try {
      const responseForRepairs=await axios.get(`${url}/repairs`)
      if(responseForRepairs && responseForRepairs.data){
        return responseForRepairs.data;
      }
    } catch (error) {
      console.log(error)
    }
  };
export const getRepairsByPropertyId = async (propertyId) =>{
  try{
    const response = await axios.post(`${url}/allRepairs`,{propertyId})
    if(response && response.data){
      return response.data
    }
    return null;

  }catch (error) {
      console.log(error)
  }
}