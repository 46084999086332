import  React,{
    useState,
    useEffect
} from 'react';
import axios from 'axios';
import { 
    Link, useParams 
} from "react-router-dom";

import {
    Paper,
    Typography,
    FilledInput,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { formatDateText,formatDate } from '../CommonFunctions';
export default function LeaseReview({unitJson,tenancyJson,rows,scheduledPayments,taxJson}) {
    console.log(scheduledPayments)
    useEffect(() => {

    
      }, []);




  return (
    <>
    {/* Unit Information */}
   
    <h5>Unit Information</h5>
    <Paper>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell> Name</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Zipcode</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow key={unitJson.name}>
                    <TableCell>{unitJson.name}</TableCell>
                    <TableCell>{unitJson.streetAddress}</TableCell>
                    <TableCell>{unitJson.city}</TableCell>
                    <TableCell>{unitJson.state}</TableCell>
                    <TableCell>{unitJson.zipCode}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    
    </Paper><br></br>

    <h5>Lease Information</h5>
    <Paper>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell> Move-in Date</TableCell>
                    <TableCell>Lease End Date</TableCell>
                    <TableCell>Rent</TableCell>
                    <TableCell>Deposit</TableCell>
                    <TableCell>Rental Type</TableCell>
                    <TableCell>Utilities Included</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>{formatDateText(tenancyJson.checkin)}</TableCell>
                    <TableCell>{formatDateText(tenancyJson.checkout)}</TableCell>
                    <TableCell>${tenancyJson.rate}</TableCell>
                    <TableCell>${tenancyJson.deposit}</TableCell>
                    <TableCell>{tenancyJson.rentalType}</TableCell>
                    <TableCell>
                    {tenancyJson.electricity==1 ? <li>Electricity</li> : null}
                    {tenancyJson.water==1 ? <li>Water</li> : null}
                    {tenancyJson.garbage==1 ? <li>Garbage</li> : null}
                    {tenancyJson.sewage==1 ? <li>Sewage</li> : null}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>

   

    </Paper><br></br>
    <h5>Tax Information</h5>
    <Paper>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Sales Tax</TableCell>
                    <TableCell>Tourism Tax</TableCell>
                    <TableCell>Surface Tax</TableCell>
                    <TableCell>Other Taxes</TableCell>
                    <TableCell>Total Tax</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>{taxJson.salesTax || 0}%</TableCell>
                    <TableCell>{taxJson.tourismTax || 0}%</TableCell>
                    <TableCell>{taxJson.surfaceTax|| 0}%</TableCell>
                    <TableCell>{taxJson.otherTax || 0}%</TableCell>
                    <TableCell>{parseInt(taxJson.salesTax || 0) + parseInt(taxJson.tourismTax || 0) 
            + parseInt(taxJson.surfaceTax || 0) + parseInt(taxJson.otherTax || 0)} %</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    
    </Paper><br></br>
    <h5>Payment Plan</h5>
    <Paper>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell> Due Date</TableCell>
                    <TableCell>Rent</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>tax</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Description</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {scheduledPayments.map((row) => (
                <TableRow>
                   
                    <TableCell>{formatDateText(row.dueDate)}</TableCell>
                    <TableCell>{row.rent}</TableCell>
                    <TableCell>{row.deposit}</TableCell>
                    <TableCell>{row.tax}</TableCell>
                    <TableCell>{parseInt(row.rent) + parseInt(row.deposit || 0) + parseInt(row.tax || 0)}</TableCell>
                    <TableCell>{row.description}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>

   

    </Paper><br></br>
    
    <h5>Tenant Information</h5>
    <Paper>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Address</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row) => (
                <TableRow key={row.name}>
                    <TableCell>{row.fname}</TableCell>
                    <TableCell>{row.lname}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.address}</TableCell>
                </TableRow>
                ))}
            </TableBody>
        </Table>
    </Paper>

    </>
  );
}