import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import { NumericFormat } from 'react-number-format';
import { formatDateText, parseResponseJSON,formatDate} from "./CommonFunctions";
import {
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Box,
    IconButton,
    Menu
    
} from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    GridPagination,
    DataGrid,
  } from '@mui/x-data-grid'
  import CancelIcon from '@mui/icons-material/Close';
import { getAnyOverLappingLeaseInDB, getPropertyInfoByTenancyIdInDB, getUnitInfoByTenancyIdInDB } from "./TenancyFunctions";
import { getPaymentScheduleByTenancyIdInDB } from "./PaymentScheduleFunctions";
import { columnGroupsStateInitializer } from "@mui/x-data-grid/internals";
import { getCustomerInfoByTenancyIdInDB } from "./CustomerFunctions";
import { getPaymentHistoryByTenancyIdInDB, getPaymentSummaryByMonthInDB } from "./PaymentFunctions";
import MakePayment from "./MakePayment";
import Header from "./Header";
import { getAllUnitsByPropertyIdInDB } from "./UnitsFunctions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import PaymentHistory from "./PaymentHistory";
import PaymentReportIndividualLease from "./PaymentReportIndividualLease";
import AddUnit from "./Property/AddUnit";
import TaxCalculation from "./Taxes/TaxCalculations";
import {calculateTotalTaxPer} from "./CommonFunctions"
import { getAllInfoForMonthlyReport } from "./AdminDashboard/DataConnections";

const MonthlyPaymentsReport = ({}) => {
  const {propertyId}=useParams();
  const [reportSummary,setReportSummary]=useState([])
  const [expectedRent,setExpectedRent] = useState(0);
    const [receivedRent,setReceivedRent] = useState(0);
    const [showTaxCalculationModal,setShowTaxCalculationModal]=useState(false);
    const [showPaymentHistoryModal,setShowPaymentHistoryModal]=useState(false);
    const [showMakePaymentModal,setShowMakePaymentModal]=useState(false);
    const [showPaymentReportModal,setShowPaymentReportModal]=useState(false);
    const [showAddUnitModal,setShowAddUnitModal] = useState(false)
    const currentDate = dayjs();
    const today=new Date();
    const [searchStartDate,setSearchStartDate]=useState(formatDate(new Date(today.getFullYear(), 
    today.getMonth(), 1)));
    const [searchEndDate,setSearchEndDate]=useState(formatDate(new Date(today.getFullYear(), 
    today.getMonth()+1, 0)));
    const [tenancyId,setTenancyId]=useState(null);
    const [tenancyJson,setTenancyJson] = useState(null)
    const [unitJson,setUnitJson] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow,setSelectedRow] = useState({})
    const navigate=useNavigate();
  useEffect(() => {
    if(propertyId){
      if(searchStartDate && searchEndDate){
      getAllInfo(propertyId,searchStartDate,searchEndDate);
      }

    }
    
    
    
  }, [propertyId,searchStartDate,searchEndDate,showMakePaymentModal]);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns=[
    // { field: 'id', headerName: 'Unit Id', width: 80, align: 'left' },
    { field: 'name', headerName: 'Unit Name', width: 160, align: 'left' },
    { field: 'checkin', headerName: 'Lease Info', width: 240, align: 'left',renderCell:(params)=>{
      if(params.value)
      {
        if(params.row.expiredLease && params.row.tenancyId){
           return <Link style={{color:"red"}} to={`/property/${propertyId}/units/${params.row.id}/lease/${params.row.tenancyId}`}>
           Lease Expired
           </Link>
        }
        else{
          return <Link  to={`/property/${propertyId}/units/${params.row.id}/lease/${params.row.tenancyId}`}>
        {params.row.checkin + ' to ' + params.row.checkout}
         </Link>
        }
      }
      
      else
      return <i style={{color:"grey"}}>No Lease this month</i>
    }
    
    },
    { field: 'rentalType', headerName: 'Payment Cycle', width: 140, align: 'left' },
    { field: 'customerNames', headerName: 'Customer', width: 200, align: 'left' },
    { field: 'phoneNumbers', headerName: 'Phone Numbers', width: 160, align: 'left' },
    { field: 'lastDueDate', headerName: 'Due Date', width: 160, align: 'left',valueFormatter: (params) => {
      const formattedDate = params.value ? formatDateText(params.value) :null
      return formattedDate 
    }, },
    { field: 'expectedRent', headerName: 'Rent', width: 100, align: 'left' ,
    renderCell:(params)=>{
      if(params.value)
      return <NumericFormat value={params.value} thousandSeparator prefix={'$'} displayType="text"/>
      
    } },
    { field: 'totalTax', headerName: 'Tax', width: 100, align: 'left' ,
    renderCell:(params)=>{
      if(params.row.leaseTaxId)
      {
        const totalTaxPer= calculateTotalTaxPer(params.row)
        const taxAmount=(totalTaxPer * params.row.expectedRent)/100;
        return <Link onClick={(e)=>{
          setTenancyJson(params.row);
          setUnitJson({id:params.row.id,name:params.row.name,streetAddress:params.row.streetAddress});
          setShowTaxCalculationModal(true)
        }}><NumericFormat 
        value={taxAmount} thousandSeparator prefix={'$'} displayType="text"/> ({totalTaxPer}%)</Link>
      }
      
      
    } },
    { field: 'totalRent', headerName: 'Total', width: 100, align: 'left' ,
    renderCell:(params)=>{
      if(params.row.expectedRent){
        let totalTaxPer=0;
        let taxAmount=0;
      if(params.row.leaseTaxId){
         totalTaxPer= calculateTotalTaxPer(params.row)
         taxAmount=(totalTaxPer * params.row.expectedRent)/100;
      }

      return <NumericFormat value={taxAmount + params.row.expectedRent} thousandSeparator prefix={'$'} displayType="text"/>
      
    }} },
    { field: 'receivedRent', headerName: 'Received Payment', width: 140, align: 'left', 
    renderCell:(params)=>{
      return <Link><NumericFormat onClick={(e)=>{
        setTenancyId(params.row.tenancyId);
        setUnitJson({id:params.row.id,name:params.row.name,streetAddress:params.row.streetAddress});
        
        setShowPaymentReportModal(true)
      }}
      value={params.value} thousandSeparator prefix={'$'} displayType="text"/></Link>
    } },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width:"140",
      renderCell:(params)=>{
        return <>
        <IconButton
        aria-label="actions"
        aria-controls="actions-menu"
        aria-haspopup="true"
        onClick={(e)=>{handleClick(e);setSelectedRow(params.row)}}
         // Rotate the icon horizontally
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => { 
          setUnitJson({id:params.row.id,name:params.row.name,streetAddress:params.row.streetAddress});
          setShowAddUnitModal(true);
          handleClose(); 
        }}>Edit Unit Info </MenuItem>
        <MenuItem onClick={() => { 
          navigate(`/property/${propertyId}/units/${selectedRow.id}/lease`);
          handleClose(); 
        }}>
          {/* <Link to={`/property/${propertyId}/units/${params.row.id}/lease`}> */}
            New Lease
          {/* </Link> */}
        </MenuItem>
        {selectedRow.tenancyId ? 
        <MenuItem onClick={() => { 
          navigate(`/property/${propertyId}/units/${selectedRow.id}/lease/${selectedRow.tenancyId}`);
          handleClose(); 
        }}>
            Edit Lease
        </MenuItem> : null}
        <MenuItem onClick={() => { 
          setTenancyId(selectedRow.tenancyId)
          setShowMakePaymentModal(true);
          handleClose(); 
        }}>Take Payment </MenuItem>
        <MenuItem onClick={() => { 
          setTenancyId(selectedRow.tenancyId);
          setUnitJson({id:selectedRow.id,name:selectedRow.name,streetAddress:selectedRow.streetAddress});
          setShowPaymentHistoryModal(true)
           handleClose();
        }}>Payment History  </MenuItem>

        {/* Add more menu items as needed */}
      </Menu>
        </>
        {/* <Link to={`/property/${propertyId}/units/${params.row.id}/lease`}>New Lease </Link><br></br>

                  <Link onClick={(e)=>{
                    setTenancyId(params.row.tenancyId)
                    setShowMakePaymentModal(true)
                    }}>Take Payment </Link>
                    <Link onClick={(e)=>{
                    setTenancyId(params.row.tenancyId);
                    setUnitJson({id:params.row.id,name:params.row.name,streetAddress:params.row.streetAddress});
                    setShowPaymentHistoryModal(true)
                    }}> Payment History 
                    </Link>
        </> */}
      }}
  ]
  const getAllInfo = async(propertyId, searchStartDate,searchEndDate)=>{
    try{
      const response= await getAllInfoForMonthlyReport(propertyId, searchStartDate,searchEndDate);
      // console.log(response)
      if(response){
        // console.log(response)
        setExpectedRent(response.expectedRent);
        setReceivedRent(response.receivedRent)
        setReportSummary(response.reportSummary)

      }
    }
    catch(error){
      console.log(error)
    }
  }

  
  function CustomPagination(props) {
    return <>
    <b>Expected Rent:
        <NumericFormat value={expectedRent} thousandSeparator prefix={'$'} displayType="text"/></b>
        <b>Received Amount:
        <NumericFormat value={receivedRent} thousandSeparator prefix={'$'} displayType="text"/></b>
        <GridPagination  {...props} /> </>;
  }
   
    
    return (
        <>
        <Header/>
        <br></br>
        <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DatePicker
        sx={{marginLeft:5}}
        views={['year', 'month']}
        openTo="month"
        defaultValue={currentDate}
        label="Select Month and Year"
        onChange={(e)=>{
          setReportSummary([])
          const selectedDate=new Date(e);
          const year = selectedDate.getFullYear();
          const month = selectedDate.getMonth();

          // Calculate the start date of the month
          const startDate = formatDate(new Date(year, month, 1)); // Set day to 1st of the month

          // Calculate the end date of the month
          const endDate = formatDate(new Date(year, month + 1, 0));
          
          setSearchStartDate(startDate);
          setSearchEndDate(endDate);
        }}
        renderInput={(params) => <TextField {...params} />} // Use TextField from MUI for input
      />
        </LocalizationProvider>
        
        <br></br><br></br>
        
          <Box
        sx={{
          height: 500,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid rows={reportSummary} columns={columns}
        getRowId={(row) => row.id+' '+row.tenancyId}
        slots={{
          pagination: CustomPagination,
        }}/>
        
      </Box>
         
      {showPaymentHistoryModal && <PaymentHistory showPaymentHistoryModal={showPaymentHistoryModal} 
    tenancyJson={{id:tenancyId}} unitJson={unitJson}
    setShowPaymentHistoryModal={setShowPaymentHistoryModal}/>}
    {showMakePaymentModal && <MakePayment showMakePaymentModal={showMakePaymentModal} 
    tenancyJson={{id:tenancyId}}
    setShowMakePaymentModal={setShowMakePaymentModal}/>}
    {showPaymentReportModal && <PaymentReportIndividualLease showPaymentReportModal={showPaymentReportModal}
    setShowPaymentReportModal={setShowPaymentReportModal} tenancyJson={{id:tenancyId}} unitJson={unitJson} 
    dueDates={{searchStartDate,searchEndDate}} />}

    {showAddUnitModal && <AddUnit showAddUnitModal={showAddUnitModal} setShowAddUnitModal={setShowAddUnitModal}
    unitRows={[]} unitJson={unitJson}      />}

    {showTaxCalculationModal && <TaxCalculation showTaxCalculationModal={showTaxCalculationModal}
    setShowTaxCalculationModal={setShowTaxCalculationModal}
    tenancyJson={tenancyJson}
    unitJson={unitJson}
    />}
        </>
    );

}
export default MonthlyPaymentsReport;