import axios from "axios";
const url = process.env.REACT_APP_SERVER_URL;
export const createAmenityInDB = async(amenityJson) => {
    try{
        const responseForAmenities= await axios.post(`${url}/amenities`,amenityJson);
        console.log(responseForAmenities)
        if(responseForAmenities && responseForAmenities.data && responseForAmenities.data.amenityId){
          return responseForAmenities.data.amenityId;
        }
        else{
          return "No Data Found"
        }
    }
    catch(error)
    {
        console.log(error);
    }
};
export const updateAmenityInDB = async(amenityJson) => {
    try{
        const responseForAmenities= await axios.patch(`${url}/amenities/${amenityJson.id}`,amenityJson);
        console.log(responseForAmenities)
        if(responseForAmenities && responseForAmenities.data){
          return responseForAmenities.data;
        }
        else{
          return "No Data Found"
        }
    }
    catch(error)
    {
        console.log(error);
    }
};
export const getAllAmenitiesInDB = async (propertyId) => {
    try {
      const responseForAmenities=await axios.post(`${url}/allAmenities`,{propertyId})
      if(responseForAmenities && responseForAmenities.data){
        return responseForAmenities.data;
      }
    } catch (error) {
      console.log(error)
    }
  }