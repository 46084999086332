import  React,{
    useState,
    useEffect
} from 'react';
import axios from 'axios';
import { 
    Link, useParams 
} from "react-router-dom";

import {
    Paper,
    Typography,
    FilledInput,
    Input,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Container,
    InputAdornment,
    Tooltip,
    Button,
    Box,
    TextField,
    Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { formatDateText,formatDate } from '../CommonFunctions';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Header from '../Header';
import { getUnitInfoByIdInDB, updateUnitInfoByIdInDB } from '../UnitsFunctions';
import { getTenancyInfoByIdInDB, updateTenancyInfoInDB } from '../TenancyFunctions';
import { getCustomerInfoByTenancyIdInDB, updateCustomerInfoInDB } from '../CustomerFunctions';
import {addPaymentScheduleInDB, getPaymentScheduleByTenancyIdInDB, updatePaymentScheduleInDB} from "../PaymentScheduleFunctions";
import AddScheduledPayment from './AddScheduledPayment';
import MakePayment from '../MakePayment';
import PaymentHistory from '../PaymentHistory';
import { createLeaseTaxInDB, getLeaseTaxByIdInDB, updateLeaseTaxInDB } from '../LeaseTaxesFunctions';
import CheckoutTenant from './CheckoutTenant';
import ExtendLease from './ExtendLease';
import { getPropertyDetailsByIdInDB } from '../PropertyFunctions';
import { getLandlordDetailsByPropertyIdInDB } from '../LandlordDetailsFunctions';
import LeaseDocumentModal from './LeaseDocumentModal';
import ThreeDayNoticeModal from './ThreeDayNoticeModal';
import _15DaysNoticeModal from './15DaysNoticeModal';
export default function Editlease() {
    const {propertyId} = useParams();
    const {tenancyId}=useParams();
    const {unitId}=useParams();
    const [unitJson,setUnitJson]=useState({})
    const [tenancyJson,setTenancyJson]=useState({})
    const [taxJson,setTaxJson] = useState({})
    const [propertyJson,setPropertyJson] = useState({});
    const [landlordJson,setLandlordJson] = useState({});
    const [customers,setCustomers]=useState([]);
    const [editSectionUnit,setEditSectionUnit] = useState(false);
    const [editSectionLease,setEditSectionLease] = useState(false);
    const [editSectionTenant,setEditSectionTenant] = useState(false);
    const [editSectionPaymentPlan,setEditSectionPaymentPlan] = useState(false);
    const [editSectionTax,setEditSectionTax]=useState(false);
    const [scheduledPayments,setScheduledPayments]=useState([]);
    const [showAddScheduledPaymentModal,setShowAddScheduledPaymentModal]=useState(false);
    const [showMakePaymentModal,setShowMakePaymentModal]=useState(false);
    const [showPaymentHistoryModal,setShowPaymentHistoryModal]=useState(false);
    const [showCheckoutTenantModal,setShowCheckoutTenantModal] = useState(false);
    const [showExtendLeaseModal,setShowExtendLeaseModal] = useState(false);
    const [showLeaseDocumentModal,setShowLeaseDocumentModal] = useState(false);
    const [showThreeDayNoticeModal, setShowThreeDayNoticeModal] = useState(false);
    const [show15DayNoticeModal,setShow15DayNoticeModal] = useState(false);
    useEffect(() => {
        getPropertyInfoById(propertyId);
        getLandlordDetailsByPropertyId(propertyId);
        getUnitInfoById(unitId);
        getTenancyInfoById(tenancyId);
        getCustomerInfoByTenancyId(tenancyId);
        getPaymentScheduleDetailsByTenancyId(tenancyId);
        
            
    
      }, [unitId,tenancyId,propertyId]);

      const getPropertyInfoById = async(propertyId) =>{
        try{
            const response= await getPropertyDetailsByIdInDB(propertyId);
            if(response){
                setPropertyJson(response[0])
            }
        }catch (error) {
        console.log(error)
      }
      }
      const getLandlordDetailsByPropertyId = async(propertyId) =>{
        try{
            const response= await getLandlordDetailsByPropertyIdInDB(propertyId);
            if(response){
                setLandlordJson(response[0])
            }
        }catch (error) {
        console.log(error)
      }
      }


    const getUnitInfoById = async (unitId) => {
        try {
          const response= await getUnitInfoByIdInDB(unitId);
          if(response){
            setUnitJson(response[0])
          }
    
        } catch (error) {
          console.log(error)
        }
    }
    const getTenancyInfoById = async (tenancyId) => {
        try {
          const response= await getTenancyInfoByIdInDB(tenancyId);
          if(response){
            setTenancyJson(response[0])
        
            if(response[0].leaseTaxId){
                getTaxInfoByTenancyId(response[0].leaseTaxId)
            }
            
          }
    
        } catch (error) {
          console.log(error)
        }
    }
    const getTaxInfoByTenancyId = async(leaseTaxId) =>{
        try{
            const response= await getLeaseTaxByIdInDB(leaseTaxId);
            
            if(response){
                setTaxJson(response[0])
            }

        }
        catch (error) {
            console.log(error)
          }
    }
    const getCustomerInfoByTenancyId = async (tenancyId) => {
        try {
          const response= await getCustomerInfoByTenancyIdInDB(tenancyId);
          if(response){
            console.log(response)
            setCustomers(response)
          }
    
        } catch (error) {
          console.log(error)
        }
    }
    const updateTaxInfo = async(taxJson)=>{
        try{
            if(taxJson.id){
                await updateLeaseTaxInDB(taxJson);
            }
            else{
                const response=await createLeaseTaxInDB(taxJson)
                if(response){
                    let tempTenancyJson=tenancyJson;
                    tempTenancyJson["leaseTaxId"]=response;
                    updateTenancyInfo(tenancyId,tempTenancyJson);
                }
            }

        }catch (error) {
          console.log(error)
        }
    }
    const updateUnitInfo = async(unitId,unitJson) =>{
        try{
            const response=await updateUnitInfoByIdInDB(unitId,unitJson);
            if(response){

            }

        }catch (error) {
          console.log(error)
        }
    }
    const updateTenancyInfo = async(tenancyId,tenancyJson) =>{
        try{
            // FIXME: if no leaseTax...then create or else update it then update tenancy
            // if(tenancyJson.leaseTaxId){
            const response=await updateTenancyInfoInDB(tenancyId,tenancyJson);
            if(response){

            }
        // }
        //     else{
                
        //     } 

        }catch (error) {
          console.log(error)
        }
    }

    const updateCustomersInfo = async(customers) =>{
        try{
            for(let i=0;i<customers.length;i++){
                const response=await updateCustomerInfoInDB(customers[i].id,customers[i]);
                if(response){

                }
            }

        }catch(error){
            console.log(error)
        }
    }
    const getPaymentScheduleDetailsByTenancyId = async(tenancyId) =>{
        try{
            const response= await getPaymentScheduleByTenancyIdInDB(tenancyId)
            if(response)
            {
                setScheduledPayments(response)
            }

        }catch(error){
            console.log(error)
        }
    }
    const updatePaymentSchedule = async(scheduledPayments) =>{
        try{
            for(let i=0;i<scheduledPayments.length;i++){
                if(scheduledPayments[i].id>0){
                const response=await updatePaymentScheduleInDB(scheduledPayments[i].id,scheduledPayments[i]);
                if(response){

                }}
                else{
                    let tempScheduledPayment=scheduledPayments[i]
                    delete tempScheduledPayment.id;
                    tempScheduledPayment["tenancyId"]=tenancyId;
                    const response=await addPaymentScheduleInDB(tempScheduledPayment)
                    if(response){

                    }
                }
            }

        }catch(error){
            console.log(error)
        }
    }
    const processRowUpdate = (newRow,key,value) => {
        setCustomers(customers.map((row) =>{
          if(row.id === newRow.id)
          {
            let updatedRow=newRow;
            updatedRow[key]=value;
            return updatedRow;
          }
          else
          return row;
        } ));
    };
    const processPaymentScheduleUpdate = (newRow,key,value) => {
        setScheduledPayments(scheduledPayments.map((row) =>{
          if(row.id === newRow.id)
          {
            let updatedRow=newRow;
            updatedRow[key]=value;
            return updatedRow;
          }
          else
          return row;
        } ));
    };



  return (
    <>
    <Header/>
    {/* Unit Information */}
    <br></br>
    <Box sx={{display:"flex",justifyContent:"space-around"}}>
    <h4 style={{color:"peru",marginLeft:"10%"}}>LEASE INFORMATION </h4>
    <Box sx={{marginLeft:"10%"}}>
    <Button variant="outlined" sx={{ color:"#432B15" ,borderColor: 'peru',
    fontWeight:"bold",
        '&:hover': {
          fontWeight:"bolder"  // Color on hover
        }}} onClick={(e)=>setShowLeaseDocumentModal(true)}>Print Lease </Button>
    <Button variant="outlined" sx={{ color:"#432B15" ,borderColor: 'peru',
    fontWeight:"bold",
        '&:hover': {
          fontWeight:"bolder"  // Color on hover
        }}} onClick={(e)=>setShowThreeDayNoticeModal(true)}>3 Day Notice </Button>
        <Button variant="outlined" sx={{ color:"#432B15" ,borderColor: 'peru',
    fontWeight:"bold",
        '&:hover': {
          fontWeight:"bolder"  // Color on hover
        }}} onClick={(e)=>setShow15DayNoticeModal(true)}>7/15 Day Notice </Button>
    <Button variant="outlined" sx={{ color:"#432B15" ,borderColor: 'peru',
    fontWeight:"bold",
        '&:hover': {
          fontWeight:"bolder"  // Color on hover
        }}} onClick={(e)=>setShowMakePaymentModal(true)}>Make Payment </Button>
    <Button variant="outlined" sx={{ color:"#432B15" ,borderColor: 'peru',
    fontWeight:"bold",
        '&:hover': {
          fontWeight:"bolder"  // Color on hover
        }}} onClick={(e)=>setShowPaymentHistoryModal(true)}>Payment History</Button>
    {/* FIXME */}
    <Button variant="outlined" sx={{ color:"#432B15" ,borderColor: 'peru',
    fontWeight:"bold",
        '&:hover': {
          fontWeight:"bolder"  // Color on hover
        }}} onClick={(e)=>setShowExtendLeaseModal(true)}>Extend Lease</Button>
    {tenancyJson && tenancyJson.actualCheckoutDate ? null 
        : <Button variant="outlined" sx={{ color:"#432B15" ,borderColor: 'peru',
            fontWeight:"bold",
                '&:hover': {
                  fontWeight:"bolder"  // Color on hover
                }}}onClick={(e)=>setShowCheckoutTenantModal(true)}>CHECKOUT</Button>
    }
    
    <Button variant="outlined"  sx={{ color:"#432B15" ,borderColor: 'peru',
    fontWeight:"bold",
        '&:hover': {
          fontWeight:"bolder"  // Color on hover
        }}} onClick={(e)=>setShowPaymentHistoryModal(true)}>Void Lease</Button>
    </Box>
    </Box>
    
    <Container sx={{mt:2}}>
        {tenancyJson && tenancyJson.actualCheckoutDate ? 
        <Alert severity="info">
            This Lease is ended and tenant moved out
        </Alert>
        : null}
        {tenancyJson && 
            tenancyJson.actualCheckoutDate==null &&
            tenancyJson.checkout<formatDate(new Date())
             ? 
        <Alert severity="warning">
            Please 
        </Alert>
        : null}
    {/* <Alert severity="warning"></Alert> */}
    <Paper elevation={3} sx={{ps:5,pe:5}} >
    <Table>
    <TableRow>
    <TableCell><h5>Unit Information</h5></TableCell>
    <TableCell align='right' >
        {editSectionUnit ? 
        <Tooltip title="Save Changes">
        <SaveIcon sx={{cursor:"pointer"}} 
            onClick={(e)=>{setEditSectionUnit(false);
                updateUnitInfo(unitJson.id,unitJson)
            }}
        /></Tooltip>
         :<EditIcon sx={{cursor:"pointer"}} onClick={(e)=>setEditSectionUnit(true)}/> 
        }
        </TableCell>
   </TableRow>
   </Table>
    
    <Paper>
        
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell> Name</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Zipcode</TableCell>
                </TableRow>
            </TableHead>
            {editSectionUnit ? 
            <TableBody>
                <TableCell>
                    <FilledInput sx={{width:"15ch"}} value={unitJson.name}
                    onChange={(e)=>setUnitJson({...unitJson,name:e.target.value})}/>
                </TableCell>
                <TableCell>
                    <FilledInput sx={{width:"45ch"}} value={unitJson.streetAddress}
                    onChange={(e)=>setUnitJson({...unitJson,streetAddress:e.target.value})}/>
                </TableCell>
                <TableCell>
                    <FilledInput sx={{width:"15ch"}} value={unitJson.city}
                    onChange={(e)=>setUnitJson({...unitJson,city:e.target.value})}/>
                </TableCell>
                <TableCell>
                    <FilledInput sx={{width:"15ch"}} value={unitJson.state}
                    onChange={(e)=>setUnitJson({...unitJson,state:e.target.value})}/>
                </TableCell>
                <TableCell>
                    <FilledInput sx={{width:"10ch"}} value={unitJson.zipCode}
                    onChange={(e)=>setUnitJson({...unitJson,zipCode:e.target.value})}/>
                </TableCell>
            </TableBody>
             : 
            <TableBody>
                <TableRow key={unitJson.name}>
                    <TableCell>{unitJson.name}</TableCell>
                    <TableCell>{unitJson.streetAddress}</TableCell>
                    <TableCell>{unitJson.city}</TableCell>
                    <TableCell>{unitJson.state}</TableCell>
                    <TableCell>{unitJson.zipCode}</TableCell>
                </TableRow>
            </TableBody>
            }
        </Table>
        
    
    </Paper><br></br>
    <Table>
    <TableRow>
    <TableCell> <h5>Lease Information</h5></TableCell>
    <TableCell align='right'  >
        {editSectionLease ? 
        <Tooltip title="Save Changes">
        <SaveIcon sx={{cursor:"pointer"}} 
            onClick={(e)=>{setEditSectionLease(false)
                updateTenancyInfo(tenancyJson.id,tenancyJson);
            }}
            
        /></Tooltip>
         :<EditIcon sx={{cursor:"pointer"}} onClick={(e)=>setEditSectionLease(true)}/> 
        }
    </TableCell>
   </TableRow>
   </Table>
   
    <Paper>
       
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell> Move-in Date</TableCell>
                    <TableCell>Lease End Date</TableCell>
                    <TableCell>Rent</TableCell>
                    <TableCell>Deposit</TableCell>
                    <TableCell>Rental Type</TableCell>
                    <TableCell>Utilities Included</TableCell>
                </TableRow>
            </TableHead>
            {editSectionLease ? 
            <TableBody>
                <TableCell>
                    <Input sx={{width:"20ch"}} value={tenancyJson.checkin} type="date"
                    onChange={(e)=>setTenancyJson({...tenancyJson,checkin: e.target.value})}/>
                </TableCell>
                <TableCell>
                    <Input sx={{width:"20ch"}} value={tenancyJson.checkout} type="date"
                    onChange={(e)=>setTenancyJson({...tenancyJson,checkout: e.target.value})}/>
                </TableCell>
                <TableCell>
                    <Input sx={{width:"10ch"}} value={tenancyJson.rate} 
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    type="number"
                    onChange={(e)=>setTenancyJson({...tenancyJson,rate: parseInt(e.target.value)})}/>
                </TableCell>
                <TableCell>
                    <Input sx={{width:"10ch"}} value={tenancyJson.deposit} 
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    type="number"
                    onChange={(e)=>setTenancyJson({...tenancyJson,deposit: parseInt(e.target.value)})}/>
                </TableCell>
                <TableCell>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    autoWidth
                    label="Payment Schedule Type"
                    value={tenancyJson.rentalType}
                    onChange={(e)=>setTenancyJson({...tenancyJson,rentalType: e.target.value})}
                >
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
                </TableCell>
                <TableCell></TableCell>
            </TableBody>
             : 
            <TableBody>
                <TableRow>
                    <TableCell>{formatDateText(tenancyJson.checkin)}</TableCell>
                    <TableCell>{formatDateText(tenancyJson.checkout)}</TableCell>
                    <TableCell>${tenancyJson.rate}</TableCell>
                    <TableCell>${tenancyJson.deposit}</TableCell>
                    <TableCell>{tenancyJson.rentalType}</TableCell>
                    <TableCell>
                    {tenancyJson.electricity==1 ? <li>Electricity</li> : null}
                    {tenancyJson.water==1 ? <li>Water</li> : null}
                    {tenancyJson.garbage==1 ? <li>Garbage</li> : null}
                    {tenancyJson.sewage==1 ? <li>Sewage</li> : null}
                    </TableCell>
                </TableRow>
            </TableBody>
            }
        </Table>
        

   

    </Paper><br></br>
    <Table>
    <TableRow>
    <TableCell><h5>Tax Information</h5></TableCell>
    <TableCell align='right'>
        {editSectionTax ? 
        <Tooltip title="Save Changes">
            <SaveIcon sx={{cursor:"pointer"}} 
            onClick={(e)=>{setEditSectionTax(false);
                    updateTaxInfo(taxJson);
                

            }}/>
        </Tooltip>
         :<EditIcon sx={{cursor:"pointer"}} onClick={(e)=>setEditSectionTax(true)}/> 
        }
    </TableCell>
   </TableRow>
   </Table>
   <Paper>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Sales Tax</TableCell>
                    <TableCell>Tourism Tax</TableCell>
                    <TableCell>Surface Tax</TableCell>
                    <TableCell>Other Taxes</TableCell>
                    <TableCell>Total Tax</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {editSectionTax ?
                <TableRow>
                <TableCell><TextField sx={{m:1}} type="float" variant="filled" label="Sales Tax" value={taxJson.salesTax} onChange={(e)=>{
             setTaxJson({...taxJson,salesTax: e.target.value});
        }} 
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        /></TableCell>
                <TableCell><TextField sx={{m:1}} type="float" variant="filled" label="Tourism Tax" value={taxJson.tourismTax}  onChange={(e)=>{
             setTaxJson({...taxJson,tourismTax: e.target.value});
        }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        /></TableCell>
                <TableCell><TextField sx={{m:1}} type="float" variant="filled" label="Surface Tax" value={taxJson.surfaceTax}  onChange={(e)=>{
             setTaxJson({...taxJson,surfaceTax: e.target.value});
        }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        /></TableCell>
                <TableCell><TextField sx={{m:1}} type="float" variant="filled" label="Other Taxes" value={taxJson.otherTax}  onChange={(e)=>{
             setTaxJson({...taxJson,otherTax: e.target.value});
        }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        /></TableCell>
                <TableCell>{parseInt(taxJson.salesTax || 0) + parseInt(taxJson.tourismTax || 0) 
                    + parseInt(taxJson.surfaceTax || 0) + parseInt(taxJson.otherTax || 0)} %</TableCell>
            </TableRow>
                 :
                <TableRow>
                    <TableCell>{taxJson.salesTax || 0}%</TableCell>
                    <TableCell>{taxJson.tourismTax || 0}%</TableCell>
                    <TableCell>{taxJson.surfaceTax|| 0}%</TableCell>
                    <TableCell>{taxJson.otherTax || 0}%</TableCell>
                    <TableCell>{parseInt(taxJson.salesTax || 0) + parseInt(taxJson.tourismTax || 0) 
                        + parseInt(taxJson.surfaceTax || 0) + parseInt(taxJson.otherTax || 0)} %</TableCell>
                </TableRow>
                }
            </TableBody>
        </Table>
    
    </Paper><br></br>
    
    <Table>
    <TableRow>
    <TableCell><h5>Tenant Information</h5></TableCell>
    <TableCell align='right'>
        {editSectionTenant ? 
        <Tooltip title="Save Changes">
            <SaveIcon sx={{cursor:"pointer"}} 
            onClick={(e)=>{setEditSectionTenant(false);
                updateCustomersInfo(customers);

            }}/>
        </Tooltip>
         :<EditIcon sx={{cursor:"pointer"}} onClick={(e)=>setEditSectionTenant(true)}/> 
        }
    </TableCell>
   </TableRow>
   </Table>
    
    <Paper>
        
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Address</TableCell>
                </TableRow>
            </TableHead>
            {editSectionTenant ? 
            <TableBody>
                { customers.map((row) => (
                <TableRow key={row.name}>
                    <TableCell>
                        <FilledInput sx={{width:"20ch"}} value={row.fname} onChange={(e)=>
                            processRowUpdate(row,"fname",e.target.value)}/>
                    </TableCell>
                    <TableCell>
                        <FilledInput sx={{width:"15ch"}} value={row.lname} onChange={(e)=>
                            processRowUpdate(row,"lname",e.target.value)}/>
                    </TableCell>
                    <TableCell>
                        <FilledInput sx={{width:"15ch"}} value={row.phone} onChange={(e)=>
                            processRowUpdate(row,"phone",e.target.value)}/>
                    </TableCell>
                    <TableCell>
                        <FilledInput sx={{width:"20ch"}} value={row.email} onChange={(e)=>
                            processRowUpdate(row,"email",e.target.value)}/></TableCell>
                    <TableCell>
                        <FilledInput sx={{width:"40ch"}} value={row.address} onChange={(e)=>
                            processRowUpdate(row,"address",e.target.value)}/>
                        </TableCell>
                </TableRow>
                ))}
            </TableBody>
             : 
            <TableBody>
            { customers.map((row) => (
                <TableRow key={row.name}>
                    <TableCell>{row.fname}</TableCell>
                    <TableCell>{row.lname}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.address}</TableCell>
                </TableRow>
                ))}
            </TableBody>
            }
        </Table>
        
    </Paper><br></br>
    <Table>
    <TableRow>
    <TableCell><h5>Payment Plan</h5></TableCell>
    <TableCell align='right'>
        {editSectionPaymentPlan ? 
        <>
        <AddIcon  sx={{cursor:"pointer",marginRight:"15%"}} onClick={(e)=>{
            setShowAddScheduledPaymentModal(true)
            setEditSectionPaymentPlan(true)
         }}/>
        <Tooltip title="Save Changes">
           
            <SaveIcon sx={{cursor:"pointer",color:"blue"}} 
            onClick={(e)=>{setEditSectionPaymentPlan(false);
            updatePaymentSchedule(scheduledPayments)}}/>
        </Tooltip>

        </>
         :<>
         
         <EditIcon sx={{cursor:"pointer"}} onClick={(e)=>setEditSectionPaymentPlan(true)}/>
         </> 
        }
    </TableCell>
   </TableRow>
   </Table>
    
    <Paper>
        
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell> Due Date</TableCell>
                    <TableCell>Rent</TableCell>
                    <TableCell>Deposit</TableCell>
                    <TableCell>tax</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Description</TableCell>
                </TableRow>
            </TableHead>
            {editSectionPaymentPlan ? 
            <TableBody>
                {scheduledPayments.map((row) => (
                <TableRow key={row.id}>
                    <TableCell>
                        <Input type="date" value={row.dueDate} onChange={(e)=>
                        processPaymentScheduleUpdate(row,"dueDate",e.target.value)}/>
                    </TableCell>
                    <TableCell>
                        <Input sx={{width:"15ch"}} type="number" value={row.rent} onChange={(e)=>
                        processPaymentScheduleUpdate(row,"rent",parseInt(e.target.value))}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}/>
                    </TableCell>
                    <TableCell>
                        <Input sx={{width:"10ch"}} type="number" value={row.deposit} onChange={(e)=>
                        processPaymentScheduleUpdate(row,"deposit",parseInt(e.target.value))}                        
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}/>
                    </TableCell>
                    <TableCell>
                        <Input sx={{width:"10ch"}} type="number" value={row.tax} onChange={(e)=>
                        processPaymentScheduleUpdate(row,"tax",parseInt(e.target.value))}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}/>
                    </TableCell>
                    <TableCell>
                        <Input sx={{width:"15ch"}} type="number" value={row.rent + (row.deposit || 0) + (row.tax || 0)}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}/>
                    </TableCell>
                    <TableCell>
                        <Input value={row.description} onChange={(e)=>
                        processPaymentScheduleUpdate(row,"description",e.target.value)}/>
                    </TableCell>
                </TableRow>
            ))}

            </TableBody>
             : 
            <TableBody>
            {scheduledPayments.map((row) => (
                <TableRow>
                   
                    <TableCell>{formatDateText(row.dueDate)}</TableCell>
                    <TableCell>${row.rent}</TableCell>
                    <TableCell>${row.deposit}</TableCell>
                    <TableCell>${row.tax || 0}</TableCell>
                    <TableCell>${parseInt(row.rent||0) + parseInt(row.deposit || 0) + parseInt(row.tax || 0)}</TableCell>
                    <TableCell>{row.description}</TableCell>
                </TableRow>
            ))}
            </TableBody>
            }
        </Table>

   

    </Paper><br></br>
    
    
</Paper>
</Container>
    {showAddScheduledPaymentModal && 
    <AddScheduledPayment 
        showAddScheduledPaymentModal={showAddScheduledPaymentModal}
        setShowAddScheduledPaymentModal={setShowAddScheduledPaymentModal} 
        rows={scheduledPayments} setRows={setScheduledPayments}
    />}

    { showMakePaymentModal && 
    <MakePayment showMakePaymentModal={showMakePaymentModal} 
        tenancyJson={tenancyJson}
        setShowMakePaymentModal={setShowMakePaymentModal}
    />}

    {showPaymentHistoryModal && 
    <PaymentHistory showPaymentHistoryModal={showPaymentHistoryModal}
        setShowPaymentHistoryModal={setShowPaymentHistoryModal} 
        tenancyJson={tenancyJson} unitJson={unitJson}
    />}

    {showCheckoutTenantModal && 
    <CheckoutTenant showCheckoutTenantModal={showCheckoutTenantModal}
        setShowCheckoutTenantModal={setShowCheckoutTenantModal} 
        tenancyJson={tenancyJson} setTenancyJson={setTenancyJson}
    />}

    {showExtendLeaseModal && 
    <ExtendLease showExtendLeaseModal={showExtendLeaseModal}
        setShowExtendLeaseModal={setShowExtendLeaseModal}
        tenancyJson={tenancyJson}
        setTenancyJson={setTenancyJson}
    />}
    {showLeaseDocumentModal &&
        <LeaseDocumentModal showLeaseDocumentModal={showLeaseDocumentModal}
        setShowLeaseDocumentModal={setShowLeaseDocumentModal}
        tenancy={tenancyJson} unit={unitJson} landlord={landlordJson}
        property={propertyJson} scheduledPayments={scheduledPayments} rows={customers}

        />
    }
    {showThreeDayNoticeModal &&
        <ThreeDayNoticeModal showThreeDayNoticeModal={showThreeDayNoticeModal}
        setShowThreeDayNoticeModal={setShowThreeDayNoticeModal}
        tenancy={tenancyJson} unit={unitJson} landlord={landlordJson}
        property={propertyJson} scheduledPayments={scheduledPayments} rows={customers}
        />

    }
    
    {show15DayNoticeModal &&
        <_15DaysNoticeModal show15DayNoticeModal={show15DayNoticeModal}
        setShow15DayNoticeModal={setShow15DayNoticeModal}
        tenancy={tenancyJson} unit={unitJson} landlord={landlordJson}
        property={propertyJson} scheduledPayments={scheduledPayments} rows={customers}
        />

    }




    </>
  );
}