import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  Box,
  FilledInput,
  InputLabel,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useNavigate, useParams } from 'react-router-dom'
import {getAllUnitsByPropertyIdInDB} from "../UnitsFunctions";
import {getAllPropertiesInDB} from "../PropertyFunctions";
import {createAmenityInDB, updateAmenityInDB} from "../AmenityFunctions"
const AddAmenity = ({   
  showAddAmenityModal,
  setShowAddAmenityModal,
  amenity,
}) => {
  const [amenityJson, setAmenityJson] = useState(() => {
    if (amenity && Object.keys(amenity).length > 0) {
      return {
        id: amenity.id || null,
        unitId: amenity.unitId || null,
        description: amenity.description || '',
        type: amenity.type || '',
        addedDate: amenity.addedDate || null,
        propertyName: amenity.propertyName || "",
        unitName: amenity.unitName || "",
      }
    } else
      return {
        id: null,
        unitId: null,
        description: '',
        type: '',
        addedDate: new Date(),
        propertyName:"",
        unitName:""
      }
  })
  const [properties,setProperties]=useState([]);
  const [units,setUnits] = useState([])
  const url = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate()
  const { propertyId } = useParams()
  useEffect(() => {
   
        getAllProperties();

  }, [])
  const handleCloseAddAmenityPopup = () => {
    setShowAddAmenityModal(false)
  }
  const getAllUnitsByPropertyId = async(propertyId) =>{
    try{
        const response=await getAllUnitsByPropertyIdInDB(propertyId);
        if(response){
            setUnits(response);
        }

    }
    catch(error)
    {
        console.log(error)
    }
  }
  const getAllProperties = async(propertyId) =>{
    try{
        const response=await getAllPropertiesInDB(propertyId);
        if(response){
            setProperties(response);
        }
    }
    catch(error)
    {
        console.log(error);
    }
  }
  const createNewAmenity = async() => {
    try{
        const response=await createAmenityInDB(amenityJson);
        if(response){
            console.log(response);
        }

    }
    catch(error){
        console.log(error);
    }
  }
  const updateAmenity = async () =>{
    try{
        const response=await updateAmenityInDB(amenityJson);
        if(response){
            console.log(response);
            
        }
        

    }
    catch(error){
        console.log(error);
    }
    
  }



  return (
    <div>
      <Dialog open={showAddAmenityModal} onClose={handleCloseAddAmenityPopup}>
        <DialogTitle>Amenity Details</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            
                {amenityJson.id ? 
                    <FormControl sx={{ m: 1, width: '34ch' }} variant="filled">
                    <InputLabel>Property Name :</InputLabel>
                    <FilledInput
                    readOnly
                    value={amenityJson.propertyName}
                    
                    />
                    </FormControl>
                 :
                    <FormControl sx={{ m: 1, width: '34ch' }} variant="filled">
                        <InputLabel id="room-select-label">
                        Select a Property :
                        </InputLabel>
                        <Select
                        labelId="room-select-label"
                        id="room-select"
                        
                        label="Select a Property :"
                        onChange={(e) =>
                            getAllUnitsByPropertyId(e.target.value)
                        }
                        >
                        {properties.map((row, index) => (
                            <MenuItem value={row.id}>{row.name}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                }
                 
                {amenityJson.id ? 
                    <FormControl sx={{ m: 1, width: '34ch' }} variant="filled">
                    <InputLabel>Unit Name :</InputLabel>
                    <FilledInput
                    readOnly
                    value={amenityJson.unitName}
                    
                    />
                    </FormControl>
                 :
                    <FormControl sx={{ m: 1, width: '34ch' }} variant="filled">
                        <InputLabel id="room-select-label">
                        Select a Unit :
                        </InputLabel>
                        <Select
                        labelId="room-select-label"
                        id="room-select"
                        label="Select a Unit :"
                        onChange={(e) =>
                            setAmenityJson({...amenityJson,unitId:e.target.value})
                        }
                        >
                        {units.map((row, index) => (
                            <MenuItem value={row.id}>{row.name}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                }

              <FormControl sx={{ m: 1, width: '34ch' }} variant="filled">
                <InputLabel>Amenity Type :</InputLabel>
                <FilledInput
                
                  value={amenityJson.type}
                  onChange={(e) =>
                    setAmenityJson({
                      ...amenityJson,
                      type: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: '34ch' }} variant="filled">
                <InputLabel>Added Date :</InputLabel>
                <FilledInput type="date"
                    value={amenityJson.addedDate}
                    onChange={(e) =>
                    setAmenityJson({
                        ...amenityJson,
                        addedDate: e.target.value,
                    })
                    }
                />
                </FormControl>
              
              <br></br>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddAmenityPopup}>Cancel</Button>
          <Button
            type="submit"
            onClick={(e) => {
                if(amenityJson.id){
                    updateAmenity();
                }
                else{
                    createNewAmenity();
                }
                handleCloseAddAmenityPopup();
                
             
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddAmenity
