import axios from "axios";
const url = process.env.REACT_APP_SERVER_URL;
export const getAllUnitsByPropertyIdInDB = async(propertyId) => {
    try{
        const responseForUnits= await axios.get(`${url}/property/${propertyId}/units`);
        if(responseForUnits && responseForUnits.data && responseForUnits.data.length>0){
          return responseForUnits.data;
        }
        else{
          return "No Data Found"
        }
    }
    catch(error)
    {
        console.log(error);
    }
};

export const getUnitInfoByIdInDB = async(unitId) =>{
    try{
        const responseForUnits = await axios.get(`${url}/units/${unitId}`)
            if(responseForUnits && responseForUnits.data && responseForUnits.data.length>0){
                return responseForUnits.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const updateUnitInfoByIdInDB = async(unitId,unitJson) =>{
    try{
        console.log(unitJson)
        const responseForUnits = await axios.patch(`${url}/units/${unitId}`,unitJson)
            if(responseForUnits){
                return responseForUnits;
              }
              else{
                return "Updation Failed"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}



