import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import { formatDateText, parseResponseJSON} from "./CommonFunctions";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
    Box
} from "@mui/material";
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
  } from '@mui/x-data-grid';
import Header from "./Header";
import MakePayment from "./MakePayment";
import DeleteConfirmation from "./DeleteConfirmation";

const Payments = () => {
    const [rows,setRows]=useState([]);
    const [showMakePaymentModal,setShowMakePaymentModal] =useState(false);
    const [showDeleteModal,setShowDeleteModal] = useState(false);
    const [payment,setPayment] =useState({})
    const url = process.env.REACT_APP_SERVER_URL;
   const {propertyId} = useParams();
    const columns=[
        { field: 'id', headerName: 'Payment Id', width: 160,align:"center" },
        { field: 'actualPaymentDate', headerName: 'Payment Date', width: 160, align:"left",
        valueFormatter: (params) => {
            // Format the date value before rendering
            const formattedDate = formatDateText(params.value);
            return formattedDate;
          },
        },
        { field: 'receiptNumber', headerName: 'Receipt Number', width: 170 },

        { field: 'paidBy', headerName: 'Paid By', width: 170},

        { field: 'cashAmount', headerName: 'Cash Amount', width: 170,align:"center",
        valueFormatter: (params) => {
            return "$" + params.value;
          },
        },

        { field: 'creditCardAmount', headerName: 'Credit Card Amount', width: 170,align:"center",
        valueFormatter: (params) => {
            return "$" + params.value;
          },
        },

        { field: 'otherAmount', headerName: 'Other Amount', width: 170, align:"center",
            valueFormatter: (params) => {
                return "$" + params.value;
              },
        },
        { field: 'totalAmount', headerName: 'Total Amount', width: 170, align:"center",
            valueGetter: (params) => {
                const totalAmount= params.row.cashAmount + params.row.creditCardAmount + params.row.otherAmount;
                return "$" + totalAmount
            }
        },
        {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: (params) => {
            return [
                <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={(e)=>{
                    console.log(params.row)
                    setPayment(params.row)
                    setShowMakePaymentModal(true);
                    
                }}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                color="inherit"
                onClick={(e)=>{
                  setPayment(params.row)
                  setShowDeleteModal(true);
              }}
              />,
            ];
        }
    }
    ]

    
    useEffect(() => {
        getAllPayments(propertyId);
        
        
    }, []);

    const getAllPayments = async(propertyId) =>{
        try{
            await axios.post(`${url}/allPayments`,{propertyId}).then((responseForPayments)=>{
                setRows(responseForPayments.data)
            })

        }catch(error){
            console.log(error)
        }
    }

    
    return (
        <>
        <Header/>
        <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
      />
    </Box>
    {showMakePaymentModal && <MakePayment showMakePaymentModal={showMakePaymentModal} 
    setShowMakePaymentModal={setShowMakePaymentModal} tenancyJson={{}} payment={payment}/>}      

    {showDeleteModal && <DeleteConfirmation showDeleteModal={showDeleteModal} 
    setShowDeleteModal={setShowDeleteModal} tableName="payment" id={payment.id} />}   
            
        </>
    );

}
export default Payments;