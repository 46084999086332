// src/utils/auth.js
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';

export const getUserRole = () => {
    const token = Cookies.get('token');
    if (!token) return null;

    const decoded = jwtDecode(token);
    return decoded.role;
};
export const getUserMail = () => {
    const token = Cookies.get('token');
    if (!token) return null;

    const decoded = jwtDecode(token);
    console.log(decoded.email)
    return decoded.email;
};

export const isAuthenticated = () => {
    const token = Cookies.get('token');
    console.log(token)
    return !!token;
};
