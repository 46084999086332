import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Link, redirect } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'
import { formatDateText, parseResponseJSON } from '../CommonFunctions'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { Box, Button } from '@mui/material'
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid'
import Header from '../Header'
import AddAmenity from './AddAmenity'
import { getAllAmenitiesInDB } from '../AmenityFunctions'
import DeleteConfirmation from "../DeleteConfirmation";
const AmenitiesReport = () => {
  const url = process.env.REACT_APP_SERVER_URL;
  const { propertyId } = useParams()
  const [amenities, setAmenities] = useState([])
  const [individualAmenity,setIndividualAmenity]=useState({});
  const [showDeleteModal,setShowDeleteModal] = useState(false);
  const [showAddAmenityModal,setShowAddAmenityModal] = useState(false);
  const columns = [
    { field: 'propertyName', headerName: 'Property', width: 220, align: 'ledt' },
    { field: 'unitName', headerName: 'Unit', width: 220, align: 'left' },
    { field: 'description', headerName: 'Description', width: 240 },

    {
      field: 'addedDate',
      headerName: 'Added At',
      width: 170,
      valueFormatter: (params) => {
        const formattedDate = formatDateText(params.value)
        return formattedDate
      },
    },

    {
      field: 'type',
      headerName: 'Amenity Type',
      width: 170,
      align: 'center',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (rowData) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={(e)=>{
                setIndividualAmenity(rowData.row);
                setShowAddAmenityModal(true);
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="inherit"
            onClick={(e)=>{
              setIndividualAmenity(rowData.row);
              setShowDeleteModal(true);
          }}
          />,
        ]
      },
    },
  ]

  useEffect(() => {
    if(propertyId){
      getAmenities(propertyId);
    }
    else{
      getAmenities();
    }
    
    
  }, [showAddAmenityModal])



  const getAmenities = async (propertyId) => {
    try {
      const response= await getAllAmenitiesInDB(propertyId);
      if(response){
        setAmenities(response)
      }

    } catch (error) {
      console.log(error)
    }
  }


  return (
    <>
      <Header />
      <Button color="primary" startIcon={<AddIcon />} onClick={(e)=>{
          setIndividualAmenity({})
          setShowAddAmenityModal(true);
          }}>
          Add Amenity
        </Button>
      <Box
        sx={{
          height: 500,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid rows={amenities} columns={columns} />
      </Box>
      {showAddAmenityModal && <AddAmenity showAddAmenityModal={showAddAmenityModal} 
      setShowAddAmenityModal={setShowAddAmenityModal} amenity={individualAmenity}/>}]


      {showDeleteModal && <DeleteConfirmation showDeleteModal={showDeleteModal} 
      setShowDeleteModal={setShowDeleteModal} tableName="amenity" id={individualAmenity.id} />} 
    </>
  )
}
export default AmenitiesReport;
