import { getAllUnitsByPropertyIdInDB } from "../UnitsFunctions";
import {  getPaymentSummaryByMonthInDB } from "../PaymentFunctions";
import { getAnyOverLappingLeaseInDB } from "../TenancyFunctions";

import {calculateTotalTaxPer, formatDate} from "../CommonFunctions"

export const getAllInfoForMonthlyReport = async(propertyId,searchStartDate,searchEndDate)=>{
  try{

    let arr=[]
    const [unitInfo, tenancyInfo, paymentSummary]= await Promise.all([getAllUnitsByPropertyIdInDB(propertyId), 
      getAnyOverLappingLeaseInDB(searchStartDate,searchEndDate,null), 
      getPaymentSummaryByMonthInDB(searchStartDate,searchEndDate)]);
      if(unitInfo && tenancyInfo && paymentSummary){
  // .then(([unitInfo, tenancyInfo, paymentSummary]) => {
      // Perform data processing and join the data
      console.log(unitInfo)
      console.log(tenancyInfo)
      console.log(paymentSummary)
      let dict1={}
      let dict2={}
      for(let i=0;i<paymentSummary.length;i++){
          dict1[paymentSummary[i].tenancyId]=paymentSummary[i];
      }
      


      for(let i=0;i<unitInfo.length;i++){
        delete unitInfo[i].checkin;
        delete unitInfo[i].checkout;
          dict2[unitInfo[i].id]=unitInfo[i];
        
      }
      console.log(dict2)
      let dict3={}
      let dict4={}
      for(let i=0;i<tenancyInfo.length;i++){
        
        if(dict2[tenancyInfo[i].unitId] && dict1[tenancyInfo[i].id]){
        dict3[tenancyInfo[i].unitId + ' ' +tenancyInfo[i].id]={...tenancyInfo[i],...dict1[tenancyInfo[i].id],...dict2[tenancyInfo[i].unitId]};
        dict4[tenancyInfo[i].unitId]=1;
        dict3[tenancyInfo[i].unitId + ' ' +tenancyInfo[i].id]["expiredLease"]=false;
        }
        else if(dict2[tenancyInfo[i].unitId]){
          dict3[tenancyInfo[i].unitId + ' ' +tenancyInfo[i].id]={...tenancyInfo[i],...dict2[tenancyInfo[i].unitId]};
          dict3[tenancyInfo[i].unitId + ' ' +tenancyInfo[i].id]["tenancyId"]=tenancyInfo[i].id;
          if(tenancyInfo[i].actualCheckoutDate==null){
            if(tenancyInfo[i].checkout<searchStartDate){
              dict3[tenancyInfo[i].unitId + ' ' +tenancyInfo[i].id]["expiredLease"]=true;
            }
            else {
              if(tenancyInfo[i].checkout<formatDate(new Date())){
                dict3[tenancyInfo[i].unitId + ' ' +tenancyInfo[i].id]["expiredLease"]=true;
              }
              //What if lease ends in this month
              else{
                dict3[tenancyInfo[i].unitId + ' ' +tenancyInfo[i].id]["expiredLease"]=false;
              }
              
            }

          }
          
          dict3[tenancyInfo[i].unitId + ' ' +tenancyInfo[i].id]["expectedRent"]=tenancyInfo[i].rate;
          dict4[tenancyInfo[i].unitId]=1;
          }

      }
      for (const key in dict2) {
        if(!dict4[key]){
          console.log(dict2[key])
          dict3[key]=dict2[key]
          dict3[key]["expiredLease"]=false;
        }

      }

      console.log(dict3)
      let exRent=0,recRent=0;
      for (const key in dict3) {
        arr.push(dict3[key])
        if(dict3[key].expectedRent){
          let totalTaxPer=0;
          if(dict3[key].leaseTaxId){
            totalTaxPer= calculateTotalTaxPer(dict3[key])
          }
          let taxAmount=(totalTaxPer * dict3[key].expectedRent)/100;
          exRent+= (dict3[key].expectedRent + taxAmount);
          recRent+=dict3[key].receivedRent || 0;
          
        }
      }
      console.log(arr);
      const returnDictionary={
        reportSummary:arr,
        expectedRent:exRent,
        receivedRent:recRent
      }
      return returnDictionary;
      
  }

  }catch(error){
    console.log(error)
  }
}