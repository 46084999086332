import React, { useState } from 'react';
import {
    Box ,FilledInput ,InputLabel ,FormControl,Stepper,Step,StepLabel,
    Typography,Button, Container,Paper,Select,MenuItem  
} from '@mui/material';
import axios from 'axios';
import AddProperty from './AddProperty';
import AdditionalPropertyInfo from './AdditionalPropertyInfo';
import UnitInfo from './UnitInfo';
import Header from '../Header';
import PropertyReview from './PropertyReview';
import AddTaxes from './AddTaxes';
import { useParams,useNavigate } from 'react-router-dom';
import { createPropertyTaxInDB,updatePropertyTaxInDB } from '../CountyTaxFunctions';
import LandlordDetails from "./LandlordDetails"
import { createLandlordDetailsInDB, updateLandlordDetailsInDB } from '../LandlordDetailsFunctions';

// const steps = ['Select campaign settings', 'Create an ad group'];
const components=[AddProperty,AdditionalPropertyInfo,LandlordDetails,AddTaxes,UnitInfo,PropertyReview]

const PropertyWizard = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [numberOfUnits,setNumberOfUnits]=useState(1);
    const [units,setUnits]=useState([]);
    const {propertyId} = useParams();
    const [propertyJson,setPropertyJson]=useState({
      propertyId:null,
      name:"",
      type:"",
      streetAddress:"",
      city:"",
      state:"FL",
      zipCode:"",
      county:"",

    });
    const [taxJson,setTaxJson] = React.useState({
      countyName:"",
      salesTax:null,
      tourismTax:null,
      surfaceTax:null,
      otherTax:null,
      propertyId:null
    })
    const [landlordJson,setLandlordJson] = React.useState({
      landlordId:null,
      propertyId:propertyId ? propertyId : null,
      name:"",
      address:"",
      phoneNumber:null,
      email:null,
      managerName:""
    })
    const navigate=useNavigate();
    const url = process.env.REACT_APP_SERVER_URL;
  const handleNext = () => {
    if(activeStep==1 && !propertyId)
    {
        createNUnits(numberOfUnits);
        
    } 
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };
  const createUnitInDB = async(propertyId,unitInfo) =>{
    try{
      const name=unitInfo.name;
          const streetAddress=unitInfo.streetAddress;
          await axios.post(`${url}/units`,{
              propertyId,
              name,
              streetAddress
          }).then((responseForCreatingUnits) =>{
              console.log(responseForCreatingUnits.data);
          })
    }catch(error){
      console.log(error);
    }
  }
  const updateUnitInDB = async(propertyId,unitInfo) =>{
    try{
      const name=unitInfo.name;
          const streetAddress=unitInfo.streetAddress;
          await axios.patch(`${url}/units/${unitInfo.id}`,{
              propertyId,
              name,
              streetAddress
          }).then((responseForUpdatingUnits) =>{
              console.log(responseForUpdatingUnits.data);
          })
    }catch(error){
      console.log(error);
    }
  }
  const createMultipleUnits = (propertyId,units) =>{
    try{
      for(let i=0;i<units.length;i++)
      {
        console.log(units[i])
        
        createUnitInDB(propertyId,units[i]);
          
      }

  }catch(error){
      console.log(error);
  }
  }
  const updateMultipleUnits = (propertyId,units) =>{
    for(let i=0;i<units.length;i++)
      {
        console.log(units[i])
        if(units[i].id<0){
          //Means a newly added unit
          createUnitInDB(propertyId,units[i]);
        }
        else{
          //Already existing record in DB
          updateUnitInDB(propertyId,units[i]);
        }
        
          
      }

  }
  const updatePropertyTax = async(taxJson) =>{
    try{
      let tempTaxJson=taxJson;
      // tempTaxJson["propertyId"]=propertyId;
      const response= await updatePropertyTaxInDB(tempTaxJson);
      if(response){
        console.log(response)
      }

    }
    catch(error)
    {
        console.log(error);
    }
  }
  const createPropertyTax = async(taxJson,propertyId) =>{
    try{
      let tempTaxJson=taxJson;
      tempTaxJson["propertyId"]=propertyId;
      const response= await createPropertyTaxInDB(tempTaxJson);
      if(response){
        console.log(response)
      }

    }
    catch(error)
    {
        console.log(error);
    }
  }
  const createNewProperty = async() => {
    try{
        await axios.post(`${url}/property`,propertyJson).then(
          (responseForCreatingProperties) => {
            console.log(responseForCreatingProperties);
            const id=responseForCreatingProperties.data.propertyId;
            setPropertyJson({...propertyJson,propertyId:id});
            createPropertyTax(taxJson,id);
            createMultipleUnits(id,units);
            let tempLandlordJson=landlordJson;
            tempLandlordJson["propertyId"]=id;
            createLandlordDetailsInDB(tempLandlordJson);
            navigate(`/property/${id}/units`);
        })

    }
    catch(error)
    {
        console.log(error);
    }
  };

  const updateProperty = async(propertyId) =>{
    try{
        await axios.patch(`${url}/property/${propertyId}`,propertyJson).then(
          (responseForEditingProperties) => {
            console.log(responseForEditingProperties);
            if(taxJson.id){
              updatePropertyTax(taxJson)
            }
            else{
              createPropertyTax(taxJson,propertyId);
            }
            updateMultipleUnits(propertyId,units);  // ---tricky
            navigate(`/property/${propertyId}/units`);
        })


    }
    catch(error)
    {
        console.log(error);
    }
  }
  const createNUnits = (n) =>{
    let unitRows=[];
    const name=propertyJson.type=="apartment" ? "Apt " : "Unit ";
    for(let i=1;i<=n;i++)
    {
        unitRows.push({"id": i,"name" : name+i,"streetAddress": ""});
    }
    setUnits(unitRows);
}

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const savePropertyAndUnitsInfo = (e) =>{
    alert("in save property And unit info");
  }
  const createOrUpdateProperty = async(propertyId) =>{
    if(propertyId){
      updateProperty(propertyId);
      if(landlordJson.landlordId){
        await updateLandlordDetailsInDB(landlordJson.landlordId,landlordJson);
      }
      else{
        await createLandlordDetailsInDB(landlordJson)
      }
    }
    else{
      createNewProperty();
    }
    

  }
 const CurrentComponent=components[activeStep];
  return (
    <div>
      <Header/>
      <Container sx={{ mt: 5 }}>
  <Paper elevation={3} sx={{ height: "70ch", display: "flex", flexDirection: "column" }}>
    <Box sx={{ pt: 5, px: 5 }}>
      <Stepper activeStep={activeStep}>
        <Step>
          <StepLabel><b>{propertyJson.name ? "Property: " + propertyJson.name : "Property Address"}</b></StepLabel>
        </Step>
        <Step>
          <StepLabel><b>Additional Details</b></StepLabel>
        </Step>
        <Step>
          <StepLabel><b>Landlord Details</b></StepLabel>
        </Step>
        <Step>
          <StepLabel><b>Tax Information</b></StepLabel>
        </Step>
        <Step>
          <StepLabel><b>Unit Details</b></StepLabel>
        </Step>
        <Step>
          <StepLabel><b>Review</b></StepLabel>
        </Step>
      </Stepper>
    </Box>

    {/* Scrollable content */}
    <Box sx={{ flex: 1, overflow: 'auto', px: 5, mt: 2, mb: 1 }}>
      <Typography>
        <CurrentComponent
          units={units}
          setUnits={setUnits}
          propertyJson={propertyJson}
          setPropertyJson={setPropertyJson}
          numberOfUnits={numberOfUnits}
          taxJson={taxJson}
          setTaxJson={setTaxJson}
          setNumberOfUnits={setNumberOfUnits}
          activeStep={activeStep}
          landlordJson={landlordJson}
          setLandlordJson={setLandlordJson}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      </Typography>
    </Box>

    {/* Fixed buttons at the bottom */}
    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, px: 5 }}>
      <Button
        color="inherit"
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{ mr: 1 }}
      >
        Back
      </Button>

      <Box sx={{ flex: '1 1 auto' }} />

      {activeStep < components.length - 1 ? (
        <Button onClick={handleNext}>Next</Button>
      ) : (
        <Button
          onClick={() => {
            createOrUpdateProperty(propertyId);
          }}
        >
          Finish
        </Button>
      )}
    </Box>
  </Paper>
</Container>
 </div>
  );
};

export default PropertyWizard;