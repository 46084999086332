import  React,{
    useState,
    useEffect
} from 'react';
import axios from 'axios';
import { 
    Link, useParams 
} from "react-router-dom";

import {
    Paper,
    Typography,
    FilledInput,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell
} from '@mui/material';
export default function PropertyReview({propertyJson,units,taxJson}) {
    useEffect(() => {
    
      }, []);




  return (
    <>
    {/* Unit Information */}
   
    <h6>Property Information</h6>
    <Paper>
    <p> Name - {propertyJson.name}</p>
    <p> Address - {propertyJson.streetAddress}</p>
    <p>Type  - {propertyJson.type}</p>
    <p>City  - {propertyJson.city}</p>
    <p>State - {propertyJson.state}</p>
    <p>Zip Code - {propertyJson.zipCode}</p>
    
    </Paper><br></br>
    <h6>Tax Information</h6>
    <Paper>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell> County Name</TableCell>
                    <TableCell>Sales Tax</TableCell>
                    <TableCell>Tourism Tax</TableCell>
                    <TableCell>Surface Tax</TableCell>
                    <TableCell>Other Taxes</TableCell>
                    <TableCell>Total Tax</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>{taxJson.countyName}</TableCell>
                    <TableCell>{taxJson.salesTax || 0}%</TableCell>
                    <TableCell>{taxJson.tourismTax || 0}%</TableCell>
                    <TableCell>{taxJson.surfaceTax || 0}%</TableCell>
                    <TableCell>{taxJson.otherTax || 0}%</TableCell>
                    <TableCell>{parseInt(taxJson.salesTax || 0) + parseInt(taxJson.tourismTax || 0) 
                        + parseInt(taxJson.surfaceTax || 0) + parseInt(taxJson.otherTax || 0)}%</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </Paper>
    <h6>Units Information</h6>
    <Paper>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell> Name</TableCell>
                    <TableCell>Street Address</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {units.map((row) => (
                <TableRow key={row.name}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.streetAddress}</TableCell>
                </TableRow>
                ))}
            </TableBody>
        </Table>
    </Paper>

    </>
  );
}