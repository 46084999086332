import { format, formatDistance, formatRelative, subDays } from 'date-fns' 
// const CommonFunctions = () => {  
 export const isDateOrDatetime = (input)  => {
  const date = new Date(input);
  return !isNaN(date.getTime());
}
export const getDataSorted = (sortColumn,sortType,importedData) => {
  if (sortColumn && sortType) {
    // alert(sortType)
    return importedData.sort((a, b) => {
      let x = a[sortColumn];
      let y = b[sortColumn];
      
    
      // console.log(typeof x)
      if(isDateOrDatetime(x) && isDateOrDatetime(y))
      {
        x=new Date(x);
        y=new Date(y);

      }
      else{
      if (typeof x === 'string') {
        x = x.charCodeAt();
        console.log(typeof x + ' x: ' +x)
      }
      if (typeof y === 'string') {
        y = y.charCodeAt();
        console.log(typeof y + ' y: ' +y)
      }
    }
      
      if (sortType === 'asc') {
        return x - y;
      } else {
        return y - x;
      }
    });
  }
  return importedData;
}
 export const  formatDate = ( dateString, numberofDays ) =>{
  // const date=new Date(dateString).toLocaleString();
  // alert(date)
  if(numberofDays)
  {
    dateString.setDate(dateString.getDate()+ numberofDays);
    return format(new Date(dateString), 'yyyy-MM-dd') 

  }
  return format(dateString, 'yyyy-MM-dd') ;
  }
  export const parseResponseJSON = (DbResponse)=>{
    try{
        if(DbResponse){
            const DbResponseString=JSON.stringify(DbResponse);
            const DbResponseJson=JSON.parse(DbResponseString);
            return DbResponseJson;
        }
    }catch(error){
        console.log(error+" while parsing the string "+ DbResponse);
    }
  } 

  export const logMe=(functionName,message)=>{
    console.log("In "+functionName + ": "+message);
  }
  export const dateDifference=(startDate,endDate)=>{
    console.log("Start Date : " + startDate);
    console.log("End Date : " + endDate);
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

return diffDays;
  }

export const formatDateText = (inputDate) => {
  const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
const formattedDate = new Date(inputDate + 'T00:00:00').toLocaleDateString('en-US', options);
return formattedDate;
};

export function calculateMonthsAndDays(startDate, endDate) {
  if(startDate && endDate){
  const tempArrayCheckin=startDate.split('-');
  const tempArrayCheckout=endDate.split('-');
  if(tempArrayCheckin.length==3 &&tempArrayCheckout.length==3 ){
    let yearCheckin=parseInt(tempArrayCheckin[0]);
    let monthCheckin=parseInt(tempArrayCheckin[1]);
    let dateCheckin=parseInt(tempArrayCheckin[2]);
    let yearCheckout=parseInt(tempArrayCheckout[0]);
    let monthCheckout=parseInt(tempArrayCheckout[1]);
    let dateCheckout=parseInt(tempArrayCheckout[2]);

  // Calculate the difference in months and days
  let yearDiff = yearCheckout - yearCheckin;
    let monthDiff = monthCheckout - monthCheckin;
    let dayDiff = dateCheckout - dateCheckin +1;

    // Adjust for negative day difference
    if(dayDiff === new Date(yearCheckout, monthCheckout, 0).getDate())
    {
      monthDiff++;
      dayDiff=0;
    }
    if (dayDiff < 0) {
        monthDiff--;
        const lastDayOfMonth = new Date(yearCheckout, monthCheckout, 0).getDate();
        dayDiff = lastDayOfMonth + dayDiff;
    }

    // Adjust for negative month difference
    if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
    }
    const monthsDiff=monthDiff + (yearDiff * 12)
 console.log("months : " + monthsDiff + "\ndiff in days: " + dayDiff)
 return { months: monthsDiff, weeks: 0,days: dayDiff };
  }
}
  return {msg:"Invalid"};
}
export function calculateWeeksAndDays(startDate, endDate) {
  if(startDate && endDate){
    // Convert start and end dates to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in days between the two dates
    const differenceInDays = Math.round((end - start) / (1000 * 60 * 60 * 24));

    // Calculate the number of weeks by dividing the difference in days by 7
    let numberOfWeeks = Math.floor((differenceInDays +1) / 7);
    const days=(differenceInDays+1) % 7;
    console.log(numberOfWeeks)
    console.log(days)
    return {months: 0,weeks :numberOfWeeks,days:days};
  }
}
export const calculateTotalTaxPer= (row)=>{
  return parseInt(row.salesTax || 0) + 
  parseInt(row.tourismTax || 0) + 
  parseInt(row.surfaceTax || 0) +
  parseInt(row.otherTax || 0);
}

// export default CommonFunctions;
