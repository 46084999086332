import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Link, redirect } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'
import { formatDateText, parseResponseJSON, dateDifference } from '../CommonFunctions'
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DeleteConfirmation from "../DeleteConfirmation";
import { Box,Button } from '@mui/material'
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid'
import Header from '../Header'
import AddRepair from './AddRepair'
import { getRepairsByPropertyId } from '../RepairFunctions'

const RepairsReport = () => {
  const url = process.env.REACT_APP_SERVER_URL
  const { propertyId } = useParams()
  const [repairs, setRepairs] = useState([])
  const [individualRepair,setIndividualRepair]=useState({});
  const [showAddRepairModal,setShowAddRepairModal] = useState(false);
  const [showDeleteModal,setShowDeleteModal] = useState(false);
  const [allRooms, setAllRooms] = useState([])
  const columns = [
    { field: 'id', headerName: 'Ticket No.', width: 100, align: 'left' },
    {
      field: 'unitId',
      headerName: 'Unit',
      width: 100,
      align: 'left',
    },
    { field: 'description', headerName: 'Description', width: 240 },

    {
      field: 'scheduledDate',
      headerName: 'Scheduled At',
      width: 170,
      valueFormatter: (params) => {
        const formattedDate = formatDateText(params.value)
        return formattedDate
      },
    },

    {
      field: 'assignedTo',
      headerName: 'Assigned To',
      width: 170,
      align: 'left',
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      align: 'left',
    },

    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 170,
      align: 'left',
      valueFormatter: (params) => {
        const formattedDate = formatDateText(params.value.split('T')[0])
        return formattedDate
      },
    },
    {
      field: 'dateDifference',
      headerName: 'Days Open',
      width: 120,
      align: 'left',
      valueGetter: (params) => {
        const daysOpen = dateDifference(
          params.row.createdAt,
          params.row.completedDate ? params.row.completedDate : new Date()
        )
        return daysOpen;
      },
    },

    {
      field: 'completedDate',
      headerName: 'Completed At',
      width: 170,
      align: 'left',
    },

    {
      field: 'rating',
      headerName: 'Rating',
      width: 120,
      align: 'left',
    },

    {
      field: 'managerComments',
      headerName: 'Manager Comments',
      width: 170,
      align: 'left',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (rowData) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={(e)=>{
                setIndividualRepair(rowData.row);
                setShowAddRepairModal(true);
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="inherit"
            onClick={(e)=>{
              setIndividualRepair(rowData.row);
              setShowDeleteModal(true);
          }}
          />
        ]
      },
    },
  ]

  useEffect(() => {
    getRepairs(propertyId);
  }, [showAddRepairModal]) 


  const getRepairs = async (propertyId) =>{
    try {
      const response= await getRepairsByPropertyId(propertyId);
      if(response){
        setRepairs(response)
      }
      } catch (error) {
        console.log(error)
      }

  }



  return (
    <>
      <Header />
      <Button color="primary" startIcon={<AddIcon />} onClick={(e)=>{
          setIndividualRepair({})
          setShowAddRepairModal(true);
          }}>
          Add Repair
        </Button>
      <Box
        sx={{
          height: 500,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid rows={repairs} columns={columns} />
      </Box>
      {showAddRepairModal  && <AddRepair showAddRepairModal={showAddRepairModal} 
    setShowAddRepairModal={setShowAddRepairModal} repair={individualRepair} /> }


{showDeleteModal && <DeleteConfirmation showDeleteModal={showDeleteModal} 
      setShowDeleteModal={setShowDeleteModal} tableName="repair" id={individualRepair.id} />} 
    </>
    
  )
}
export default RepairsReport;
