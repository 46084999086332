import React, { useState, useEffect } from 'react';
import {
    Box ,FilledInput ,InputLabel ,FormControl,Stepper,Step,StepLabel,
    Typography,Button, Container,Paper,Select,MenuItem  
} from '@mui/material';
import { useParams,useNavigate } from 'react-router-dom';
import Header from '../Header';
import LeaseWizard from './LeaseWizard';

const Lease = () => {
    
  
  return (
    <div>
       <Header/> 
       <LeaseWizard/>
    </div>
  );
};

export default Lease;