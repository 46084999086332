import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import {
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from "@mui/material";
import { ThreeDayNotice } from "./ThreeDayNotice";

const ThreeDayNoticeModal = ({
    showThreeDayNoticeModal,
    setShowThreeDayNoticeModal,
    tenancy,
    unit,
    landlord,
    property,
    scheduledPayments,
    rows
}) => {
   
    const [tenancyJson,setTenancyJson] = useState(() => {
      if (tenancy) {
        return tenancy;
      } else {
        return {};
      }
    });
    const [unitJson,setUnitJson] = useState(() => {
        if (unit) {
          return unit;
        } else {
          return {};
        }
      });
      const [landlordJson,setLandlordJson] = useState(() => {
        if (landlord) {
          return landlord;
        } else {
          return {};
        }
      });
      const [propertyJson,setPropertyJson] = useState(() => {
        if (property) {
          return property;
        } else {
          return {};
        }
      });
    const url=process.env.REACT_APP_SERVER_URL;
    const {propertyId,unitId,tenancyId} = useParams();

    
      const handleCloseMakePaymentPopup=()=>{
        setShowThreeDayNoticeModal(false)
      }
      useEffect(() => {
        if(propertyId && Object.entries(propertyJson).length === 0){

        }
        if(unitId && Object.entries(unitJson).length === 0){

        }
        if(tenancyId && Object.entries(tenancyJson).length === 0){

        }
        
        
      }, []);

    
    
    return (
        <>
      <Dialog
        open={showThreeDayNoticeModal}
        onClose={handleCloseMakePaymentPopup}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle >Three Day Notice  </DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          <ThreeDayNotice  tenancyJson={tenancyJson} unitJson={unitJson} landlordJson={landlordJson}
            propertyJson={propertyJson} scheduledPayments={scheduledPayments} customersDetails={rows} />
          
        </DialogContent>
      </Dialog>
                
            
        </>
    );

}
export default ThreeDayNoticeModal;