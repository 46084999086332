import React, { useEffect, useState } from 'react';
import axios from "axios";
import UnitInfo from './UnitInfo';
// import CloseIcon from '@mui/icons-material/Close';
import {
    Button, 
    TextField,
    FilledInput,


    Box,
    InputLabel ,
    FormControl,
    Select,
    MenuItem ,
    InputAdornment,
    Paper,
    Typography
} from '@mui/material';
import { PatternFormat } from 'react-number-format';
import { useParams } from 'react-router-dom';
import {
     getLandlordDetailsByPropertyIdInDB
} from "../LandlordDetailsFunctions";

const  LandlordDetails = ({landlordJson,setLandlordJson}) => {
    const {propertyId}=useParams();
    useEffect(()=>{
     if(propertyId){
          getLandlordDetailsByPropertyId(propertyId);
     }
     

    },[])
    const getLandlordDetailsByPropertyId = async(propertyId) =>{
     try{
          const response = await getLandlordDetailsByPropertyIdInDB(propertyId);
          console.log(response)
          if(response && response.length>0){
               setLandlordJson(response[0])
               
          }
     }
     catch(error){
          console.log(error)
     }

    }

    return (
        <>
        

        <Typography variant='h6' sx={{m:1}}>
        Landlord's Details
        </Typography>
        <h6></h6>
        
        <TextField sx={{m:1,width:"48ch"}}  type="text" variant="filled"  label="Landlord's Name" 
        value={landlordJson.name} onChange={(e)=>{
            setLandlordJson({...landlordJson,name: e.target.value});
        }}/>
        <FormControl fullWidth sx={{ m: 1,width:"34ch"}} variant="filled">
        <InputLabel>Landlord's Phone Number</InputLabel>
        <PatternFormat  format="+1 (###) ###-####" customInput={FilledInput} label="Landlord's Phone Number" 
        value={landlordJson.phoneNumber}  onChange={(e)=>{
             setLandlordJson({...landlordJson,phoneNumber:e.target.value});
        }}/>
        </FormControl>
        
        
        
        <TextField sx={{m:1,width:"84ch"}} type="text" variant="filled" label="Landlord's Address" 
        value={landlordJson.address} onChange={(e)=>{
             setLandlordJson({...landlordJson,address: e.target.value});
        }} 
          
        />

        <TextField sx={{m:1,width:"48ch"}} type="float" variant="filled" label="Landlord's Email" 
        value={landlordJson.email}  onChange={(e)=>{
             setLandlordJson({...landlordJson,email: e.target.value});
        }}
        />

        
        <TextField sx={{m:1,width:"34ch"}} type="float" variant="filled" label="Manager's Name" 
        value={landlordJson.managerName}  onChange={(e)=>{
             setLandlordJson({...landlordJson,managerName: e.target.value});
        }}
        />

 
        </>
        
    );
  }
  export default LandlordDetails;