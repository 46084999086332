import pdfMake, { fonts } from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts={
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf'
},
  DejaVu:{
    normal:"DejaVuSans.ttf",
    bold:"DejaVuSans-Bold.ttf"
  }
}
fetch('/fonts/DejaVuSans.ttf').then(res => res.arrayBuffer()).then(buffer => {
  pdfMake.vfs['DejaVuSans.ttf'] = buffer;
});
fetch('/fonts/DejaVuSans-Bold.ttf').then(res => res.arrayBuffer()).then(buffer => {
  pdfMake.vfs['DejaVuSans-Bold.ttf'] = buffer;
});
export function printPage(dataJson){
  console.log(dataJson)
    const inputNumberedList=[];
    const terms=[];
    function divideFourParts(p1,p2,p3,p4){
      const signatures=[];
      signatures.push({
        columns: [
          {
              text: [
                  { text: `\n________ ${p1}`,}
              ]
          },
          {
              text:[
                { text: `\n________ ${p2}`,}
              ]
          },
          {
            text: [
              { text: `\n________ ${p3}`,}
            ]
        },
        p4 ? 
        {
            text:[
              { text: `\n________ ${p4}`,}
            ]
        }
        :{
          text:[]
        }

      ]
      });
      return signatures;

    }
    function fourSignatures(firstColumn,secondColumn){
      const signatures=[];
      signatures.push({
        columns: [
          {
              text: [
                  { text: '\n\n_________________________\n' }, // Text before the bold word
                  { text: `${firstColumn}` + '\n',alignment:"left"}, // Bold word
              ],style:{
                lineHeight:1
              }
          },
          {
              text:[
                  { text: '\n\n__________________\n' }, // Text before the bold word
                  { text: `${secondColumn}` + '\n',alignment:"left" }, // Bold word
              ],style:{
                lineHeight:1
              }
          },
          {
            text: [
                { text: '\n\n_________________________\n' }, // Text before the bold word
                { text: `${firstColumn}` + '\n',alignment:"left"}, // Bold word
            ],style:{
              lineHeight:1
            }
        },
        {
            text:[
                { text: '\n\n__________________\n' }, // Text before the bold word
                { text: `${secondColumn}` + '\n',alignment:"left" }, // Bold word
            ],style:{
              lineHeight:1
            }
        }

      ]

      });
      return signatures;

    }
    function signaturesDisplay(firstColumn,secondColumn){
      const signatures=[];
    signatures.push({
      columns: [
        {
            text: [
                { text: '\n\n________________________________________________\n' }, // Text before the bold word
                { text: `${firstColumn}` + '\n',alignment:"left"}, // Bold word
            ],style:{
              lineHeight:1
            }
        },
        {
            text:[
                { text: '\n\n________________________________________________\n' }, // Text before the bold word
                { text: `${secondColumn}` + '\n',alignment:"left" }, // Bold word
            ],style:{
              lineHeight:1
            }
        }

    ]
    })
    return signatures;

    }
    
    

  
    var documentDefinition = {
      content: [
        { text: 'Residential Lease for Single Family Home or Duplex', style: 'header' },
        { text: '(FOR A TERM NOT TO EXCEED ONE YEAR)', style: 'subheader' },
        { text: 'A BOX (  ) OR A BLANK SPACE ( ____ ) INDICATES A PROVISION WHERE A CHOICE OR DECISION MUST BE MADE BY THE PARTIES.' },
        { text: '\nTHE LEASE IMPOSES IMPORTANT LEGAL OBLIGATIONS. MANY RIGHTS AND RESPONSIBILITIES OF THE PARTIES ARE GOVERNED BY CHAPTER 83, PART II, RESIDENTIAL LANDLORD AND TENANT ACT, FLORIDA STATUTES. A COPY OF THE RESIDENTIAL LANDLORD AND TENANT ACT IS ATTACHED TO THIS LEASE.\n',bold:true },
        {text:"\n"},
        {
          style:{
            margin:[15,0,15,0]
          },
          text: [
            {
              text: [
                { text: '1. PARTIES.', style: 'sectionTitle' },
                ' This is a lease ("the Lease") between ',
                { text: `${dataJson.landlord_name} `, style:'underlinedText' }, // Adjust width as needed
                ' (name & address of owner of the property) ',
                
                { text: `${dataJson.landlord_address}`, style:'underlinedText'},
                ' (“Landlord”) and ',
                { text: `${dataJson.tenants_names_list}`,style:'underlinedText' },
                ' (name(s) of person(s) to whom the property is leased) (“Tenant.”) ',
                { text: '\n\n' },
                {
                      text: [
                        'Landlord’s E-mail address: \t', 
                        { text: `${dataJson.landlord_email}`, style:'underlinedText'},
                        // { text: '____________________________________' }
                      ],
                    },
                    {
                      text: [
                        '\nLandlord’s Telephone Number: \t', 
                        { text: `${dataJson.landlord_phoneNumber}`, style:'underlinedText'},

                        // { text: '____________________________________' }
                      ],
                    },
                    {
                      text: [
                        '\nTenant’s E-mail address: \t', 
                        { text: `${dataJson.tenant_email}`, style:'underlinedText'},

                        // { text: '____________________________________' }
                      ],
                    },
                    {
                      text: [
                        '\nTenant’s Telephone Number: \t', 
                        { text: `${dataJson.tenant_phone}`, style:'underlinedText'},

                        // { text: '____________________________________' },
                        '\n\n'
                      ],
                    
                },
              ],
            },
            {
              text:[
                { text: '2. PROPERTY RENTED. ', style: 'sectionTitle' },
                'Landlord leases to Tenant the land and buildings located at ',
                {text: `${dataJson.property_streetAddress}`,style:'underlinedText' },
                '(street address) , Florida ',
                {text:`${dataJson.property_zipCode}`,style:'underlinedText'},
                ' (zip code) \n\ntogether with the following furniture and appliances [List all furniture and appliances. If none, write "none."] (In the Lease, the property leased, including furniture and appliances, if any, is called "the Premises"): \n'
                ,{text:`${dataJson.appliances_list}`,style:'underlinedText'},
                '\nThe Premises shall be occupied only by the Tenant and the following persons: \n',
                {text:`${dataJson.tenants_names_list}`,style:'underlinedText'},
                {text:'\n\n'}
              ]
            },
            {
              text:[
                {text:'3. TERM.',style: 'sectionTitle'},
                ' This is a lease for a term, not to exceed twelve months, beginning on ',
                {text:`${dataJson.leaseStartDate}`,style:'underlinedText'},
                '(month, day, year) and ending ',
                {text:`${dataJson.leaseEndDate}`,style:'underlinedText'},
                ' (month, day,year) (the "Lease Term"). '
              ]
            },
            {
                  text:[
                  {text:'\n4. RENT PAYMENTS, TAXES AND CHARGES.\n', style: 'sectionTitle'},
                  {text:'Tenant shall pay total rent in the amount of $ '},
                 {text: `${dataJson.total_rent}`, style: 'underlinedText'},
                ' (excluding taxes) for the Lease Term. The rent shall be payable by Tenant in advance in installments or in full as provided in the options below:',
                ,
                  ]
            },
          ]
        },
        {
          
          ul: [
            
            [
              {
                text:[
                  {text:dataJson.checkbox_4_1 ? `☑` :`☐`,style:'boxStyle'},
                      ' in installments. If in installments, rent shall be payable ',
                ]
              },
                
                {
                    ul: [
                        {text:[
                          {text:dataJson.checkbox_4_2 ? `☑` :`☐`,style:'boxStyle'},
                          ' monthly, on the ',
                          { text: dataJson.checkbox_4_2 ? `${dataJson.m_which_day}` : '', style: 'underlinedText' },
                          ' day of each month (if left blank, on the first day of each month) in the amount of $',
                          { text: dataJson.checkbox_4_2 ? `${dataJson.m_install_amount}` : '', style: 'underlinedText' },
                          ' per installment.\n'
                        ], margin:[40,10,0,10]},
                        {text:[
                          {text:dataJson.checkbox_4_3 ? `☑` :`☐`,style:'boxStyle'},
                          {text:'weekly, on the '},
                          { text: dataJson.checkbox_4_3 ? `${dataJson.w_which_day}` : '', style: 'underlinedText' },
                          {text:' day of each week (if left blank, on Monday of each week) in the amount of $'},
                          { text: dataJson.checkbox_4_3 ? `${dataJson.w_install_amount}` : '', style: 'underlinedText' },
                          ' per installment.\n'
                        ], margin:[40,0,0,10]},
                    ],
                    style:{
                      listStyle:'none'
                    },
                },
            ],
            {text:[
              {text:dataJson.checkbox_4_4 ? `☑` :`☐`,style:'boxStyle'},
            {text:' in full on   '},
            { text: dataJson.checkbox_4_4 ? `${dataJson.f_which_day}` : '', style: 'underlinedText' },

                ' (date) in the amount of $ ',
            { text: dataJson.checkbox_4_4 ? `${dataJson.f_install_amount}` : '', style: 'underlinedText' },

          ]}
        ]
        },
        {text:[
          '\nTenant shall also be obligated to pay taxes on the rent when applicable in the amount of $ ',
          {text:`${dataJson.total_taxes}`,style: 'underlinedText'},
          {text:dataJson.checkbox_4_5 ?  `☑` :`☐`,style:'boxStyle'},
          'with each rent installment ',
          {text:dataJson.checkbox_4_6 ?  `☑` :`☐`,style:'boxStyle'},
          ' with the rent for the full term of the Lease. Landlord will notify Tenant if the amount of the tax changes.'
        ]},
        {
          text:[
            '\nPayment Summary  \n',
          {text:dataJson.checkbox_ps_1 ?  `☑` :`☐`,style:'boxStyle'},
            ' If rent is paid in installments, the total payment per installment including taxes shall be in the amount of $',
            {text:dataJson.input_ps_1.length>0 ? `${dataJson.input_ps_1}`: 
             ' ____________________ ', style: 'underlinedText'},
            '.  \n',
            {text:dataJson.checkbox_ps_2 ?  `☑` :`☐`,style:'boxStyle'},
            ' If rent is paid in full, the total payment including taxes shall be in the amount of $',
            {text:dataJson.input_ps_2.length>0 ? `${dataJson.input_ps_2}`: 
             ' ____________________ ', style: 'underlinedText'},

           

          ],bold:true
        },
        {
          text:[
            '\nAll rent payments shall be payable to ',
            {text:dataJson.input_ps_3.length>0 ? `${dataJson.input_ps_3}`: 
             ' _________________________________ ', style: 'underlinedText'}, 
            ' (name) at ',
            {text:dataJson.input_ps_4.length>0 ? `${dataJson.input_ps_4}`: 
             ' ______________________________________ ', style: 'underlinedText'},
             
            ' (address). (If left blank, to Landlord at Landlord’s address).  '
          ]
        },
        {
          text:[
            '\n ',
            {text:dataJson.checkbox_ps_3 ?  `☑` :`☐`,style:'boxStyle'},
            ' If the tenancy starts on a day other than the first day of the month or week as designated above, the rent shall be prorated from ',
            {text:dataJson.input_ps_5.length>0 ? `${dataJson.input_ps_5}`: 
             ' ___________ ', style: 'underlinedText'},
            '(date) through ',
            {text:dataJson.input_ps_6.length>0 ? `${dataJson.input_ps_6}`: 
             ' ________________  ', style: 'underlinedText'},
            
            ' (date)in the amount of $ ', {text:dataJson.input_ps_7.length>0 ? `${dataJson.input_ps_7}`: 
            ' ____________  ', style: 'underlinedText'}, 

            ' and shall be due on ',
            {text:dataJson.input_ps_8.length>0 ? `${dataJson.input_ps_8}`: 
             ' ___________ ', style: 'underlinedText'},
           
            ' (date) (If rent paid monthly, prorate on a 30 day month.) '
           ]
        },
        {
          text:[
            '\nTenant shall make rent payments required under the Lease by (choose all applicable) ',
            {text:dataJson.checkbox_ps_4 ?  `☑` :`☐`,style:'boxStyle'},
            ' cash, ',  {text:dataJson.checkbox_ps_5 ?  `☑` :`☐`,style:'boxStyle'}, ' personal check, ',
            {text:dataJson.checkbox_ps_6 ?  `☑` :`☐`,style:'boxStyle'}, ' money order, ', 
            {text:dataJson.checkbox_ps_7  ?  `☑` :`☐`,style:'boxStyle'},
             ' cashier’s check, or ', {text:dataJson.checkbox_ps_8 ?  `☑` :`☐`,style:'boxStyle'},' other ',
             {text:dataJson.input_ps_9.length>0 ? `${dataJson.input_ps_9}`: 
             '______________________________', style: 'underlinedText'},
            '(specify). If payment is accepted by any means other than cash, payment is not considered made until the other instrument is collected.'
           ]
        },
        {
          text:[
            '\nIf Tenant makes a rent payment with a worthless check, Landlord can require Tenant ',
            {text:dataJson.checkbox_ps_9 ?  `☑` :`☐`,style:'boxStyle'},
            'to pay all future payments by ',
            {text:dataJson.checkbox_ps_10 ?  `☑` :`☐`,style:'boxStyle'},
            'money order, cashier’s check or official bank check or ',
            {text:dataJson.checkbox_ps_11 ?  `☑` :`☐`,style:'boxStyle'},
            'cash or other (specify), and ',
            {text:dataJson.checkbox_ps_12 ?  `☑` :`☐`,style:'boxStyle'},
            'to pay bad check fees in the amount of $',
            {text:dataJson.input_ps_10.length>0 ? `${dataJson.input_ps_10}`: '____________', style: 'underlinedText'},
            ' (not to exceed the amount prescribed by Section 68.065, Florida Statutes.)'
            ]
        },
        {
          text:[
            {text:'\n5. MONEY DUE PRIOR TO OCCUPANCY.',style: 'sectionTitle'},
            
            ' Tenant shall pay the sum of $',
            {text:dataJson.input_5_1.length>0 ? `${dataJson.input_5_1}`: '_________', style: 'underlinedText'},
            ' in accordance with this paragraph prior to occupying the Premises. Tenant shall not be entitled to move in or to keys to the Premises until all money due prior to occupancy has been paid. If no date is specified below, then funds shall be due prior to Tenant occupancy.',
            ' Any funds designated in this paragraph due after occupancy, shall be paid accordingly. Any funds due under this paragraph shall be payable to Landlord at Landlord’s address or to',
            '\n',
            {text:dataJson.input_5_2.length>0 ? `${dataJson.input_5_2}`: 
            '________________________________________________', style: 'underlinedText'},
             
            ' (name)',
            'at',
            '\n',
            {text:dataJson.input_5_3.length>0 ? `${dataJson.input_5_3}`: 
            '_____________________________________________________________', style: 'underlinedText'},
            ' (address)',

          ]
        },
        {
          text:[
            `First `,
            {text:dataJson.checkbox_5_1 ?  `☑` :`☐`,style:'boxStyle'},
            ` month’s `,
            {text:dataJson.checkbox_5_2 ?  `☑` :`☐`,style:'boxStyle'},
            ` week’s rent plus applicable taxes `,
            `\t\t\t $ `,
            {text:dataJson.input_5_p1.length>0 ? `${dataJson.input_5_p1}`: 
            '_________', style: 'underlinedText'},
            `\t due  `,{text:dataJson.input_5_d1.length>0 ? `${dataJson.input_5_d1}`: 
            '_________', style: 'underlinedText'}, `\n`,
            `Prorated rent plus applicable taxes \t\t\t\t\t\t\t\t\t\t\t $ `,
            {text:dataJson.input_5_p2.length>0 ? `${dataJson.input_5_p2}`: 
            '_________', style: 'underlinedText'},
            ` \t due `,
            {text:dataJson.input_5_d2.length>0 ? `${dataJson.input_5_d2}`: 
            '_________', style: 'underlinedText'},`\n`,
            `Advance rent for `,
            {text:dataJson.checkbox_5_3 ?  `☑` :`☐`,style:'boxStyle'},
            ` month `,
            {text:dataJson.checkbox_5_4 ?  `☑` :`☐`,style:'boxStyle'},
            ` week of `,
            {text:dataJson.input_5_4.length>0 ? `${dataJson.input_5_4}`: 
            '_________', style: 'underlinedText'},` \n\t\t\t\tplus applicable taxes `,
            `\t\t \t\t \t\t \t\t \t\t \t\t\t \t \t $ `,
            {text:dataJson.input_5_p3.length>0 ? `${dataJson.input_5_p3}`: 
            '_________', style: 'underlinedText'},
            ` \t due  `,
            {text:dataJson.input_5_d3.length>0 ? `${dataJson.input_5_d3}`: 
            '_________', style: 'underlinedText'},
            `\n`,
            `last  `,
            {text:dataJson.checkbox_5_5 ?  `☑` :`☐`,style:'boxStyle'},
            ` month’s `,
            {text:dataJson.checkbox_5_6 ?  `☑` :`☐`,style:'boxStyle'},
            ` week’s rent plus applicable taxes`,
            `\t\t\t $`,
            {text:dataJson.input_5_p4.length>0 ? `${dataJson.input_5_p4}`: 
            '_________', style: 'underlinedText'},
            ` \t due `,
            {text:dataJson.input_5_d4.length>0 ? `${dataJson.input_5_d4}`: 
            '_________', style: 'underlinedText'},` \n`,
            `Security deposit \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t $ `,
            {text:dataJson.input_5_p5.length>0 ? `${dataJson.input_5_p5}`: 
            '_________', style: 'underlinedText'},
            ` \t due  `,
            {text:dataJson.input_5_d5.length>0 ? `${dataJson.input_5_d5}`: 
            '_________', style: 'underlinedText'},`\n`,
            `Additional Security deposit \t\t\t\t\t \t\t\t\t\t\t\t\t\t $ `,
            {text:dataJson.input_5_p6.length>0 ? `${dataJson.input_5_p6}`: 
            '_________', style: 'underlinedText'},
            ` \t due `,
             {text:dataJson.input_5_d6.length>0 ? `${dataJson.input_5_d6}`: 
            '_________', style: 'underlinedText'},`\n`,
            `Security deposit for homeowner’s association \t\t\t\t\t\t$ `,
            {text:dataJson.input_5_p7.length>0 ? `${dataJson.input_5_p7}`: 
            '_________', style: 'underlinedText'},
            ` \t due  `,
            {text:dataJson.input_5_d7.length>0 ? `${dataJson.input_5_d7}`: 
            '_________', style: 'underlinedText'},
            `\n`,
            
            `Pet Deposit  \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t$ `,
            {text:dataJson.input_5_p8.length>0 ? `${dataJson.input_5_p8}`: 
            '_________', style: 'underlinedText'},
            ` \t due  `,
            {text:dataJson.input_5_d8.length>0 ? `${dataJson.input_5_d8}`: 
            '_________', style: 'underlinedText'},`\n`,
            `Other `,
            {text:dataJson.input_5_5.length>0 ? `${dataJson.input_5_5}`: 
            '_________', style: 'underlinedText'},` \t\t\t\t\t\t$ `,
            {text:dataJson.input_5_p9.length>0 ? `${dataJson.input_5_p9}`: 
            '_________', style: 'underlinedText'},
            ` \t due  `,
            {text:dataJson.input_5_d9.length>0 ? `${dataJson.input_5_d9}`: 
            '_________', style: 'underlinedText'},`\n`,
            `Other `,
            {text:dataJson.input_5_6.length>0 ? `${dataJson.input_5_6}`: 
            '_________', style: 'underlinedText'},` \t\t\t\t\t\t$ `,
            {text:dataJson.input_5_p10.length>0 ? `${dataJson.input_5_p10}`: 
            '_________', style: 'underlinedText'},
            ` \t due  `,
            {text:dataJson.input_5_d10.length>0 ? `${dataJson.input_5_d10}`: 
            '_________', style: 'underlinedText'},`\n`,

          ]

        },
        {
          text:[
            {text:'\n\n6. LATE FEES.',style: 'sectionTitle'},

            '(Complete if applicable) In addition to rent, Tenant shall pay a late charge in the amount of $ ',
            {text:dataJson.input_6_1.length>0 ? `${dataJson.input_6_1}`: '_____________', style: 'underlinedText'},
            ' (If left blank, 4% of the rent payment) for each rent payment made ',
            {text:dataJson.input_6_2.length>0 ? `${dataJson.input_6_2}`: '_____________',style: 'underlinedText'},
            ' days after the day it is due (if left blank, 5 days if rent is paid monthly, 1 day if rent is paid weekly). '
          ]
        },
        {
          text:[
            {text:'\n\n7. PETS AND SMOKING. ',style: 'sectionTitle'},

            ,
            'Unless this box ',
             {text:dataJson.checkbox_7_1 ? `☑` :`☐`,style:'boxStyle'},
            ' is checked or a pet deposit is paid, Tenant may not keep pets or animals on the Premises. If Tenant may keep pets, the pets described in this paragraph are permitted on the Premises.',
            {text: dataJson.input_7_1.length>0 ? `${dataJson.input_7_1}`:
            ' ____________________________________________________________________________ ', style:"underlinedText"},
            '(Specify number of pets, type(s), breed, maximum adult weight of pets.)\n',
            'Unless this box ',
            {text:dataJson.checkbox_7_2 ? `☑` :`☐`,style:'boxStyle'},
            ' is checked, no smoking is permitted in the Premises.'
          ]
        },
        {
          text:[
            {text:'\n\n8. NOTICES.',style: 'sectionTitle'},

            {text: dataJson.input_8_1.length>0 ? `${dataJson.input_8_1}`:
            ' ____________________________________________________' , style:"underlinedText"},
            'is Landlord’s Agent. All notices must be sent to ',
            '\n ',
            {text:dataJson.checkbox_8_1 ? `☑` :`☐`,style:'boxStyle'},
            ' Landlord ',
            {text: dataJson.input_8_2.length>0 ? `${dataJson.input_8_2}`:
            '_______________________ ', style:"underlinedText"},
            'at', 
            {text: dataJson.input_8_3.length>0 ? `${dataJson.input_8_3}`:
            '___________________________________________', style:"underlinedText"},
            '\n ',
            {text:dataJson.checkbox_8_2 ? `☑` :`☐`,style:'boxStyle'},
            ' Landlord’s Agent ',
            {text: dataJson.input_8_4.length>0 ? `${dataJson.input_8_4}`:
            '________________', style:"underlinedText"},
            ' at ',
            {text: dataJson.input_8_5.length>0 ? `${dataJson.input_8_5}`:
            '__________________________________________', style:"underlinedText"},
            '\nunless Landlord gives Tenant written notice of a change. All notices of such names and addresses or changes thereto shall be delivered to the Tenant’s residence or, if specified in writing by the Tenant, to any other address. All notices to the Landlord or the Landlord’s Agent (whichever is specified above) shall be given by U.S. mail or by hand delivery. ',
            '\nAny notice to Tenant shall be given by U.S. mail or delivered to Tenant at the Premises. If Tenant is absent from the Premises, a notice to Tenant may be given by leaving a copy of the notice at Premises.'
          ]
          
        },
        {
          text:[
            {text:'\n\n9. UTILITIES.',style: 'sectionTitle'},

            
            ' Tenant shall pay for all utilities services during the Lease Term and connection charges and deposits for activating existing utility connections to the Premises except for',
            {text: dataJson.input_9_1.length>0 ? `${dataJson.input_9_1}`:
            '__________________________', style:"underlinedText"},
            ', that Landlord agrees to provide at Landlord’s expense (If blank, then “NONE”). '
          ],
          
        },
        {
          text:[
            {text:'10. MAINTENANCE.',style: 'sectionTitle'},
            
            ' Landlord shall be responsible for compliance with Section 83.51, Florida Statutes, and shall be responsible',
            'for maintenance and repair of the Premises, unless otherwise stated below: (Fill in each blank space with ',
            '“Landlord" for Landlord or “Tenant" for Tenant, if left blank, Landlord will be responsible for the item): '
          ],pageBreak: 'before'

        },
        //FIXX
        // {text:'________ roofs '},
        { stack:divideFourParts("roofs","windows","screens", "steps ")  },
        { stack:divideFourParts("doors", "floors", "porches", "exterior walls")  },
        { stack:divideFourParts("foundations","plumbing", "structural-components",null)  },
        { stack:divideFourParts("heating","hot water", "running water","locks and keys ")  },
        { stack:divideFourParts("electrical","system cooling","smoke detection device",null)  },
        {text:'________ garbage removal/ outside receptacles'},
        {text:'________  extermination of rats, mice, roaches, ants and bedbugs'},
        {text:'________ extermination of wood-destroying organisms'},
        {text:'________ lawn/shrubbery  \t __________pool/spa/hot tub' },
        {text:'________ water treatment \t __________ filters (specify) _______________________'},
        {text:'________ ceilings        \t _________ interior walls'},
        {text:'________  Other (specify) ______________________________________________________________________ '},
        {
          text:[
            'Tenant shall notify ',
            {text: dataJson.input_10_1.length>0 ? `${dataJson.input_10_1}`:
            `________________________________ `, style:"underlinedText"},
           
            '(name) at ',
            {text: dataJson.input_10_2.length>0 ? `${dataJson.input_10_2}` :
            `_________________________ `, style:"underlinedText"},
            '(address) (if left blank, Landlord at Landlord’s address) and ',
            {text: dataJson.input_10_3.length>0 ? `${dataJson.input_10_3}` :
            `____________________________________ `, style:"underlinedText"},
            ' (telephone number) of maintenance and repair requests. '
          ]
        },
        {
          text:[
            {text:'\n\n11. ASSIGNMENT.',style: 'sectionTitle'},
            
            ' Unless this box ',
            {text:dataJson.checkbox_11_1 ? `☑` :`☐`,style:'boxStyle'},
            ' is checked, Tenant may not assign the Lease or sublease all or any part of the Premises without first ',
            'obtaining the Landlord’s written approval and consent to the assignment or sublease. '
          ]
        },
        {
          text:[
            {text:'\n\n12. KEYS AND LOCKS.',style: 'sectionTitle'},
            
            'Landlord shall furnish Tenant\n',
            {text: dataJson.input_12_1.length>0 ? `${dataJson.input_12_1}`:
            `________________ `, style:"underlinedText"},` # of sets of keys to the dwelling\n`,
            {text: dataJson.input_12_2.length>0 ? `${dataJson.input_12_2}`:
            `________________ `, style:"underlinedText"},` # of mail box keys\n`,
            {text: dataJson.input_12_3.length>0 ? `${dataJson.input_12_3}`:
            `________________ `, style:"underlinedText"},` # of garage door openers\n`,
            `If there is a homeowner’s association, Tenant will be provided with the following to access the association’s common areas/facilities:\n`,
            {text: dataJson.input_12_4.length>0 ? `${dataJson.input_12_4}`:
            `________________ `, style:"underlinedText"} ,` # of keys to `, {text: dataJson.input_12_5.length>0 ? `${dataJson.input_12_5}`:
            `__________________________________________ `, style:"underlinedText"}, `\n`,
            {text: dataJson.input_12_6.length>0 ? `${dataJson.input_12_6}`:
            `________________ `, style:"underlinedText"}, `# of remote controls to `, {text: dataJson.input_12_7.length>0 ? `${dataJson.input_12_7}`:
            `__________________________________________ `, style:"underlinedText"}, `\n`,
            {text: dataJson.input_12_8.length>0 ? `${dataJson.input_12_8}`:
            `________________ `, style:"underlinedText"},` # of electronic cards to `, {text: dataJson.input_12_9.length>0 ? `${dataJson.input_12_9}`:
            `__________________________________________ `, style:"underlinedText"}, `\n`,
            {text: dataJson.input_12_10.length>0 ? `${dataJson.input_12_10}`:
            `________________ `, style:"underlinedText"},`other (specify) to `, {text: dataJson.input_12_11.length>0 ? `${dataJson.input_12_11}`:
            `__________________________________________ `, style:"underlinedText"}, `\n`,
            `At end of Lease Term, all items specified in this paragraph shall be returned to `,
            {text: dataJson.input_12_12.length>0 ? `${dataJson.input_12_12}`:
            `____________________________`, style:"underlinedText"},
            ` (name) at `,
            {text: dataJson.input_12_13.length>0 ? `${dataJson.input_12_13}`:
            `__________________________________________`, style:"underlinedText"},
            
            `(address) (If left blank, Landlord at Landlord’s address). `
          ]
        },
        {
          text:[
            {text:'13. LEAD-BASED PAINT.',style: 'sectionTitle'},
            {text:dataJson.checkbox_13_1 ? `☑` :`☐`,style:'boxStyle'},
            ' Check and complete if the dwelling was built before January 1, 1978.Lead Warning Statement (when used in this article, the term Lessor refers to Landlord and the term ',
            ' Lessee refers to Tenant). \nHousing built before 1978 may contain lead-based paint. Lead from paint, paint chips, and dust can pose health hazards if not managed properly. Lead exposure is especially harmful to young children and ',
            'pregnant women. Before renting pre-1978 housing, Lessors must disclose the presence of known leadbased paint and/or lead-based paint hazards in the dwelling. Lessees must also receive a federally approved pamphlet on lead poisoning prevention'
          ]
        },
        {
          text:[
          {text:`Lessor's Disclosure (initial) `,style: 'sectionTitle'},
          `\n`,
          {text: dataJson.input_13_ldi_1.length>0 ? `${dataJson.input_13_ldi_1}`:
            `______`, style:"underlinedText"},
           `Presence of lead-based paint or lead-based paint hazards (check (i) or (ii) below): \n`,
          
          ]

        },
        {
          text:[
            {text: dataJson.input_13_ldi_2.length>0 ? `${dataJson.input_13_ldi_2}`:
            `______`, style:"underlinedText"},
              ` Known lead-based paint and/or lead-based paint hazards are present in the housing (explain). \n`,
              {text: dataJson.input_13_ldi_3.length>0 ? `${dataJson.input_13_ldi_3}`:
            `______________________________________________________________________________________________`, style:"underlinedText"},
              `\n`,
              {text: dataJson.input_13_ldi_4.length>0 ? `${dataJson.input_13_ldi_4}`:
            `______`, style:"underlinedText"},
              `  Lessor has no knowledge of lead-based paint and/or lead-based paint hazards in the housing\n`
          ]
          ,
          margin:[40,0,40,0]

        },
        {
          text:[
          `\n`,
          {text: dataJson.input_13_ldi_5.length>0 ? `${dataJson.input_13_ldi_5}`:
            `______`, style:"underlinedText"},
          ` Records and reports available to the Lessor (check (i) or (ii) below): \n`,
          
          ]

        },
        {
          text:[
            {text: dataJson.input_13_ldi_6.length>0 ? `${dataJson.input_13_ldi_6}`:
            `______`, style:"underlinedText"},

              ` Lessor has provided the lessee with all available records and reports pertaining to lead-based paint and/or lead-based paint hazards in the housing (list documents below).  \n`,
              {text: dataJson.input_13_aai_1.length>0 ? `${dataJson.input_13_aai_1}`:
            `__________________________________________________________________________________________`, style:"underlinedText"},
              `\n`,
              {text: dataJson.input_13_ldi_7.length>0 ? `${dataJson.input_13_ldi_7}`:
              `______`, style:"underlinedText"}, 
              ` Lessor has no reports or records pertaining to lead-based paint and/or lead-based paint hazards in the housing. \n`
          ],
          margin:[40,0,40,0]

        },
        {
          text:[
            {text:`Lessee's Acknowledgment (initial)  \n`,style: 'sectionTitle'},
            {text: dataJson.input_13_lai_1.length>0 ? `${dataJson.input_13_lai_1}`:
            `______`, style:"underlinedText"},
            ` Lessee has received copies of all information listed above. \n`,
            {text: dataJson.input_13_lai_2.length>0 ? `${dataJson.input_13_lai_2}`:
            `______`, style:"underlinedText"},
            ` Lessee has received the pamphlet Protect Your Family From Lead in Your Home`

          ]

        },
        
        {
          text:[
            {text:`\nAgent's Acknowledgment (initial) \n`,style: 'sectionTitle'},
            {text: dataJson.input_13_aai_1.length>0 ? `${dataJson.input_13_aai_1}`:
            `_____`, style:"underlinedText"},
            ` `,
            `Agent has informed the Lessor of the Lessor's obligations under 42 U.S.C. 4852d and is aware of `,
            {text: dataJson.input_13_aai_2.length>0 ? `${dataJson.input_13_aai_2}`:
              `______________________________________________`, style:"underlinedText"}, 
            ``,
            ` his/her responsibility to ensure compliance. `

          ]

        },
        {
          text:[
            {text:'\nCertification of Accuracy',style: 'sectionTitle'},
            `The following parties have reviewed the information above and certify, to the best of their knowledge,`,
             `that the information provided by the signatory is true and accurate. `
          ]

        },
        {
          stack:fourSignatures(`Lessor’s signature `, 'Date'),
        },
        {
          stack:fourSignatures(`Lessor’s signature `, 'Date'),
        },
        {
          stack:fourSignatures(`Agent’s signature `, 'Date'),
        },
        {
          text:[
            {text:'\n\n14. SERVICEMEMBER.',style: 'sectionTitle'},
            
            ` If Tenant is a member of the United States Armed Forces on active duty or `,
            `state active duty or a member of the Florida National Guard or United States Reserve Forces, `,
            `the Tenant has rights to terminate the Lease as provided in Section 83.682, `,
            `Florida Statutes, the provisions of which can be found in the attachment to this Lease.`
          ]
        },
        
        {
          text:[
            {text:'\n\n15. LANDLORD’S ACCESS TO THE PREMISES. ',style: 'sectionTitle'},

          ]

        },
        {
          ul:[
            `At any time for the protection or preservation of the Premises. `,
            `After reasonable notice to Tenant at reasonable times for the purpose of repairing the Premises. `,
            [`To inspect the Premises; make necessary or agreed-upon repairs, decorations, alterations, `,
              `or improvements; supply agreed services; or exhibit the Premises to prospective or actual purchasers, `,
            `mortgagees, tenants, workers, or contractors under any of the following circumstances: `
            ],
            {
              ul:[
                `with Tenant’s consent;`,
                `in case of emergency; `,
                `when Tenant unreasonably withholds consent; or `,
                [
                  `if Tenant is absent from the Premises for a period of at least one-half a rental `,
                  `installment period. (If the rent is current and Tenant notifies Landlord of an intended absence, then `,
                  `Landlord may enter only with Tenant’s consent or for the protection or preservation of the Premises.) `
                ]

              ],margin:[40,0,40,0]
            }
          ],margin:[40,0,40,0]

        },
        {
          text:[
            ` \n\n16. HOMEOWNER’S ASSOCIATION. IF TENANT MUST BE APPROVED BY A `,
            `HOMEOWNER’S ASSOCIATION (“ASSOCIATION”), LANDLORD AND TENANT AGREE `,
            `THAT THE LEASE IS CONTINGENT UPON RECEIVING APPROVAL FROM THE `,
            `ASSOCIATION. ANY APPLICATION FEE REQUIRED BY AN ASSOCIATION SHALL BE `,
            `PAID BY `,
            {text:dataJson.checkBox_16_1 ? `☑` :`☐`,style:'boxStyle'},

            ` LANDLORD `  ,
            {text:dataJson.checkBox_16_2 ? `☑` :`☐`,style:'boxStyle'},

            ` TENANT.` ,
            `IF SUCH APPROVAL IS NOT OBTAINED PRIOR TO `,
            `COMMENCEMENT OF LEASE TERM, EITHER PARTY MAY TERMINATE THE LEASE BY `,
            `WRITTEN NOTICE TO THE OTHER GIVEN AT ANY TIME PRIOR TO APPROVAL BY THE `,
            `ASSOCIATION, AND IF THE LEASE IS TERMINATED, TENANT SHALL RECEIVE RETURN`
          ],
          bold:true
        },
        {
          text:[
            {text:'\n\nOF DEPOSITS SPECIFIED IN ARTICLE 5, IF MADE.',style: 'sectionTitle'},
            
            ` If the Lease is not terminated, rent shall abate `,
            `until the approval is obtained from the association. Tenant agrees to use due diligence in applying for `,
            `association approval and to comply with the requirements for obtaining approval `,
            {text:dataJson.checkBox_16_3 ? `☑` :`☐`,style:'boxStyle'},

            ` Landlord  `,
            {text:dataJson.checkBox_16_4 ? `☑` :`☐`,style:'boxStyle'},

            ` Tenant `,
            `shall pay the security deposit required by the association, if applicable.`
          ]
        },
        {
          text:[
            {text:'17. USE OF THE PREMISES. ',style: 'sectionTitle'},
            
            `Tenant shall use the Premises for residential purposes. Tenant shall have `,
            `exclusive use and right of possession to the dwelling. `,
            `The Premises shall be used so as to comply with all state, county, municipal laws and ordinances, `,
            `and all covenants and restrictions affecting the Premises and all rules and regulations of `,
            `homeowners’ associations affecting the Premises. Tenant may not paint or make any alterations or `,
            `improvements to the Premises without first obtaining the Landlord’s written consent to`,
            `the alteration or improvement. However, unless this box `,
            {text:dataJson.checkBox_17_1 ? `☑` :`☐`,style:'boxStyle'},

            `is checked, Tenant may hang pictures and `,
            `install window treatments in the Premises without Landlord’s consent, provided Tenant removes all such `,
            `items before the end of the Lease Term and repairs all damage resulting from the removal. Any `,
            `improvements or alterations to the Premises made by the Tenant shall become Landlord’s property. `,
            `Tenant agrees not to use, keep, or store on the Premises any dangerous, explosive, `,
            `toxic material which would increase the probability of fire or `,
            `which would increase the cost of insuring the Premises. `,
          ],pageBreak: 'before'
        },
        {
          text:[
            {text:'\n18. RISK OF LOSS/INSURANCE. ',style: 'sectionTitle'},
          ]
        },
        {
          ul:[
            `\t\tLandlord and Tenant shall each be responsible for loss, damage, or injury caused by its own negligence or willful conduct. `,
            `\t\tTenant should carry insurance covering Tenant’s personal property and Tenant’s liability insurance. `
          ]
        },
        {
          text:[
            {text:'\n19. PROHIBITED ACTS BY LANDLORD. ',style: 'sectionTitle'},
            `Landlord is prohibited from taking certain actions as described in Section 83.67, Florida Statutes, the provisions of which can be found in the attachment to this Lease. `

          ]
        },
        {
          text:[
            {text:'\n20. CASUALTY DAMAGE. ',style: 'sectionTitle'},
            `If the Premises are damaged or destroyed other than by wrongful or ` ,
            `negligent acts of Tenant or persons on the Premises with Tenant’s consent, `,
            `so that the use of the Premises is substantially impaired, `,
            `Tenant may terminate the Lease within 30 days after the damage or destruction `,
            `and Tenant will immediately vacate the Premises. If Tenant vacates, `,
            `Tenant is not liable for rent that would have been due after the date of termination. `,
            `Tenant may vacate the part of the Premises rendered unusable by the damage or destruction,` ,
            `in which case Tenant’s liability for rent shall be reduced by the fair rental `,
            `value of the part of the Premises that was damaged or destroyed.`
          ]
        },
        {
          text:[
            {text:'\n21. DEFAULTS/REMEDIES.',style: 'sectionTitle'},
            `Should a party to the Lease fail to fulfill their responsibilities under the `,
            `Lease or need to determine whether there has been a default of the Lease, refer to Part II, Chapter 83,`,
            `entitled Florida Residential Landlord and Tenant Act which contains information on defaults and remedies. `,
            `A copy of the current version of this Act is attached to the Lease. `

          ]
        },
        {
          text:[
            {text:'\n22. SUBORDINATION.',style: 'sectionTitle'},
            `The Lease is automatically subordinate to the lien of any mortgage encumbering the fee title to the Premises from time to time. `
          ]
        },
        {
          text:[
            {text:`\n23. LIENS. THE INTEREST OF THE LANDLORD SHALL NOT BE SUBJECT TO LIENS FOR IMPROVEMENTS MADE BY THE TENANT AS PROVIDED IN SECTION 713.10, FLORIDA STATUTES.`,style: 'sectionTitle'},
            `Tenant shall notify all parties performing work on the Premises at Tenant’s request that the Lease does not allow any liens to attach to Landlord’s interest.  `
          ]
        },
        {
          text:[
            {text:'\n24. RENEWAL/EXTENSION.',style: 'sectionTitle'},
            `The Lease can be renewed or extended only by a written agreement signed by both Landlord and Tenant, but the term of a renewal or extension together with the original Lease `,
            `Term may not exceed one year. A new lease is required for each year. `
          ]
        },
        {
          text:[
            {text:'25. TENANT’S TELEPHONE NUMBER. ',style: 'sectionTitle'},
            `Tenant shall, within 5 business days of obtaining telephone services at the Premises, send written notice to Landlord of Tenant’s telephone numbers at the Premises. `,
          ],pageBreak:"before"
        },
        {
          text:[
            {text:`\n26. ATTORNEYS' FEES.`,style: 'sectionTitle'},
            `In any lawsuit brought to enforce the Lease or under applicable law, the party in whose favor a judgment or decree has been rendered may recover reasonable court costs, including `,
            `attorneys’ fees, from the non-prevailing party. `
          ]
        },
        {
          text:[
            {text:'\n27. MISCELLANEOUS.  ',style: 'sectionTitle'},
          ]
        },
        {
          ul:[
            [
              `\t\tTime is of the essence of the performance of each party’s obligations under the Lease. `,
            ],
            [
              `\t\tThe Lease shall be binding upon and for the benefit of the heirs, personal representatives, `,
            `successors, and permitted assigns of Landlord and Tenant, subject to the requirements specifically`,
            `mentioned in the Lease. Whenever used, the singular number shall include the plural or singular and the use`,
            `of any gender shall include all appropriate genders. `,
            ],
            
            
              `\t\tThe agreements contained in the Lease set forth the complete understanding of the parties and may not be changed or terminated orally. `,
            `\t\tNo agreement to accept surrender of the Premises from Tenant will be valid unless in writing and signed by Landlord. `,
            `\t\tAll questions concerning the meaning, execution, construction, effect, validity, and enforcement of the Lease shall be determined pursuant to the laws of Florida. `,
            `\t\tA facsimile copy of the Lease and any signatures hereon shall be considered for all purposes originals. `,
            [
            `\t\tAs required by law, Landlord makes the following disclosure: "RADON GAS." Radon is `,
              `a naturally occurring radioactive gas that, when it has accumulated in a building in sufficient quantities, `,
              `may present health risks to persons who are exposed to it over time. Levels of radon that exceed federal and `,
              `state guidelines have been found in buildings in Florida. Additional information regarding radon and radon `,
              `testing may be obtained from your county health department. `
            ]
          ]
        },
        {
          text:[
            {text:'\n28. BROKERS’ COMMISSION. ',style: 'sectionTitle'},
            {text:dataJson.checkBox_28_1 ? `☑` :`☐`,style:'boxStyle'},
            ` Check and complete if applicable. The brokerage companies named below will be paid the commission `,
            `set forth in this paragraph by `,
            {text:dataJson.checkBox_28_2 ? `☑` :`☐`,style:'boxStyle'},
            ` Landlord `,
            {text:dataJson.checkBox_28_3 ? `☑` :`☐`,style:'boxStyle'},
             `Tenant for procuring a tenant for this transaction. `
          ]
        },
        { stack:signaturesDisplay(`Real Estate Licensee`, 'Real Estate Licensee'),},
        { stack:signaturesDisplay(`Real Estate Brokerage Company `, 'Real Estate Brokerage Company '),},
        { stack:signaturesDisplay(`Commission`, 'Commission'),},
        {
          text:[
            {text:'\n29. TENANT’S PERSONAL PROPERTY. ',style: 'sectionTitle'},
            
            `TENANT MUST INITIAL IN THIS BOX `,
            {text:dataJson.checkBox_29_1 ? `☑` :`☐`,style:'boxStyle'},
            ` FOR THE FOLLOWING PROVISION TO APPLY. BY SIGNING THIS RENTAL AGREEMENT, THE TENANT `,
            `AGREES THAT UPON SURRENDER, ABANDONMENT, OR RECOVERY OF POSSESSION OF THE DWELLING UNIT DUE TO THE DEATH OF THE LAST REMAINING TENANT, AS `,
            `PROVIDED BY CHAPTER 83, FLORIDA STATUTES, THE LANDLORD SHALL NOT BE LIABLE OR RESPONSIBLE FOR STORAGE OR DISPOSITION OF THE TENANT’S PERSONAL PROPERTY.`
          ]
        },
        {text:'The Lease has been executed by the parties on the dates indicated below. '},
        { stack:signaturesDisplay(`Landlord's Signature`, 'Date'), },
        {stack:signaturesDisplay(`Landlord's Signature`, 'Date'),},
        {stack:signaturesDisplay(`Landlord's Signature`, 'Date'),},
        {stack:signaturesDisplay(`Tenant's Signature`, 'Date'),},
        {stack:signaturesDisplay(`Tenant's Signature`, 'Date'),},
        {text:'\n\nThis form was completed with the assistance of\n',},
        {
          text: [
            'Name of Individual:\t', 
            { text: '____________________________________' }
          ],
        },
        {
          text: [
            'Name of Business: \t', 
            { text: '____________________________________' }
          ],
        },
        {
          text: [
            'Address: \t', 
            { text: '____________________________________' }
          ],
        },
        {
          text: [
            'Telephone Number: \t', 
            { text: '____________________________________' },
            '\n\n'
          ],pageBreak:"after"
        
        },
        { text: 'Copy of Current Version of Florida Residential Landlord and Tenant Act', style: 'header' },
        { text: 'Part II, Chapter 83, Florida Statutes to Be Attached ', style: 'subheader',pageBreak:"after" },
        { text: 'EARLY TERMINATION FEE/LIQUIDATED DAMAGES ADDENDUM', style: 'header',decoration:"underline" },
        {
          text:[
          `[ ] I agree, as provided in the rental agreement, to pay $_______ (an amount that does not exceed 2 `,
          `months' rent) as liquidated damages or an early termination fee if I elect to terminate the rental agreement `,
          `and the landlord waives the right to seek additional rent beyond the month in which the landlord retakes possession. \n`,

          `\n[ ] I do not agree to liquidated damages or an early termination fee, and I acknowledge `,
          `that the landlord may seek damages as provided by law. \n\n`
          ]
        },
        { stack:signaturesDisplay(`Landlord's Signature`, 'Date'), },
        {stack:signaturesDisplay(`Landlord's Signature`, 'Date'),},
        {stack:signaturesDisplay(`Landlord's Signature`, 'Date'),},
        {stack:signaturesDisplay(`Tenant's Signature`, 'Date'),},
        {stack:signaturesDisplay(`Tenant's Signature`, 'Date'),},
        









      ],
      styles: {
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 5]
        },
        subheader: {
          fontSize: 10,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 15]
        },
        sectionTitle:{
          bold:true,
          fontSize:10
        },
        underlinedText:{
          width: 200,decoration:'underline',bold:true,
        },
        extraLeftMargn:{
          margin:[50,0,15,0],
          bold:true
        },
        boxStyle:{
          font:'DejaVu',bold:true,fontSize:13,lineHeight:1.4,
          decoration:'underline'
        }
        
        
    },
    defaultStyle: {
      fontSize: 10,
      alignment: 'justify',
      margin:[15,0,15,0],
      lineHeight: 1.5,
    }
    
    }
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
      pdfDocGenerator.download('lease11'+'.pdf');
     
    
  }