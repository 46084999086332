import React, { useState, useEffect } from 'react';
import axios from "axios";
import {getPropertyDetailsByIdInDB} from "../PropertyFunctions";
import { getTaxDetailsByPropertyIdInDB } from '../CountyTaxFunctions';
// import ReactDOM from 'react-dom';
// import { AddressAutofill } from '@mapbox/search-js-react';
 
// const  AddProperty = () =>{
//     return (
//         <form>
//             <AddressAutofill accessToken="my-access-token-here">
//             <input
//             name="address" placeholder="Address" type="text"
//             autoComplete="address-line1"
//             />
//             </AddressAutofill>
//             <input
//             name="apartment" placeholder="Apartment number" type="text"
//             autoComplete="address-line2"
//             />
//             <input
//             name="city" placeholder="City" type="text"
//             autoComplete="address-level2"
//             />
//             <input
//             name="state" placeholder="State" type="text"
//             autoComplete="address-level1"
//             />
//             <input
//             name="country" placeholder="Country" type="text"
//             autoComplete="country"
//             />
//             <input
//             name="postcode" placeholder="Postcode" type="text"
//             autoComplete="postal-code"
//             />
//         </form>
//     ); 
// }
// export default AddProperty;
// import { Steps, Panel, Placeholder, ButtonGroup, Button,Whisper,Tooltip,Input } from 'rsuite';
import {
    Box ,
    FilledInput ,
    InputLabel ,
    FormControl,
    Button
  } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const AddProperty = ({propertyJson,setPropertyJson,setTaxJson}) => {
    
  const url = process.env.REACT_APP_SERVER_URL;
    const navigate=useNavigate();
    const {propertyId} = useParams();
    useEffect(() => {
      if(propertyId){
        // getPropertyDetailsById(propertyId);
        getPropertyDetailsByPropertyId(propertyId)
        getTaxDetailsByPropertyId(propertyId);
      }
      }, [propertyId]);

      const setStateValue = (propertyDetails) =>{
        if(propertyDetails){
          setPropertyJson({...propertyJson,
            propertyId:propertyDetails.id,
            name:propertyDetails.name,
            type:propertyDetails.type,
            streetAddress:propertyDetails.streetAddress,
            city:propertyDetails.city,
            state:propertyDetails.state,
            zipCode:propertyDetails.zipCode
          })
        }
      };
      const getTaxDetailsByPropertyId = async(propertyId) =>{
        try{
        const response= await getTaxDetailsByPropertyIdInDB(propertyId);
        console.log(response)
        if(response && Object.keys(response).length > 0){
          setTaxJson(response)
        }
        }
        catch(error){
          console.log(error)
        }
      }
      const getPropertyDetailsByPropertyId = async(propertyId) =>{
        try{
        const response= await getPropertyDetailsByIdInDB(propertyId);
        console.log(response)
        if(response && Object.keys(response).length > 0){
          setStateValue(response)
        }
        }
        catch(error){
          console.log(error)
        }
      }


  return (
    <div>
        
        
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        
      <div>
      <FormControl sx={{ m: 1, width: '50ch' }} variant="filled">
         <InputLabel>Name</InputLabel>
          <FilledInput value={propertyJson.name} onChange={(e)=>setPropertyJson({...propertyJson,name:e.target.value})}
          />
        </FormControl>

      

      <FormControl fullWidth sx={{ m: 1}} variant="filled">
          <InputLabel>Street Address</InputLabel>
          <FilledInput value={propertyJson.streetAddress} onChange={(e)=>setPropertyJson({...propertyJson,streetAddress:e.target.value})}
          />
        </FormControl><br></br>
        
         <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
         <InputLabel>City</InputLabel>
          <FilledInput value={propertyJson.city} onChange={(e)=>setPropertyJson({...propertyJson,city:e.target.value})}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
        <InputLabel>State</InputLabel>
          <FilledInput value={propertyJson.state} onChange={(e)=>setPropertyJson({...propertyJson,state:e.target.value})}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
          <InputLabel>Zip Code</InputLabel>
          <FilledInput value={propertyJson.zipCode} onChange={(e)=>setPropertyJson({...propertyJson,zipCode:e.target.value})}
          />
        </FormControl>
        
      </div>

      
      
    </Box>
   
    </div>
  );
};

export default AddProperty;