import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import { 
  formatDateText,
  formatDate 
} from '../CommonFunctions';
import {
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Box,
    IconButton,
    Input,
    Alert
    
} from "@mui/material";
import {
    GridPagination,
    DataGrid,
    GRID_CHECKBOX_SELECTION_COL_DEF,
  } from '@mui/x-data-grid'
  import CancelIcon from '@mui/icons-material/Close';
import { updateTenancyInfoInDB } from "../TenancyFunctions";
import { createLeaseActivityLogInDB } from "../LeaseActivityLogFunctions";


const CheckoutTenant = ({tenancyJson,setTenancyJson,showCheckoutTenantModal,setShowCheckoutTenantModal}) => {
 
    

    const url=process.env.REACT_APP_SERVER_URL;
    const {tenancyId}=useParams();
    
    
      const handleCloseCheckoutTenantPopup=()=>{
        setShowCheckoutTenantModal(false)
      }
     
      
      
      useEffect(() => {
        
      }, [tenancyJson]);


      const checkoutTenancy= async(tenancyJson)=>{
        try{
            const response=await updateTenancyInfoInDB(tenancyJson.id,tenancyJson);
            if(response){
              console.log("Tenant Checkedout Successfully");
              const leaseActivityLog={tenancyId:tenancyJson.id,
                description:`Tenant checked-out on ${tenancyJson.actualCheckoutDate}`}
              createLeaseActivityLogInDB(leaseActivityLog);
            }
            
            

        }catch(error){
            console.log(error)
        }
      }
      
      
    
    return (
        <>
      <Dialog
        open={showCheckoutTenantModal}
        onClose={handleCloseCheckoutTenantPopup}
        maxWidth="lg"
      >
        <DialogTitle 
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          Checkout Tenant 
        <IconButton aria-label="close" onClick={handleCloseCheckoutTenantPopup}>
            <CancelIcon />
        </IconButton></DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Alert severity="info">
            Tenants are expected to checkout on <b>{formatDateText(tenancyJson.checkout)}</b>.
            </Alert>
            
          <br></br>Are you sure the tenant checked out? Please confirm the date.<br></br>
         
                    <Input sx={{width:"20ch",m:1}} value={tenancyJson.actualCheckoutDate} type="date"
                    onChange={(e)=>setTenancyJson({...tenancyJson,actualCheckoutDate:e.target.value})}/>
          </DialogContentText>
      
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCheckoutTenantPopup}>Cancel</Button>
          <Button
            type="submit"
            onClick={(e) => {
                checkoutTenancy(tenancyJson)
                handleCloseCheckoutTenantPopup();
                
             
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      
            
        </>
    );

}
export default CheckoutTenant;