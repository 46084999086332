import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  Box,
  FilledInput,
  InputLabel,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Select,
  TextField,
  Typography,
  Rating
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useNavigate, useParams } from 'react-router-dom'
import {getAllUnitsByPropertyIdInDB} from "../UnitsFunctions";
import {getAllPropertiesInDB} from "../PropertyFunctions";
import {createRepairInDB, updateRepairInDB} from "../RepairFunctions"
const AddRepair = ({   
  showAddRepairModal,
  setShowAddRepairModal,
  repair,
}) => {
  const [repairJson, setRepairJson] = useState(() => {
    if (repair && Object.keys(repair).length > 0) {
      return {
        id: repair.id || null,
        unitId: repair.unitId || null,
        description: repair.description || '',
        propertyName: repair.propertyName || "",
        unitName: repair.unitName || "",
        assignedTo: repair.assignedTo || '',
        scheduledDate: repair.scheduledDate || null,
        status: repair.status || '',
        completedDate: repair.completedDate || null,
        managerRatingOnRepair: repair.managerRatingOnRepair || 0,
        managerComments: repair.managerComments || '',
      }
    } else
      return {
        id: null,
        unitId: null,
        description: '',
        propertyName:"",
        unitName:"",
        assignedto: '',
        scheduledDate: null,
        status: '',
        completedDate: null,
        managerRatingOnRepair: 0,
        managerComments: '',
      }
  })
  const [properties,setProperties]=useState([]);
  const [units,setUnits] = useState([])
  const url = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate()
  const { propertyId } = useParams()
  useEffect(() => {
        getAllProperties();

  }, [])
  const handleCloseAddRepairPopup = () => {
    setShowAddRepairModal(false)
  }
  const getAllUnitsByPropertyId = async(propertyId) =>{
    try{
        const response=await getAllUnitsByPropertyIdInDB(propertyId);
        if(response){
            setUnits(response);
        }

    }
    catch(error)
    {
        console.log(error)
    }
  }
  const getAllProperties = async(propertyId) =>{
    try{
        const response=await getAllPropertiesInDB(propertyId);
        if(response){
            setProperties(response);
        }
    }
    catch(error)
    {
        console.log(error);
    }
  }
  const createNewRepair = async() => {
    try{
        const response=await createRepairInDB(repairJson);
        if(response){
            console.log(response);
        }

    }
    catch(error){
        console.log(error);
    }
  }
  const updateRepair = async () =>{
    try{
        const response=await updateRepairInDB(repairJson);
        if(response){
            console.log(response);
            
        }
        

    }
    catch(error){
        console.log(error);
    }
    
  }



  return (
    <div>
      <Dialog open={showAddRepairModal} onClose={handleCloseAddRepairPopup}>
        <DialogTitle>Repair Details</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            
                {repairJson.id ? 
                    <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                    <InputLabel>Property Name :</InputLabel>
                    <FilledInput
                    readOnly
                    value={repairJson.propertyName}
                    
                    />
                    </FormControl>
                 :
                    <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                        <InputLabel id="room-select-label">
                        Select a Property :
                        </InputLabel>
                        <Select
                        labelId="room-select-label"
                        id="room-select"
                        
                        label="Select a Property :"
                        onChange={(e) =>
                            getAllUnitsByPropertyId(e.target.value)
                        }
                        >
                        {properties.map((row, index) => (
                            <MenuItem value={row.id}>{row.name}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                }
                 
                {repairJson.id ? 
                    <FormControl sx={{ m: 1, width: '34ch' }} variant="filled">
                    <InputLabel>Unit Name :</InputLabel>
                    <FilledInput
                    readOnly
                    value={repairJson.unitName}
                    
                    />
                    </FormControl>
                 :
                    <FormControl sx={{ m: 1, width: '34ch' }} variant="filled">
                        <InputLabel id="room-select-label">
                        Select a Unit :
                        </InputLabel>
                        <Select
                        labelId="room-select-label"
                        id="room-select"
                        label="Select a Unit :"
                        onChange={(e) =>
                            setRepairJson({...repairJson,unitId:e.target.value})
                        }
                        >
                        {units.map((row, index) => (
                            <MenuItem value={row.id}>{row.name}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                }

              <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                <InputLabel>Repair Type :</InputLabel>
                <FilledInput
                
                  value={repairJson.type}
                  onChange={(e) =>
                    setRepairJson({
                      ...repairJson,
                      type: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: '34ch' }} variant="filled">
                <InputLabel>Assigned To :</InputLabel>
                <FilledInput
                  value={repairJson.assignedTo}
                  onChange={(e) =>
                    setRepairJson({
                      ...repairJson,
                      assignedTo: e.target.value,
                    })
                  }
                />
              </FormControl>
                <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                <InputLabel>Repair Description :</InputLabel>
                <FilledInput
                  value={repairJson.description}
                  onChange={(e) =>
                    setRepairJson({
                      ...repairJson,
                      description: e.target.value,
                    })
                  }
                />
              </FormControl>
              
              
              <br></br>
              <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                <InputLabel id="repair-select-label">
                  Repair Status :
                </InputLabel>
                <Select
                  labelId="repair-select-label"
                  id="repair-select"
                  value={repairJson.status}
                  label="Repair Status :"
                  onChange={(e) =>
                    setRepairJson({
                      ...repairJson,
                      status: e.target.value,
                    })
                  }
                >
                  {' '}
                  {/* <MenuItem value="" disabled selected></MenuItem> */}
                  <MenuItem value="Yet to be assigned">
                    Yet to be Assigned
                  </MenuItem>
                  <MenuItem value="Assigned">Assigned</MenuItem>
                  <MenuItem value="Need management action">
                    Need Management Action
                  </MenuItem>
                  <MenuItem value="In progress">In Progress</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1,width:'34ch'}} variant="filled">
                
                <InputLabel>Scheduled At :</InputLabel>
                <FilledInput type="date"
                    value={repairJson.scheduledDate}
                    onChange={(e) =>
                    setRepairJson({
                        ...repairJson,
                        scheduledDate: e.target.value,
                    })
                    }
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                
                <InputLabel>Completed At :</InputLabel>
                <FilledInput type="date"
                    value={repairJson.completedDate}
                    onChange={(e) =>
                    setRepairJson({
                        ...repairJson,
                        completedDate: e.target.value,
                    })
                    }
                />
                
              </FormControl>
              <br></br>

              <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                <Typography component="legend">Manager Review :</Typography>
                <Rating
                  name="simple-controlled"
                  value={repairJson.managerRatingOnRepair}
                  onChange={(e) =>
                    setRepairJson({
                      ...repairJson,
                      managerRatingOnRepair: e.target.value
                    })
                  }
                />
              </FormControl>
              <br></br>

              <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                <InputLabel>Manager Comments :</InputLabel>
                <FilledInput
                  value={repairJson.managerComments}
                  onChange={(e) =>
                    setRepairJson({
                      ...repairJson,
                      managerComments: e.target.value
                    })
                  }
                />
              </FormControl>
              
              <br></br>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddRepairPopup}>Cancel</Button>
          <Button
            type="submit"
            onClick={(e) => {
                if(repairJson.id){
                    updateRepair();
                }
                else{
                    createNewRepair();
                }
                handleCloseAddRepairPopup();
                
             
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddRepair
