import  React,{
    useState,
    useEffect
} from 'react';
import axios from 'axios';
import { 
    Link, useParams,useNavigate
} from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    FilledInput,
    InputLabel,
    FormControl,
    Select,
    MenuItem
} from '@mui/material';
import { getAllUnitsByPropertyIdInDB, getUnitInfoByIdInDB } from '../UnitsFunctions';
import { getLandlordDetailsByPropertyIdInDB } from '../LandlordDetailsFunctions';

export default function UnitInfo({unitJson,setUnitJson,tenancyJson,setTenancyJson,landlordJson,setLandlordJson}) {
    const [units,setUnits]= useState([]);
    const {propertyId} = useParams();
    const {unitId} =useParams();
    const url = process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
        if(unitId){
            getUnitInfoById(unitId)
        }
        else if(propertyId){
            getAllUnitsByPropertyId(propertyId,unitId);    
        } 
        if(propertyId){
            getLandlordDetailsByPropertyId(propertyId)
        }
    
      }, [propertyId,unitId]);
      
    
    const getLandlordDetailsByPropertyId= async(propertyId) =>{
        try{
            const response= await getLandlordDetailsByPropertyIdInDB(propertyId);
            if(response && response.length>0){
                console.log(response[0])
                setLandlordJson(response[0])
            }
        }
        catch(error){
            console.log(error);
        }
    }
    
    //Middleware to get respective unit Info
    const getUnitInfoByIdInUnits = async(units,unitId) => {
        try{
            for(let i=0;i<units.length;i++)
            {
                if(units[i].id==unitId)
                {
                    console.log("in getUnitInfoBYIdInUnits");
                    console.log(units[i])
                    setUnitJson({...unitJson,
                        unitId:units[i].id,
                        name:units[i].name,
                        streetAddress:units[i].streetAddress,
                        city:units[i].city,
                        state:units[i].state,
                        zipCode:units[i].zipCode

                    });
                    setTenancyJson({...tenancyJson,unitId:unitId})
                    break;
                }
            }
            

        }
        catch(error){
            console.log(error);
        }
    }
    const getAllUnitsByPropertyId = async(propertyId) =>{
        try{
            const response=await getAllUnitsByPropertyIdInDB(propertyId);
            if(response){
                setUnits(response);
            }

        }
        catch(error)
        {
            console.log(error)
        }
    }
    
    const getUnitInfoById = async(unitId) =>{
        try{
            const response=await getUnitInfoByIdInDB(unitId);
            if(response){
                getUnitInfoByIdInUnits(response,unitId);
            }
            

        }
        catch(error)
        {
            console.log(error)
        }
    }

  return (
    <>
    {unitId ? 
    <FormControl sx={{ m: 1, width: '50ch' }} variant="filled">
    <InputLabel>Name</InputLabel>
     <FilledInput value={unitJson.name} onChange={(e)=>setUnitJson({...unitJson,name:e.target.value})}
     />
   </FormControl>
     :
    <FormControl sx={{ m: 1, width: '25ch' }} >
            <InputLabel id="demo-simple-select-autowidth-label">Units</InputLabel>
            <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            autoWidth
            label="Units"
            onChange={(e)=>getUnitInfoByIdInUnits(units,e.target.value)}
            >
            {units.map((row) => (
                <MenuItem value={row.id}>{row.name}</MenuItem>
            ))}
            </Select>
        </FormControl>
    }
        <br></br>
            
      

      <FormControl sx={{ m: 1, width: '85ch' }}  variant="filled">
          <InputLabel>Street Address</InputLabel>
          <FilledInput 
          value={unitJson.streetAddress} onChange={(e)=>setUnitJson({...unitJson,streetAddress:e.target.value})}
          />
        </FormControl><br></br>
        
         <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
         <InputLabel>City</InputLabel>
          <FilledInput 
          value={unitJson.city} onChange={(e)=>setUnitJson({...unitJson,city:e.target.value})}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
        <InputLabel>State</InputLabel>
          <FilledInput 
          value={unitJson.state} onChange={(e)=>setUnitJson({...unitJson,state:e.target.value})}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
          <InputLabel>Zip Code</InputLabel>
          <FilledInput 
          value={unitJson.zipCode} onChange={(e)=>setUnitJson({...unitJson,zipCode:e.target.value})}
          />
        </FormControl> 

        


    </>
  );
}